import { defineStore } from 'pinia'
import { Vehicle } from '@/interfaces/vehicle'
import { FilterList } from '@/interfaces/filters'
import { convertDate } from './charge'

const formatVehicle = (vehicle: Vehicle) => {
    const vehicleFormated = {
        immatriculation: vehicle.immatriculation,
        additional_information: vehicle.additionalInformation,
        version_id: vehicle.version?.id || null,
        rfid_passes: vehicle.passes.map((p) => p.id)
    }
    return vehicleFormated
}

const useVehicleStore = defineStore('vehicle', () => {
    const getVehicles = (
        context: any,
        payload: {
            idOrga: number
            idBU: number
            filters: FilterList
        }
    ) => {
        const { idOrga, idBU, filters } = payload
        const offset =
            (filters.pages.index - 1) * filters.pages.perPage || (0 as number)
        const limit = filters.pages.perPage || (20 as number)
        const keyword = filters.searchField || (null as null | string)
        const orderBy = filters.orderBy || (null as null | string)
        let params = {
            offset,
            limit,
            keyword,
            orderBy,
            has_business_pass: filters.hasBusinessPass,
            has_domestic_zone: filters.hasDomesticZone,
            min_date_created: undefined as string | undefined | null,
            max_date_created: undefined as string | undefined | null
        }

        if (filters.dateRange) {
            const { minStartDate, maxStartDate } = convertDate(
                filters.dateRange[0],
                filters.dateRange[1]
            )
            if (minStartDate) {
                params = { ...params, min_date_created: minStartDate }
            }
            if (maxStartDate) {
                params = { ...params, max_date_created: maxStartDate }
            }
        }

        return new Promise((resolve, reject) => {
            const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/vehicles`
            context.$axios
                .$get(url, { params })
                .then((res: any) => {
                    const vehicles = context.$toCamel(res)
                    return resolve(vehicles)
                })
                .catch((e: any) => reject(e))
        })
    }

    const getVehicle = (
        context: any,
        payload: { idOrga: number; idBU: number; idVehicle: number }
    ) => {
        const { idOrga, idBU, idVehicle } = payload
        return new Promise((resolve, reject) => {
            const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/vehicles/${idVehicle}`
            context.$axios
                .$get(url)
                .then((res: any) => {
                    const vehicle = context.$toCamel(res)
                    resolve(vehicle)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }

    const createVehicle = (
        context: any,
        payload: { vehicle: Vehicle; idOrga: number; idBU: number }
    ) => {
        const { vehicle, idOrga, idBU } = payload
        const vehicleFormated = formatVehicle(vehicle)

        return new Promise((resolve, reject) => {
            context.$axios
                .$post(
                    `/cloudapi/organizations/${idOrga}/business-units/${idBU}/vehicles`,
                    vehicleFormated
                )
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }

    const updateVehicle = (
        context: any,
        payload: { vehicle: Vehicle; idOrga: number; idBU: number }
    ) => {
        const { vehicle, idOrga, idBU } = payload
        const vehicleFormated = formatVehicle(vehicle)

        return new Promise((resolve, reject) => {
            context.$axios
                .$put(
                    `/cloudapi/organizations/${idOrga}/business-units/${idBU}/vehicles/${vehicle.id}`,
                    vehicleFormated
                )
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }

    const deleteVehicle = (
        context: any,
        payload: { idVehicle: number; idOrga: number; idBU: number }
    ) => {
        const { idVehicle, idOrga, idBU } = payload
        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/vehicles/${idVehicle}`
        return new Promise((resolve, reject) => {
            context.$axios
                .$delete(url)
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }

    const getBrands = (context: any, payload: { searchField: string }) => {
        const params = {
            keyword: payload.searchField
        }
        const url = `/cloudapi/vehicles/brands`
        return new Promise((resolve, reject) => {
            context.$axios
                .$get(url, { params })
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }

    const getModels = (
        context: any,
        payload: { idBrand: number; searchField: string }
    ) => {
        const params = {
            keyword: payload.searchField
        }
        const { idBrand } = payload
        const url = `/cloudapi/vehicles/brands/${idBrand}/models`
        return new Promise((resolve, reject) => {
            context.$axios
                .$get(url, { params })
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }

    const getVersions = (
        context: any,
        payload: { idBrand: number; idModel: number; searchField: string }
    ) => {
        const params = {
            keyword: payload.searchField
        }
        const { idBrand, idModel } = payload
        const url = `/cloudapi/vehicles/brands/${idBrand}/models/${idModel}/versions`
        return new Promise((resolve, reject) => {
            context.$axios
                .$get(url, { params })
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }

    return {
        getVehicles,
        getVehicle,
        createVehicle,
        updateVehicle,
        deleteVehicle,
        getBrands,
        getModels,
        getVersions
    }
})

export default useVehicleStore
