
import IconAdmin from '~/components/UI/icons/layout/IconAdmin.vue'
import IconBills from '~/components/UI/icons/layout/IconBills.vue'
import IconBusiness from '~/components/UI/icons/layout/IconBusiness.vue'
import IconSettings from '~/components/UI/icons/layout/IconSettings.vue'
import IconCharge from '~/components/UI/icons/layout/IconCharge.vue'
import IconDashboard from '~/components/UI/icons/layout/IconDashboard.vue'
import IconFleet from '~/components/UI/icons/layout/IconFleet.vue'
import IconOrders from '~/components/UI/icons/layout/IconOrders.vue'
import IconStation from '~/components/UI/icons/layout/IconStation.vue'
import IconSupervision from '~/components/UI/icons/layout/IconSupervision.vue'
import IconCollaborators from '~/components/UI/icons/layout/IconCollaborators.vue'
import IconUsers from '~/components/UI/icons/layout/IconUsers.vue'
import IconChargemap from '~/components/UI/icons/layout/IconChargemap.vue'
import IconBack from '~/components/UI/icons/layout/IconBack.vue'
import IconVehicle from '~/components/UI/icons/layout/IconVehicle.vue'
import IconPass from '~/components/UI/icons/layout/IconPass.vue'
import IconChart from '~/components/UI/icons/layout/IconChart.vue'
import IconLibrary from '~/components/UI/icons/cloud/IconLibrary.vue'

export default {
    name: 'MenuLink',
    components: {
        IconAdmin,
        IconBills,
        IconBusiness,
        IconCharge,
        IconDashboard,
        IconFleet,
        IconOrders,
        IconStation,
        IconSupervision,
        IconCollaborators,
        IconUsers,
        IconChargemap,
        IconBack,
        IconVehicle,
        IconPass,
        IconChart,
        IconSettings,
        IconLibrary
    },
    props: {
        link: {
            type: Object,
            required: true
        },
        isSubmenu: {
            type: Boolean,
            default: false
        }
    },
    emits: ['closeMobileMenu'],
    methods: {
        closeMobileMenu() {
            this.$emit('closeMobileMenu')
        }
    }
}
