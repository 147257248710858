import { createLocalVue, shallowMount } from '@vue/test-utils'

// @ts-ignore
import AppTabs from '@/components/UI/HeaderButton.vue'
import router from '~/test/mocks/router.mock'

const localVue = createLocalVue()

describe('components/UI/HeaderButton.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppTabs, {
            localVue,
            mocks: {
                $route: {
                    hash: undefined
                },
                $router: router
            }
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        expect(wrapper.vm.url).toBeNull()
        expect(wrapper.vm.icon).toBe('IconPlus')
        expect(wrapper.vm.alt).toBe('clear')
    })

    it('do not redirect', () => {
        wrapper.vm.redirect()
        expect(router.push).not.toHaveBeenCalled()
    })

    it('redirect with url', async () => {
        const propsData = {
            url: 'test',
            icon: 'IconCross',
            alt: 'red'
        }
        await wrapper.setProps(propsData)
        expect(wrapper.vm.url).toEqual(propsData.url)
        expect(wrapper.vm.icon).toEqual(propsData.icon)
        expect(wrapper.vm.alt).toEqual(propsData.alt)
        wrapper.vm.redirect()
        expect(router.push).toHaveBeenCalledWith(propsData.url)
    })
})
