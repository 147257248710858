import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'

// @ts-ignore
import AppAccordionAgnostic from '@/components/UI/AppAccordionAgnostic.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()
localVue.use(Vuex)
describe('components/UI/AppAccordionAgnostic.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppAccordionAgnostic, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks
            }
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const container = wrapper.find("[data-testid='app-accordion-agnostic']")
        expect(container.exists()).toBeTruthy()
    })

    describe('methods', () => {
        describe('heightCalculation', () => {
            it('should return without doing anything if header is not defined', () => {
                wrapper.vm.header = undefined
                wrapper.vm.toggleAccordion()
                expect(wrapper.vm.childHeight).toBe(0)
            })
            it('should update ChildHeight with he nextElementSibling height', () => {
                expect(wrapper.vm.childHeight).toBe(0)
                wrapper.vm.header = {
                    nextElementSibling: {
                        scrollHeight: 100
                    }
                }
                wrapper.vm.toggleAccordion()
                expect(wrapper.vm.childHeight).toBe(100)
            })
        })

        describe('toggleAccordion', () => {
            it('should emit updateOpen', () => {
                wrapper.vm.toggleAccordion()
                expect(wrapper.emitted().updateOpen).toBeTruthy()
                expect(wrapper.emitted().updateOpen[0]).toEqual([true])
            })
            it('should call heightCalculation', () => {
                const spy = jest.spyOn(wrapper.vm, 'heightCalculation')
                wrapper.vm.toggleAccordion()
                expect(spy).toHaveBeenCalled()
            })

            it('should not emit updateOpen if isDisabled', () => {
                wrapper = shallowMount(AppAccordionAgnostic, {
                    ...basicMountWrapper,
                    localVue,
                    propsData: {
                        isDisabled: true
                    },
                    mocks: {
                        ...basicMountWrapper.mocks
                    }
                })

                wrapper.vm.toggleAccordion()
                expect(wrapper.emitted().updateOpen).not.toBeTruthy()
            })
        })
    })
})
