import { defineStore } from 'pinia'

const useAuthenticationStore = defineStore('authentication', () => {
    const logUser = (
        context: any,
        params: {
            login: string
            password: string
            tokenCaptcha: string
        }
    ) => {
        const payload = {
            data: {
                login: params.login,
                password: params.password,
                google_recaptcha_token: context.$config.CAPTCHA_ENABLED
                    ? params.tokenCaptcha
                    : undefined
            }
        }

        return context.$auth
            .loginWith('refresh', payload)
            .then((res: any) =>
                context.$store.dispatch('session/setUpUser', res.data, {
                    root: true
                })
            )
            .catch((e: any) => {
                if (e.response && e.response.status === 401) {
                    throw new Error('errors.invalid_password')
                } else {
                    throw new Error('errors.oops')
                }
            })
    }

    const logOutUser = (context: any) =>
        context.$auth.strategy
            .logout({
                url: `/cloudapi/tokens/${context.$auth.strategy.refreshToken.get()}`,
                method: 'delete'
            })
            .catch((e: any) => {
                context.$sentry.captureException(e)
            })
            .finally(() => {
                context.$store.dispatch('session/logout', null, {
                    root: true
                })
            })

    const sendPasswordReset = (context: any, email: string): Promise<void> => {
        const params = { user_email: email }
        const url = 'cloudapi/password/reset'
        return context.$axios.post(url, params)
    }

    const updatePasswordReset = (context: any, paramsPasswordReset: any) => {
        const params = {
            key: paramsPasswordReset.key,
            new_password: paramsPasswordReset.password
        }
        const url = `/cloudapi/users/${paramsPasswordReset.userId}/password`
        return context.$axios.patch(url, params)
    }

    return {
        logUser,
        logOutUser,
        sendPasswordReset,
        updatePasswordReset
    }
})

export default useAuthenticationStore
