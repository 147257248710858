export default function ({ error, store }) {
    if (
        store.getters['session/getUser'] &&
        !store.getters['session/isSupervisionManager']
    ) {
        error({
            statusCode: 401,
            message: 'errors.unauthorized'
        })
    }
}
