
import Vue from 'vue'

export default Vue.extend({
    props: {
        isDisabled: {
            type: Boolean,
            default: false
        },
        isEnd: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        toggleDropdown(slotProps: any, shouldUpdateRef: boolean = false) {
            if (slotProps) {
                slotProps.toggleShowList()
                if (shouldUpdateRef) {
                    slotProps.setButtonRef(this.$refs.buttonRef)
                }
            }
        }
    }
})
