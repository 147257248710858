import { createLocalVue, shallowMount } from '@vue/test-utils'

// @ts-ignore
import AppFlyout from '@/components/UI/AppFlyout.vue'

const localVue = createLocalVue()

describe('components/UI/AppFlyout.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppFlyout, {
            localVue
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="app-flyout"]')
        expect(component.exists()).toBeTruthy()
    })

    it('can display title', () => {
        let component = wrapper.find('[data-testid="app-flyout-title"]')
        expect(component.exists()).toBeFalsy()

        wrapper = shallowMount(AppFlyout, {
            localVue,
            slots: {
                title: '<div></div>'
            }
        })

        component = wrapper.find('[data-testid="app-flyout-title"]')
        expect(component.exists()).toBeTruthy()
    })
})
