export default function ({ store, route, error }) {
    const onFirstAttemptOrRefresh = process.server
    if (
        onFirstAttemptOrRefresh ||
        !store.getters['session/getUser'] ||
        !store.getters['organization/getOrganization']
    ) {
        return store
            .dispatch('session/loadUser')
            .then(() => store.dispatch('session/initSession', route))
            .then(() => {
                if (
                    !store.getters['session/hasAccessRights'] &&
                    route.name !== 'profile' &&
                    route.name !== 'login'
                ) {
                    return error({
                        statusCode: 403,
                        message: 'no_rights'
                    })
                }
            })
            .catch(() => {
                if (route.name !== 'login') {
                    return error({
                        statusCode: 500,
                        message: 'errors.oops'
                    })
                }
            })
    }
}
