import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'
// @ts-ignore
import MenuAdminSection from '@/components/UI/menu/MenuAdminSection.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()
localVue.use(Vuex)
const $store = {
    dispatch: jest.fn(() => Promise.resolve(12)),
    getters: {
        'session/isSuperAdmin': false
    }
}
describe('MenuAdminSection.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(MenuAdminSection, {
            ...basicMountWrapper,
            mocks: {
                ...basicMountWrapper.mocks,
                $store
            },
            localVue
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="menu-admin-section"]')
        expect(component.exists()).toBeTruthy()
    })

    it("should be displayed if it's super admin", () => {
        $store.getters['session/isSuperAdmin'] = true
        expect(wrapper.vm.adminSections.authorized).toBeTruthy()
        $store.getters['session/isSuperAdmin'] = false
        expect(wrapper.vm.adminSections.authorized).toBeFalsy()
    })
    it('switchMenu', () => {
        expect(wrapper.emitted().selected).toEqual(undefined)
        wrapper.vm.switchMenu(1)
        expect(wrapper.emitted().selected[0]).toEqual([1])
    })

    it('closeMobileMenu', () => {
        expect(wrapper.emitted().closeMobileMenu).toEqual(undefined)
        wrapper.vm.closeMobileMenu()
        expect(wrapper.emitted().closeMobileMenu[0]).toEqual([])
    })
})
