
import Vue from 'vue'

export default Vue.extend({
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        hasData: {
            type: Boolean,
            default() {
                return false
            }
        }
    }
})
