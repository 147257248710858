
import { mapActions, mapState } from 'pinia'
import useMobileFilterStore from '~/store/mobileFilter'
import Collaborator from '~/components/UI/images/Collaborator.vue'
import BadgeChargemapBusiness from '~/components/UI/images/BadgeChargemapBusiness.vue'
import BadgePrivate from '~/components/UI/images/BadgePrivate.vue'
import BadgeClassic from '~/components/UI/images/BadgeClassic.vue'
import ChargingVehicle from '~/components/UI/images/ChargingVehicle.vue'
import Invoice from '~/components/UI/images/Invoice.vue'
import WallBox from '~/components/UI/images/WallBox.vue'
import Dashboard from '~/components/UI/images/Dashboard.vue'
import OfficeBuilding from '~/components/UI/images/OfficeBuilding.vue'
import BusinessUnit from '~/components/UI/images/BusinessUnit.vue'
import Vehicle from '~/components/UI/images/Vehicle.vue'
import Log from '~/components/UI/images/Log.vue'
import Settings from '~/components/UI/images/Settings.vue'
import ChargingArea from '~/components/UI/images/ChargingArea.vue'
import Delivery from '~/components/UI/images/Delivery.vue'
import IconArrowLeft from '~/components/UI/icons/layout/IconArrowLeft.vue'
import IconBurger from '~/components/UI/icons/layout/IconBurger.vue'
import IconSearch from '~/components/UI/icons/input/IconSearch.vue'

export default {
    // https://tailwindui.com/components/application-ui/headings/section-headings
    name: 'AppTitle',
    components: {
        IconArrowLeft,
        IconBurger,
        IconSearch,
        WallBox,
        Dashboard,
        OfficeBuilding,
        Invoice,
        ChargingVehicle,
        BadgeChargemapBusiness,
        BadgePrivate,
        BadgeClassic,
        Collaborator,
        BusinessUnit,
        Vehicle,
        Log,
        Settings,
        ChargingArea,
        Delivery
    },
    props: {
        picture: {
            type: String,
            default: 'wall-box'
        },
        title: {
            type: String,
            default: ''
        },
        showBackButton: {
            type: Boolean,
            default: false
        },
        hasFilter: {
            type: Boolean,
            default: undefined
        }
    },
    head() {
        return {
            title: this.title
        }
    },
    computed: {
        ...mapState(useMobileFilterStore, {
            showBottomSheet: 'getShowBottomSheet',
            showSearchBar: 'getShowSearchBar',
            filters: 'getFilters',
            hasSearch: 'getHasSearch'
        }),
        searchEmpty() {
            return this.search ? this.search.length <= 0 : true
        }
    },
    methods: {
        ...mapActions(useMobileFilterStore, [
            'setShowBottomSheet',
            'setShowSearchBar'
        ]),
        goBack() {
            this.$router.go(-1)
        },
        toggleMobileMenu() {
            this.$menu.$emit('toggle')
        },
        toggleBottomSheet() {
            this.setShowBottomSheet(!this.showBottomSheet)
        },
        toggleSearch() {
            this.setShowSearchBar(!this.showSearchBar)
        }
    }
}
