
import { mapActions, mapState } from 'pinia'
import useMobileFilterStore from '~/store/mobileFilter'
import IconClose from '~/components/UI/icons/notification/IconClose.vue'

export default {
    name: 'AppBottomSheet',
    components: { IconClose },
    computed: {
        ...mapState(useMobileFilterStore, {
            filters: 'getFilters',
            showBottomSheet: 'getShowBottomSheet'
        })
    },
    beforeDestroy() {
        this.resetFilters()
    },
    methods: {
        ...mapActions(useMobileFilterStore, [
            'setFilters',
            'removeFilter',
            'updateFilter',
            'resetFilter',
            'setToUpdate',
            'setShowBottomSheet'
        ]),
        ...mapState(useMobileFilterStore, {
            addNewFilter: 'addFilter',
            removeAFilter: 'removeFilter'
        }),
        resetFilters(newFilters) {
            this.setFilters(newFilters || [])
        },
        addFilter(newFilter) {
            this.addNewFilter(newFilter)
        },
        removeFilter(filterId) {
            this.removeAFilter(filterId)
        },
        update(filter) {
            this.updateFilter(filter)
        },
        reset() {
            this.resetFilter()
            this.setToUpdate(true)
        },
        close() {
            this.setToUpdate(true)
            this.setShowBottomSheet(!this.showBottomSheet)
        }
    }
}
