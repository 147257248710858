var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-col w-full h-max",attrs:{"data-testid":"app-input"}},[(_vm.label)?_c('label',{attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"relative flex items-center w-full overflow-hidden"},[_c('input',_vm._b({ref:"AppInput",staticClass:"w-11/12 py-2.5 border",class:[
                !_vm.isValid
                    ? 'border-red-500 border-2 placeholder:text-red-500'
                    : 'border-gray-300',
                _vm.canShowEyeIcon || _vm.canShowSearchIcon || _vm.canShowXCircleIcon
                    ? 'pl-3 pr-7'
                    : 'px-3',
                _vm.disabled && 'bg-gray-50 !placeholder:text-gray-400'
            ],attrs:{"id":_vm.id,"type":_vm.getTypeInput,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateValue($event.target.value)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('keyUpEnter', $event)},"blur":function($event){return _vm.$emit('blur', $event)}}},'input',_vm.$attrs,false)),_vm._v(" "),_c('div',{staticClass:"absolute flex flex-inline items-center",style:(`left:${_vm.calculatePositionLeft}rem`)},[(_vm.unit && _vm.hasValue)?_c('span',{staticClass:"mr-2",class:_vm.isValid ? 'text-gray-400' : 'text-red-900',attrs:{"data-testid":"app-input-unit"}},[_vm._v("\n                "+_vm._s(_vm.unit)+"\n            ")]):_vm._e()]),_vm._v(" "),(_vm.canShowEyeIcon)?_c('div',{staticClass:"absolute right-2 flex flex-inline items-center cursor-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.handleReveal.apply(null, arguments)}}},[(_vm.isRevealed)?_c('IconEyeClosed',{staticClass:"text-gray-700 h-5 w-5"}):_c('IconEye',{staticClass:"text-gray-700 h-5 w-5"})],1):_vm._e(),_vm._v(" "),(_vm.canShowSearchIcon)?_c('div',{staticClass:"absolute right-2 flex flex-inline items-center z-50"},[_c('IconSearch',{staticClass:"text-gray-700 h-5 w-5"})],1):_vm._e(),_vm._v(" "),(_vm.canShowXCircleIcon)?_c('div',{staticClass:"absolute right-2 flex flex-inline items-center z-50",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('input', '')}}},[(_vm.hasValue)?_c('IconXCircle',{staticClass:"h-5 w-5 cursor-pointer",class:_vm.isValid ? 'text-gray-500' : 'text-red-500'}):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isValid && !!_vm.errorMessage),expression:"!isValid && !!errorMessage"}],staticClass:"mt-2 text-sm text-red-600"},[_vm._v("\n        "+_vm._s(_vm.errorMessage)+"\n    ")]),_vm._v(" "),_c('canvas',{ref:"canvasTextInput",staticClass:"hidden"})])
}
var staticRenderFns = []

export { render, staticRenderFns }