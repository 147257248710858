
import Vue from 'vue'

export default Vue.extend({
    name: 'IconInfoCircle',
    props: {
        isSolid: {
            type: Boolean,
            default: true
        }
    }
})
