
import Vue from 'vue'
import { ConnectorIconStatus } from '~/interfaces/connector'
import { evseStatuses } from '~/interfaces/chargebox'
import IconCheckCircle from '@/components/UI/icons/IconCheckCircle.vue'
import IconFlag from '@/components/UI/icons/IconFlag.vue'
import IconChargingInactivity from '@/components/UI/icons/IconChargingInactivity.vue'
import IconCharging from '@/components/UI/icons/IconCharging.vue'
import IconXCircle from '@/components/UI/icons/IconXCircle.vue'
import IconBan from '@/components/UI/icons/IconBan.vue'
import IconQuestionMark from '@/components/UI/icons/IconQuestionMark.vue'

export default Vue.extend({
    components: {
        IconCheckCircle,
        IconFlag,
        IconChargingInactivity,
        IconCharging,
        IconXCircle,
        IconBan,
        IconQuestionMark
    },
    props: {
        status: {
            type: String,
            required: true
        }
    },
    methods: {
        icon(status: evseStatuses) {
            return ConnectorIconStatus.get(status) ?? 'IconBan'
        }
    }
})
