import { createLocalVue, mount } from '@vue/test-utils'

// @ts-ignore
import AppBottomSheet from '@/components/UI/AppBottomSheet.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'
import useMobileFilter from '~/store/mobileFilter'

const localVue = createLocalVue()

describe('components/UI/AppBottomSheet.vue', () => {
    let wrapper: any
    let mobileFilterStore: any

    beforeEach(() => {
        wrapper = mount(AppBottomSheet, {
            ...basicMountWrapper,
            localVue
        })

        mobileFilterStore = useMobileFilter()
        mobileFilterStore.filters = [
            {
                id: 1,
                value: 'test'
            }
        ]
        mobileFilterStore.showBottomSheet = true
        mobileFilterStore.showSearchBar = false
        mobileFilterStore.hasSearch = false
        mobileFilterStore.toUpdate = false
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const bsFilter = wrapper.find('.bs-filter')
        expect(bsFilter.exists()).toBeTruthy()
    })

    it('should resetFilters', () => {
        const spyStoreCall = jest.spyOn(wrapper.vm, 'setFilters')
        wrapper.vm.resetFilters([{ id: 1, value: 'test' }])
        expect(spyStoreCall).toHaveBeenCalledWith([{ id: 1, value: 'test' }])
    })
    it('should addFilter', () => {
        const spyStoreCall = jest.spyOn(wrapper.vm, 'addNewFilter')
        wrapper.vm.addFilter({ id: 1, value: 'test' })
        expect(spyStoreCall).toHaveBeenCalledWith({
            id: 1,
            value: 'test'
        })
    })
    it('should removeFilter', () => {
        const spyStoreCall = jest.spyOn(wrapper.vm, 'removeAFilter')
        wrapper.vm.removeFilter(1)
        expect(spyStoreCall).toHaveBeenCalledWith(1)
    })
    it('update', () => {
        const spyStoreCall = jest.spyOn(wrapper.vm, 'updateFilter')
        wrapper.vm.update({ id: 1, value: 'test' })
        expect(spyStoreCall).toHaveBeenCalledWith({ id: 1, value: 'test' })
    })

    it('reset', () => {
        const spyStoreReset = jest.spyOn(wrapper.vm, 'resetFilter')
        const spyStoreUpdate = jest.spyOn(wrapper.vm, 'setToUpdate')
        wrapper.vm.reset()
        expect(spyStoreReset).toHaveBeenCalled()
        expect(spyStoreUpdate).toHaveBeenCalledWith(true)
    })

    it('close', () => {
        const spyStoreShowBottomSheet = jest.spyOn(
            wrapper.vm,
            'setShowBottomSheet'
        )
        const spyStoreUpdate = jest.spyOn(wrapper.vm, 'setToUpdate')
        wrapper.vm.close()
        expect(spyStoreShowBottomSheet).toHaveBeenCalledWith(false)
        expect(spyStoreUpdate).toHaveBeenCalledWith(true)
    })
})
