import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'
// @ts-ignore
import AppDetailFormLine from '@/components/UI/detailSection/AppDetailFormLine.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()
localVue.use(Vuex)

const props = {
    inputId: '',
    isOptional: false,
    mode: 'line',
    isDisabled: false,
    fromTemplate: false,
    hasEmptyLabel: false,
    noBorderLine: false
}

const $emit = jest.fn()
describe('components/UI/AppDetailFormLine.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppDetailFormLine, {
            ...basicMountWrapper,
            localVue,
            propsData: props,
            mocks: {
                ...basicMountWrapper.mocks,
                $emit
            }
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="app-detail-form-line"]')
        expect(component.exists()).toBeTruthy()
    })

    it('can return classForm', async () => {
        expect(wrapper.vm.classForm).toBe('detail-section__form-line')

        await wrapper.setProps({
            ...props,
            mode: 'inline'
        })

        expect(wrapper.vm.classForm).toBe('detail-section__form-inline')
    })

    it('can display label if emptyLabel is true', async () => {
        let component = wrapper.find(
            '[data-testid="app-detail-form-line-label"]'
        )
        expect(component.exists()).toBeFalsy()

        await wrapper.setProps({
            ...props,
            hasEmptyLabel: true
        })

        component = wrapper.find('[data-testid="app-detail-form-line-label"]')
        expect(component.exists()).toBeTruthy()
    })

    it('can display optional if isOptional is true', async () => {
        let component = wrapper.find(
            '[data-testid="app-detail-form-line-optional"]'
        )
        expect(component.exists()).toBeFalsy()

        await wrapper.setProps({
            ...props,
            hasEmptyLabel: true,
            isOptional: true
        })

        component = wrapper.find(
            '[data-testid="app-detail-form-line-optional"]'
        )
        expect(component.exists()).toBeTruthy()
    })
})
