import { createLocalVue, shallowMount } from '@vue/test-utils'

// @ts-ignore
import ChargemapPagination from '@/components/UI/CMUI/ChargemapPagination.vue'

import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()

const props = {
    value: 1,
    perPage: 10,
    total: 20,
    range: 1
}

describe('components/UI/CMUI/ChargemapPagination.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(ChargemapPagination, {
            ...basicMountWrapper,
            localVue,
            propsData: props
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="chargemap-pagination"]')

        expect(component.exists()).toBeTruthy()
    })

    describe('computed', () => {
        it('can return start', () => {
            expect(wrapper.vm.start).toBe(1)

            wrapper = shallowMount(ChargemapPagination, {
                ...basicMountWrapper,
                localVue,
                propsData: {
                    ...props,
                    total: 0
                }
            })

            expect(wrapper.vm.start).toBe(0)
        })

        it('can return end', async () => {
            expect(wrapper.vm.end).toBe(10)
            await wrapper.setProps({
                ...props,
                total: 4
            })
            expect(wrapper.vm.end).toBe(4)
        })

        it('can return rangeBefore', async () => {
            await wrapper.setProps({
                ...props,
                value: 2,
                total: 10,
                perPage: 5
            })

            expect(wrapper.vm.rangeBefore).toEqual([1])
        })

        it('can return rangeAfter', async () => {
            await wrapper.setProps({
                ...props,
                value: 2,
                total: 18,
                perPage: 5
            })

            expect(wrapper.vm.rangeAfter).toEqual([3])
        })

        it('can return paginationText', async () => {
            await wrapper.setProps({
                ...props,
                value: 1,
                total: 4,
                perPage: 5
            })

            expect(wrapper.vm.paginationText).toBe('inputs.pagination.text')
        })
    })
})
