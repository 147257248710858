import { createLocalVue, mount } from '@vue/test-utils'

// @ts-ignore
import AppFilter from '~/components/UI/AppFilter.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'
import useMobileFilter from '~/store/mobileFilter'

const localVue = createLocalVue()

const $emit = jest.fn()

describe('AppFilter.vue', () => {
    let wrapper: any
    let mobileFilterStore: any

    beforeEach(() => {
        wrapper = mount(AppFilter, {
            ...basicMountWrapper,
            localVue,
            propsData: {
                value: undefined,
                elements: [
                    {
                        id: 0,
                        translation: 'Filtrer par état',
                        value: undefined
                    },
                    {
                        id: 1,
                        translation: 'Tous les états',
                        value: null
                    }
                ]
            },
            mocks: {
                ...basicMountWrapper.mocks,
                $emit
            }
        })

        mobileFilterStore = useMobileFilter()
        mobileFilterStore.filters = []
        mobileFilterStore.showBottomSheet = false
        mobileFilterStore.showSearchBar = false
        mobileFilterStore.hasSearch = false
        mobileFilterStore.toUpdate = false
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        expect(wrapper.vm.value).toEqual(null)
        expect(wrapper.vm.label).toEqual(undefined)
        expect(wrapper.vm.elementsAvailable).toEqual([
            {
                id: 1,
                translation: 'Tous les états',
                value: null
            }
        ])
    })

    it('set elements available', async () => {
        await wrapper.setProps({
            elements: [
                {
                    id: 0,
                    translation: 'Filtrer par état',
                    value: undefined
                },
                {
                    id: 1,
                    translation: 'Tous les états',
                    value: null
                },
                {
                    id: 2,
                    translation: 'En attente',
                    value: null
                },
                {
                    id: 3,
                    translation: 'Réglé',
                    value: null
                }
            ]
        })
        expect(wrapper.vm.elementsAvailable).toEqual([
            {
                id: 1,
                translation: 'Tous les états',
                value: null
            },
            {
                id: 2,
                translation: 'En attente',
                value: null
            },
            {
                id: 3,
                translation: 'Réglé',
                value: null
            }
        ])
    })

    it('label error', async () => {
        await wrapper.setProps({
            value: 'test'
        })
        expect(wrapper.vm.label).toBe('Error not a value available')
        await wrapper.setProps({
            elements: []
        })
        expect(wrapper.vm.label).toBe('Error not a value available')
    })

    it('update', () => {
        const spyStoreCall = jest.spyOn(wrapper.vm, 'update')
        wrapper.vm.update('test value')
        expect($emit).toHaveBeenCalledWith('input', 'test value')
        expect(spyStoreCall).toHaveBeenCalled()
    })

    it('updateLocalFilter', () => {
        const uid = wrapper.vm._uid
        const badFilter = { id: 999, value: 'bad' }
        const filterValue = { id: uid, value: 'text' }
        const spyUpdate = jest.spyOn(wrapper.vm, 'update')
        wrapper.vm.updateLocalFilter(badFilter)
        expect(spyUpdate).not.toHaveBeenCalled()
        wrapper.vm.updateLocalFilter(filterValue)
        expect(spyUpdate).toHaveBeenCalledWith('text')
    })
})
