// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Vue from 'vue'
import { keysToSnake } from './utils'

declare module 'vue/types/vue' {
    interface Vue {
        $toSnake: (e: any) => any
    }
}

export default (_: any, inject: any) => {
    inject('toSnake', (obj: any) => keysToSnake(obj))
}
