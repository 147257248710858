
import Vue from 'vue'

export default Vue.extend({
    name: 'AppDetailSection',
    props: {
        showList: {
            type: Boolean,
            default() {
                return true
            }
        },
        showForm: {
            type: Boolean,
            default() {
                return true
            }
        },
        paddingClass: {
            type: String,
            default() {
                return 'py-4'
            }
        },
        paddingClassList: {
            type: String,
            default: ''
        },
        isDivided: {
            type: Boolean,
            default: false
        }
    }
})
