import useFlaggyStore from '~/store/flaggy'

export default function (context) {
    const { error } = context
    const flaggyStore = useFlaggyStore()

    const { hasFeatureActivated } = flaggyStore
    const message = 'errors.no_access'

    if (!hasFeatureActivated('orders')) {
        return error({ statusCode: 403, message })
    }
}
