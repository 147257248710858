import Vue from 'vue'
import AppPage from '~/components/UI/AppPage.vue'
import AppAlert from '~/components/UI/AppAlert.vue'
import AppBadge from '~/components/UI/AppBadge.vue'
import AppBottomSheet from '~/components/UI/AppBottomSheet.vue'
import AppFilter from '~/components/UI/AppFilter.vue'
import AppCheckbox from '~/components/UI/AppCheckbox.vue'
import AppFilterOptions from '~/components/UI/bottomSheetFilters/AppFilterOptions.vue'
import AppFilterCheckbox from '~/components/UI/bottomSheetFilters/AppFilterCheckbox.vue'
import AppButton from '~/components/UI/AppButton.vue'
import AppControlInput from '~/components/UI/AppControlInput.vue'
import AppLoader from '~/components/UI/AppLoader.vue'
import ChargemapPagination from '~/components/UI/CMUI/ChargemapPagination.vue'
import AppSearch from '~/components/UI/AppSearch.vue'
import AppSwitch from '~/components/UI/AppSwitch.vue'
import AppInput from '~/components/UI/AppInput.vue'
import AppTabs from '~/components/UI/AppTabs.vue'
import AppTitle from '~/components/UI/AppTitle.vue'
import HeaderButton from '~/components/UI/HeaderButton.vue'
import ConnectorChargebox from '~/components/supervision/charges/ConnectorChargebox.vue'
import IconBusiness from '~/components/UI/icons/layout/IconBusiness.vue'
import IconCheck from '~/components/UI/icons/dropdown/IconCheck.vue'
import IconCross from '~/components/UI/icons/layout/IconCross.vue'
import IconRenew from '~/components/UI/icons/IconRenew.vue'
import IconCrossed from '~/components/UI/icons/IconCrossed.vue'
import IconUser from '~/components/UI/icons/cloud/IconUser.vue'
import IconFleet from '~/components/UI/icons/layout/IconFleet.vue'
import IconStation from '~/components/UI/icons/layout/IconStation.vue'
import IconBadgeCheck from '~/components/UI/icons/IconBadgeCheck.vue'
import IconChevron from '~/components/UI/icons/IconChevron.vue'
import IconLibrary from '~/components/UI/icons/cloud/IconLibrary.vue'
import IconDotVertical from '~/components/UI/icons/dropdown/IconDotVertical.vue'
import IconEye from '~/components/UI/icons/cloud/IconEye.vue'
import IconFilter from '~/components/UI/icons/IconFilter.vue'
import IconMail from '~/components/UI/icons/cloud/IconMail.vue'
import IconOffice from '~/components/UI/icons/cloud/IconOffice.vue'
import IconUserCircle from '~/components/UI/icons/cloud/IconUserCircle.vue'
import IconCheckCircle from '~/components/UI/icons/IconCheckCircle.vue'
import IconPen from '~/components/UI/icons/IconPen.vue'
import IconDots from '~/components/UI/icons/IconDots.vue'
import IconDownload from '~/components/UI/icons/IconDownload.vue'
import IconDustBin from '~/components/UI/icons/IconDustBin.vue'
import IconX from '~/components/UI/icons/IconX.vue'
import IconPlus from '~/components/UI/icons/IconPlus.vue'
import IconCar from '~/components/UI/icons/cloud/IconCar.vue'
import IconCollaborators from '~/components/UI/icons/layout/IconCollaborators.vue'
import IconVehicle from '~/components/UI/icons/layout/IconVehicle.vue'
import IconPass from '~/components/UI/icons/layout/IconPass.vue'
import IconTagDelete from '~/components/UI/icons/IconTagDelete.vue'
import IconRedirect from '~/components/UI/icons/cloud/IconRedirect.vue'
import IconWarning from '~/components/UI/icons/IconWarning.vue'
import IconWarn from '~/components/UI/icons/notification/IconWarn.vue'
import IconBell from '~/components/UI/icons/IconBell.vue'
import IconWifi from '~/components/UI/icons/IconWifi.vue'
import IconNoWifi from '~/components/UI/icons/IconNoWifi.vue'
import IconExclamation from '~/components/UI/icons/IconExclamation.vue'
import IconChargingStatus from '~/components/UI/icons/IconChargingStatus.vue'
import IconPluggedCar from '~/components/UI/icons/IconPluggedCar.vue'
import IconWarningSignal from '~/components/UI/icons/IconWarningSignal.vue'
import IconNewsPaper from '~/components/UI/icons/IconNewsPaper.vue'
import IconRefresh from '~/components/UI/icons/IconRefresh.vue'
import IconInfoCircle from '~/components/UI/icons/IconInfoCircle.vue'
import ChargemapSelect from '~/components/UI/CMUI/ChargemapSelect.vue'
import AppDetailSection from '~/components/UI/detailSection/AppDetailSection.vue'
import AppDetailSectionLine from '~/components/UI/detailSection/AppDetailSectionLine.vue'
import AppDetailFormLine from '~/components/UI/detailSection/AppDetailFormLine.vue'
import AppDetailSectionActions from '~/components/UI/detailSection/AppDetailSectionActions.vue'
import ChargemapDropdown from '~/components/UI/CMUI/ChargemapDropdown.vue'
import AppDropdown from '~/components/UI/AppDropdown.vue'

Vue.component('AppPage', AppPage)
Vue.component('AppAlert', AppAlert)
Vue.component('AppBadge', AppBadge)
Vue.component('AppBottomSheet', AppBottomSheet)
Vue.component('AppFilter', AppFilter)
Vue.component('AppCheckbox', AppCheckbox)
Vue.component('AppFilterOptions', AppFilterOptions)
Vue.component('AppFilterCheckbox', AppFilterCheckbox)
Vue.component('AppButton', AppButton)
Vue.component('AppControlInput', AppControlInput)
Vue.component('AppLoader', AppLoader)
Vue.component('ChargemapPagination', ChargemapPagination)
Vue.component('ChargemapSelect', ChargemapSelect)
Vue.component('AppSearch', AppSearch)
Vue.component('AppSwitch', AppSwitch)
Vue.component('AppInput', AppInput)
Vue.component('AppTabs', AppTabs)
Vue.component('AppTitle', AppTitle)
Vue.component('HeaderButton', HeaderButton)
Vue.component('ConnectorChargebox', ConnectorChargebox)
Vue.component('IconBusiness', IconBusiness)
Vue.component('IconCheck', IconCheck)
Vue.component('IconCross', IconCross)
Vue.component('IconRenew', IconRenew)
Vue.component('IconCrossed', IconCrossed)
Vue.component('IconUser', IconUser)
Vue.component('IconFleet', IconFleet)
Vue.component('IconStation', IconStation)
Vue.component('IconBadgeCheck', IconBadgeCheck)
Vue.component('IconChevron', IconChevron)
Vue.component('IconLibrary', IconLibrary)
Vue.component('IconDotVertical', IconDotVertical)
Vue.component('IconEye', IconEye)
Vue.component('IconFilter', IconFilter)
Vue.component('IconMail', IconMail)
Vue.component('IconOffice', IconOffice)
Vue.component('IconUserCircle', IconUserCircle)
Vue.component('IconPen', IconPen)
Vue.component('IconDots', IconDots)
Vue.component('IconDownload', IconDownload)
Vue.component('IconDustBin', IconDustBin)
Vue.component('IconX', IconX)
Vue.component('IconPlus', IconPlus)
Vue.component('IconCar', IconCar)
Vue.component('IconCollaborators', IconCollaborators)
Vue.component('IconVehicle', IconVehicle)
Vue.component('IconPass', IconPass)
Vue.component('IconTagDelete', IconTagDelete)
Vue.component('IconRedirect', IconRedirect)
Vue.component('IconWarning', IconWarning)
Vue.component('IconWarn', IconWarn)
Vue.component('IconBell', IconBell)
Vue.component('IconWifi', IconWifi)
Vue.component('IconNoWifi', IconNoWifi)
Vue.component('IconChargingStatus', IconChargingStatus)
Vue.component('IconPluggedCar', IconPluggedCar)
Vue.component('IconWarningSignal', IconWarningSignal)
Vue.component('IconNewsPaper', IconNewsPaper)
Vue.component('IconExclamation', IconExclamation)
Vue.component('IconRefresh', IconRefresh)
Vue.component('IconCheckCircle', IconCheckCircle)
Vue.component('IconInfoCircle', IconInfoCircle)
Vue.component('AppDetailSection', AppDetailSection)
Vue.component('AppDetailSectionLine', AppDetailSectionLine)
Vue.component('AppDetailFormLine', AppDetailFormLine)
Vue.component('AppDetailSectionActions', AppDetailSectionActions)
Vue.component('ChargemapDropdown', ChargemapDropdown)
Vue.component('AppDropdown', AppDropdown)
