import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'
// @ts-ignore
import AppFilterOptions from '@/components/UI/bottomSheetFilters/AppFilterOptions.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()
localVue.use(Vuex)

const $emit = jest.fn()
describe('components/UI/bottomSheetFilters/AppFilterOptions.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppFilterOptions, {
            ...basicMountWrapper,
            localVue,
            propsData: {
                filter: {
                    id: 58,
                    value: [30, 150],
                    options: [
                        {
                            id: 1,
                            name: 'test 1',
                            value: [0, 50]
                        },
                        {
                            id: 2,
                            name: 'test 2',
                            value: [40, 200]
                        },
                        {
                            id: 3,
                            name: 'test 3',
                            value: [80, 350]
                        }
                    ]
                }
            },
            mocks: {
                ...basicMountWrapper.mocks,
                $emit
            }
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const bsFilter = wrapper.find('.bs-filter')
        expect(bsFilter.exists()).toBeTruthy()
    })

    it('update', () => {
        const newValue = 'test'
        expect($emit).not.toHaveBeenCalled()
        wrapper.vm.update(newValue)
        expect($emit).toHaveBeenCalledWith('update', {
            id: wrapper.vm.filter.id,
            value: newValue
        })
    })
})
