import { defineStore } from 'pinia'
import { CostCenter, CostCentersJSON } from '~/interfaces/businessunit'

const useCostCenterStore = defineStore('costCenter', () => {
    const getCostCenters = (
        context: any,
        payload: { idOrga: number; idBU: number; filters: any }
    ) => {
        const { index, perPage, search } = payload.filters
        const offset = (index - 1) * perPage || (0 as number)
        const limit = perPage || (20 as number)
        const keyword = search || null
        const params = {
            offset,
            limit,
            keyword
        }

        const { idOrga, idBU } = payload
        return context.$axios
            .$get(
                `/cloudapi/organizations/${idOrga}/business-units/${idBU}/cost-centers`,
                { params }
            )
            .then((res: CostCentersJSON) => context.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    }

    const postCostCenter = (
        context: any,
        payload: { idOrga: number; idBU: number; costCenterName: string }
    ) => {
        const { idOrga, idBU, costCenterName } = payload
        return context.$axios
            .$post(
                `/cloudapi/organizations/${idOrga}/business-units/${idBU}/cost-centers`,
                { name: costCenterName }
            )
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    }

    const updateCostCenter = (
        context: any,
        payload: { idOrga: number; idBU: number; costCenter: CostCenter }
    ) => {
        const { idOrga, idBU, costCenter } = payload
        return context.$axios
            .$put(
                `/cloudapi/organizations/${idOrga}/business-units/${idBU}/cost-centers/${costCenter.id}`,
                { name: costCenter.name }
            )
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    }

    const deleteCostCenter = (
        context: any,
        payload: { idOrga: number; idBU: number; costCenter: CostCenter }
    ) => {
        const { idOrga, idBU, costCenter } = payload
        return context.$axios
            .$delete(
                `/cloudapi/organizations/${idOrga}/business-units/${idBU}/cost-centers/${costCenter.id}`
            )
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    }

    return {
        getCostCenters,
        postCostCenter,
        updateCostCenter,
        deleteCostCenter
    }
})

type CostCenterStore = Omit<
    ReturnType<typeof useCostCenterStore>,
    keyof ReturnType<typeof defineStore>
>

export default useCostCenterStore

export type { CostCenterStore }
