import { createLocalVue, shallowMount } from '@vue/test-utils'

// @ts-ignore
import AppSearch from '@/components/UI/AppSearch.vue'

// Mocks
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()

const $emit = jest.fn()
const propsData = {
    value: 'search test',
    type: 'text'
}

describe('components/UI/AppSearch.vue', () => {
    let wrapper: any

    beforeAll(() => {
        const div = document.createElement('div')
        div.id = 'root'
        document.body.appendChild(div)
        wrapper = shallowMount(AppSearch, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks,
                $emit,
                $device: {
                    isDesktop: true
                }
            },
            attachTo: '#root',
            propsData
        })
    })

    afterEach(() => {
        wrapper.setProps(propsData)
        jest.clearAllMocks()
    })

    it('is a Vue instance', async () => {
        wrapper.vm.$refs.inputSearch.focus = jest.fn()
        expect(wrapper.vm).toBeTruthy()
        expect(wrapper.vm.value).toBe('search test')
        expect(wrapper.vm.id).toBe('input-search')
        expect(wrapper.vm.name).toBe('search')
        expect(wrapper.vm.type).toBe('text')
        await localVue.nextTick()
        const input = wrapper.find('input')
        expect(input.element).toHaveFocus()
        const component = wrapper.find('[data-testid="app-search"]')
        expect(component.exists()).toBeTruthy()
    })

    it('watch value', () => {
        expect(wrapper.vm.search).toBe('search test')
        wrapper.setProps({ value: 'update search' })
        wrapper.vm.$nextTick(() => {
            expect(wrapper.vm.search).toBe('update search')
        })
    })

    it('onInput', async () => {
        expect(wrapper.vm.controlInput()).toBeTruthy()
        wrapper.vm.onInput()
        expect($emit).toHaveBeenCalledWith('input', 'search test')
    })

    it('onInput with invalid mail', async () => {
        await wrapper.setProps({
            value: 'coucou@mail',
            type: 'email'
        })
        expect(wrapper.vm.controlInput()).toBeFalsy()
        wrapper.vm.onInput()
        expect($emit).not.toHaveBeenCalled()
    })

    it('onChange', () => {
        expect(wrapper.vm.controlInput()).toBeTruthy()
        wrapper.vm.onChange()
        expect($emit).toHaveBeenCalledWith('change', 'search test')
    })

    it('onChange with invalid mail', async () => {
        await wrapper.setProps({
            value: 'coucou@mail',
            type: 'email'
        })
        expect(wrapper.vm.controlInput()).toBeFalsy()
        wrapper.vm.onChange()
        expect($emit).not.toHaveBeenCalled()
    })

    it('clear', () => {
        wrapper.vm.clear()
        expect($emit).toHaveBeenCalledWith('input', '')
        expect($emit).toHaveBeenCalledWith('change', '')
    })

    describe('controlInput', () => {
        it('search empty > true', () => {
            expect(wrapper.vm.controlInput()).toBeTruthy()
        })

        it('type email valid > return true', async () => {
            await wrapper.setProps({
                value: 'coucou@mail.fr',
                type: 'email'
            })
            expect(wrapper.vm.controlInput()).toBeTruthy()
        })

        it('type email invalid > return error', async () => {
            await wrapper.setProps({
                value: 'coucou@mail',
                type: 'email'
            })
            expect(wrapper.vm.controlInput()).toBeFalsy()
        })

        it('can show error', async () => {
            const component = wrapper.find('[data-testid="app-search-error"]')
            expect(component.element.style.getPropertyValue('display')).toBe('')

            await wrapper.setData({ error: '' })

            expect(component.element.style.getPropertyValue('display')).toBe(
                'none'
            )
        })
    })

    it('validEmail', () => {
        expect(wrapper.vm.validEmail('coucou@mail.com')).toBeTruthy()
        expect(wrapper.vm.validEmail('coucou@mail')).toBeFalsy()
        expect(wrapper.vm.validEmail('coucou+test@mail.fr')).toBeTruthy()
        expect(wrapper.vm.validEmail('coucoumail.com')).toBeFalsy()
        expect(wrapper.vm.validEmail('coucou@mail.c')).toBeFalsy()
        expect(wrapper.vm.validEmail('@coucou@mail.com')).toBeFalsy()
    })

    it('should update show mobile', () => {
        expect(wrapper.vm.showMobile).toBeFalsy()
        wrapper.vm.toggleMobile()
        expect(wrapper.vm.showMobile).toBeTruthy()
        wrapper.vm.toggleMobile(true)
        expect(wrapper.vm.showMobile).toBeTruthy()
        wrapper.vm.toggleMobile()
        expect(wrapper.vm.showMobile).toBeFalsy()
        wrapper.vm.toggleMobile(false)
        expect(wrapper.vm.showMobile).toBeFalsy()
    })

    it('can display clear icon', async () => {
        let component = wrapper.find('[data-testid="app-search-clear"]')
        expect(component.exists()).toBeTruthy()

        await wrapper.setProps({
            value: ''
        })

        component = wrapper.find('[data-testid="app-search-clear"]')
        expect(component.exists()).toBeFalsy()
    })

    it('can display clear icon', async () => {
        let component = wrapper.find('[data-testid="app-search-search"]')
        expect(component.exists()).toBeFalsy()

        await wrapper.setProps({
            value: ''
        })

        component = wrapper.find('[data-testid="app-search-search"]')
        expect(component.exists()).toBeTruthy()
    })
})
