
import Cookies from 'js-cookie'
import Vue from 'vue'

export default Vue.extend({
    // https://tailwindui.com/components/application-ui/feedback/alerts
    name: 'AppAlert',
    props: {
        type: {
            type: String,
            default: 'info',
            validator(value) {
                return [
                    'info',
                    'warning',
                    'error',
                    'validate',
                    'unknown'
                ].includes(value)
            }
        },
        hasIconWarning: {
            type: Boolean,
            default: false
        },
        identifier: {
            type: String,
            default() {
                return null
            }
        },
        showClose: {
            type: Boolean,
            default: false
        },
        rounded: {
            type: Boolean,
            default: true
        },
        shadowed: {
            type: Boolean,
            default: true
        },
        bordered: {
            type: Boolean,
            default: true
        },
        smallPadding: {
            type: Boolean,
            default: false
        },
        transition: {
            type: String,
            default: 'drop-fade'
        }
    },
    emits: ['callConfirm', 'callAction'],
    data: () => ({ visible: false }),
    computed: {
        color() {
            switch (this.type) {
                case 'error':
                    return 'red'
                case 'warning':
                    return 'yellow'
                case 'validate':
                    return 'green'
                case 'info':
                default:
                    return 'chargemap'
            }
        }
    },
    mounted() {
        if (!this.identifier) {
            this.visible = true
        } else {
            this.visible = !Cookies.get(this.identifier)
        }
    },
    methods: {
        callConfirm() {
            if (this.identifier) {
                this.stockVisit()
            }
            this.$emit('callConfirm')
        },
        callAction() {
            this.$emit('callAction')
        },
        dismiss() {
            if (this.identifier) {
                this.stockVisit()
            }
            this.visible = false
            this.$emit('close')
        },
        stockVisit() {
            if (this.identifier) {
                Cookies.set(this.identifier, 'true', {
                    expires: 365
                })
            }
        }
    }
})
