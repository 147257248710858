
import Vue from 'vue'

export default Vue.extend({
    name: 'AppFilterCheckbox',
    props: {
        filter: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            inputId: 'checkbox'
        }
    },

    mounted(this: any) {
        this.inputId = this._uid
    },
    methods: {
        update(evt: any) {
            this.$emit('update', {
                id: this.filter.id,
                value: evt.target.checked
            })
        }
    }
})
