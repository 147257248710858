import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'
// @ts-ignore
import MenuUserSection from '@/components/UI/menu/MenuUserSection.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()
localVue.use(Vuex)
const roleProfile = {
    'session/isSuperAdmin': false,
    'session/isBUAdmin': false,
    'session/isAdmin': false,
    'session/isFleetManager': false,
    'session/isSupervisionManager': false
}
const profile = {
    'organization/getOrganization': {
        id: 2,
        name: 'Tsup',
        role: 'MEMBER',
        businessUnits: [] as any,
        access: []
    },
    'businessunit/getBusinessUnit': null as null | {
        id: number
        name: string
        roles: string[]
    },
    'businessunit/hasFilled3OnboardingState': true,
    ...roleProfile
}

const withBUSelected = {
    'businessunit/getBusinessUnit': {
        id: 1,
        name: 'bu name',
        roles: []
    }
}

const withoutRight = {}
const withoutAccess = {}

const withSuperAdminProfile = {
    'session/isSuperAdmin': true
}
const withAdminProfile = {
    'session/isAdmin': true
}
const withBUAdminProfile = {
    'session/isBUAdmin': true
}
const withFleetProfile = {
    'session/isFleetManager': true
}
const withSupervisionProfile = {
    'session/isSupervisionManager': true
}

const $store = {
    getters: profile
}
describe('MenuUserSection.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(MenuUserSection, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                $store,
                ...basicMountWrapper.mocks
            }
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="menu-user-section"]')
        expect(component.exists()).toBeTruthy()
    })
    it('switchMenu', () => {
        expect(wrapper.emitted().selected).toEqual(undefined)
        wrapper.vm.switchMenu(1)
        expect(wrapper.emitted().selected[0]).toEqual([1])
    })

    it('closeMobileMenu', () => {
        expect(wrapper.emitted().closeMobileMenu).toEqual(undefined)
        wrapper.vm.closeMobileMenu()
        expect(wrapper.emitted().closeMobileMenu[0]).toEqual([])
    })
    describe('admin sections access', () => {
        const buListAccess = () =>
            wrapper.vm.menuSections[0].links[1].authorized
        const orderBillAccess = () =>
            wrapper.vm.menuSections[0].links[2].authorized

        describe('without bu selected', () => {
            describe('without access', () => {
                const matrixProfile = [
                    [withoutRight, false, false],
                    [withSuperAdminProfile, true, true],
                    [withAdminProfile, true, true],
                    [withBUAdminProfile, false, false],
                    [withFleetProfile, false, true],
                    [withSupervisionProfile, false, false]
                ]
                test.each(matrixProfile)(
                    'with role: %p',
                    (right, hasBuListAccess, hasOrderBillAccess) => {
                        $store.getters = {
                            ...profile,
                            ...right,
                            ...withoutAccess
                        }
                        expect(buListAccess()).toEqual(hasBuListAccess)
                        expect(orderBillAccess()).toEqual(hasOrderBillAccess)
                    }
                )
            })
        })
        describe('with bu selected', () => {
            const profileWithBuSelected = { ...profile, ...withBUSelected }
            describe('without access', () => {
                const matrixProfile = [
                    [withoutRight, false, false],
                    [withSuperAdminProfile, false, true],
                    [withAdminProfile, false, true],
                    [withBUAdminProfile, false, true],
                    [withFleetProfile, false, true],
                    [withSupervisionProfile, false, false]
                ]
                test.each(matrixProfile)(
                    'with role: %p',
                    (right, hasBuListAccess, hasOrderBillAccess) => {
                        $store.getters = {
                            ...profileWithBuSelected,
                            ...right,
                            ...withoutAccess
                        }
                        expect(buListAccess()).toEqual(hasBuListAccess)
                        expect(orderBillAccess()).toEqual(hasOrderBillAccess)
                    }
                )
            })
        })

        describe('with hasFilled3OnboardingState falsy', () => {
            const profileWithBuSelected = { ...profile, ...withBUSelected }
            describe('without access', () => {
                const matrixProfile = [
                    [withoutRight, false, false],
                    [withSuperAdminProfile, false, true],
                    [withAdminProfile, false, false],
                    [withBUAdminProfile, false, false],
                    [withFleetProfile, false, false],
                    [withSupervisionProfile, false, false]
                ]
                test.each(matrixProfile)(
                    'with role: %p',
                    (right, hasBuListAccess, hasOrderBillAccess) => {
                        $store.getters = {
                            ...profileWithBuSelected,
                            ...right,
                            ...withoutAccess,
                            'businessunit/hasFilled3OnboardingState': false
                        }
                        expect(buListAccess()).toEqual(hasBuListAccess)
                        expect(orderBillAccess()).toEqual(hasOrderBillAccess)
                    }
                )
            })
        })
    })
    describe('other sections access', () => {
        const OrganizationParameterAccess = () =>
            wrapper.vm.menuSections[4].links[0].authorized
        const BUParameterAccess = () =>
            wrapper.vm.menuSections[4].links[1].authorized
        const userAccess = () => wrapper.vm.menuSections[4].links[2].authorized

        describe('without bu selected', () => {
            describe('without access', () => {
                const matrixProfile = [
                    [withoutRight, false, false, false],
                    [withSuperAdminProfile, true, false, true],
                    [withAdminProfile, true, false, true],
                    [withBUAdminProfile, false, false, true],
                    [withFleetProfile, false, false, false],
                    [withSupervisionProfile, false, false, false]
                ]
                test.each(matrixProfile)(
                    'with role: %p',
                    (
                        right,
                        hasOrganizationParameterAccess,
                        hasBUParameterAccess,
                        hasUserAccess
                    ) => {
                        $store.getters = {
                            ...profile,
                            ...right,
                            ...withoutAccess
                        }
                        expect(OrganizationParameterAccess()).toEqual(
                            hasOrganizationParameterAccess
                        )
                        expect(BUParameterAccess()).toEqual(
                            hasBUParameterAccess
                        )
                        expect(userAccess()).toEqual(hasUserAccess)
                    }
                )
            })
        })
        describe('with bu selected', () => {
            const profileWithBuSelected = { ...profile, ...withBUSelected }
            describe('without access', () => {
                const matrixProfile = [
                    [withoutRight, false, false],
                    [withSuperAdminProfile, true, true],
                    [withAdminProfile, true, true],
                    [withBUAdminProfile, true, true],
                    [withFleetProfile, false, false],
                    [withSupervisionProfile, false, false]
                ]
                test.each(matrixProfile)(
                    'with role: %p',
                    (right, hasBuListAccess, hasOrderBillAccess) => {
                        $store.getters = {
                            ...profileWithBuSelected,
                            ...right,
                            ...withoutAccess
                        }
                        expect(BUParameterAccess()).toEqual(hasBuListAccess)
                        expect(userAccess()).toEqual(hasOrderBillAccess)
                    }
                )
            })
        })

        describe('with hasFilled3OnboardingState falsy', () => {
            const profileWithBuSelected = { ...profile, ...withBUSelected }
            describe('without access', () => {
                const matrixProfile = [
                    [withoutRight, false, false],
                    [withSuperAdminProfile, true, true],
                    [withAdminProfile, true, false],
                    [withBUAdminProfile, true, false],
                    [withFleetProfile, false, false],
                    [withSupervisionProfile, false, false]
                ]
                test.each(matrixProfile)(
                    'with role: %p',
                    (right, hasBuListAccess, hasOrderBillAccess) => {
                        $store.getters = {
                            ...profileWithBuSelected,
                            ...right,
                            ...withoutAccess,
                            'businessunit/hasFilled3OnboardingState': false
                        }
                        expect(BUParameterAccess()).toEqual(hasBuListAccess)
                        expect(userAccess()).toEqual(hasOrderBillAccess)
                    }
                )
            })
        })
    })
})
