
import { PropType } from 'vue'
import { Tab } from '~/interfaces/tab'

export default {
    name: 'AppTabs',
    props: {
        tabs: {
            type: Array as PropType<Tab[]>,
            required: true,
            validator(val: Tab) {
                return val.id !== null && val.text !== null
            }
        },
        indicators: {
            type: Array as PropType<string[]>,
            default: () => []
        },
        colorIndicator: {
            type: String,
            default: ''
        },
        selectedTab: {
            type: String as PropType<string | null>,
            default() {
                return null
            }
        },
        tabBackgroundColor: {
            type: String,
            default: 'bg-gray-50'
        }
    }
}
