
import Vue from 'vue'
import { DateTime } from 'luxon'
import { mapActions, mapState } from 'pinia'
import useMobileFilterStore from '~/store/mobileFilter'
import IconArrowDown from '~/components/UI/icons/dropdown/IconArrowDown.vue'
import IconDate from '~/components/UI/icons/dropdown/IconDate.vue'
import IconCheck from '~/components/UI/icons/dropdown/IconCheck.vue'
import { Filters } from '~/interfaces/filters'
import ChargemapDatepicker from '~/components/UI/CMUI/ChargemapDatepicker.vue'

export default Vue.extend({
    name: 'AppFilterDate',
    components: {
        IconArrowDown,
        IconDate,
        IconCheck,
        ChargemapDatepicker
    },
    props: {
        value: {
            type: Array,
            required: true
        },
        low: {
            type: Number,
            default: 7
        },
        middle: {
            type: Number,
            default: 30
        },
        high: {
            type: Number,
            default: 90
        },
        placeholder: {
            type: String,
            default(this: any) {
                return this.$t('sessions.date')
            }
        }
    },
    data() {
        return {
            DateTime
        }
    },
    computed: {
        ...mapState(useMobileFilterStore, {
            filters: 'getFilters',
            showBottomSheet: 'getShowBottomSheet'
        }),
        currentLocale(this: any) {
            return this.$i18n.locale
        },
        BSfilter(this: any) {
            return {
                id: this._uid,
                name: this.placeholder,
                value: null,
                options: [
                    {
                        id: 1,
                        name: null,
                        value: null
                    },
                    {
                        id: 2,
                        name: `${this.low} ${this.$t(
                            'time.last.other'
                        )} ${this.$t('time.day.other')}`,
                        value: this.low
                    },
                    {
                        id: 3,
                        name: `${this.middle} ${this.$t(
                            'time.last.other'
                        )} ${this.$t('time.day.other')}`,
                        value: this.middle
                    },
                    {
                        id: 4,
                        name: `${this.high} ${this.$t(
                            'time.last.other'
                        )} ${this.$t('time.day.other')}`,
                        value: this.high
                    }
                ]
            } as Filters
        },
        diffDaysToday(this: any) {
            return this.getDiffDays(this.value)
        },
        label(this: any): string {
            if (this.value.length >= 1) {
                if (this.value[1]) {
                    const locale = this.$i18n.locale.toString()
                    const dateFrom = DateTime.fromISO(this.value[0])
                        .setLocale(locale)
                        .toLocaleString(DateTime.DATE_FULL)
                    const dateTo = DateTime.fromISO(this.value[1])
                        .setLocale(locale)
                        .toLocaleString(DateTime.DATE_FULL)
                    return `${this.$t('time.from')} ${dateFrom} ${this.$t(
                        'time.to'
                    )} ${dateTo}`
                }
                if (this.diffDaysToday === 0) {
                    return this.$t('time.today')
                }
                if (this.diffDaysToday === 1) {
                    return this.$t('time.yesterday')
                }
                if (this.diffDaysToday <= 30) {
                    return `${this.diffDaysToday} ${this.$t(
                        'time.last.other'
                    )} ${this.$t('time.day.other')}`
                }
                return `${Math.round(this.diffDaysToday / 30)} ${this.$tc(
                    'time.last.other'
                )} ${this.$tc('time.month.other')}`
            }
            return this.placeholder
        }
    },
    watch: {
        filters: {
            handler(this: any, val) {
                const filter = val.find((f: any) => f.id === this._uid)
                if (this.showBottomSheet) {
                    this.updateLocalFilter(filter)
                }
            },
            deep: true
        }
    },
    mounted(this: any) {
        this.addFilter(this.BSfilter)
    },

    beforeDestroy(this: any) {
        this.removeFilter(this._uid)
    },
    methods: {
        ...mapActions(useMobileFilterStore, [
            'addFilter',
            'removeFilter',
            'updateFilter'
        ]),
        toggleDropdown(slotProps: any, shouldUpdateRef: boolean = false) {
            if (slotProps) {
                slotProps.toggleShowList()
                if (shouldUpdateRef) {
                    slotProps.setButtonRef(this.$refs.buttonRef)
                }
            }
        },
        getDiffDays(values: string[]) {
            if (values.length !== 1) {
                return null
            }
            const pastDate = DateTime.fromISO(values[0])
            const nowDate = DateTime.now().startOf('day')
            return nowDate.diff(pastDate, 'days').toObject().days
        },
        updateDate(this: any, values: string[]) {
            // Datepicker return null if we clear the input
            if (!values[0] || !values[1]) values = []

            this.$emit('input', values)
            this.updateFilter({
                id: this._uid,
                value: this.getDiffDays(values)
            })
        },
        updateDays(this: any, days: number | null, slotProps: any) {
            let dates = [] as (string | null)[]
            if (days) {
                const dateTarget: DateTime = DateTime.now().minus({ days })
                dates = [dateTarget.toISODate()]

                if (this.value[0] !== dates[0] || !!this.value[1]) {
                    this.$emit('input', dates)
                    this.updateFilter({
                        id: this._uid,
                        value: days
                    })
                }
            } else if (days === 0) {
                this.$emit('input', [DateTime.now().toISODate()])
            } else {
                this.$emit('input', [])
                this.updateFilter({
                    id: this._uid,
                    value: null
                })
            }
            this.toggleDropdown(slotProps)
        },
        updateLocalFilter(this: any, filter: any) {
            if (filter.id === this._uid) {
                this.updateDays(filter.value)
            }
        }
    }
})
