
export default {
    name: 'Logo',
    props: {
        link: {
            type: String,
            default: '/'
        }
    },
    methods: {
        emitToggleMenu() {
            this.$emit('toggleMenu')
        }
    }
}
