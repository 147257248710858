
import Vue from 'vue'
import License from '@/components/UI/images/License.vue'

export default Vue.extend({
    components: {
        License
    },
    computed: {
        isLicence() {
            const { idOrga } = this.$route.params
            return this.$route.path === `/organizations/${idOrga}/licenses`
        },
        link() {
            const { idOrga } = this.$route.params
            return `/organizations/${idOrga}/business-units`
        }
    }
})
