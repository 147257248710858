import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'

// @ts-ignore
import AppAccordion from '@/components/UI/AppAccordion.vue'

// Mocks
import basicMountWrapper from '~/utilitary/mountWrapper'

const props = {
    title: 'title'
}

const localVue = createLocalVue()
localVue.use(Vuex)

describe('AppAccordion', () => {
    let wrapper: any
    beforeEach(() => {
        wrapper = shallowMount(AppAccordion, {
            ...basicMountWrapper,
            propsData: props,
            localVue
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="app-accordion"]')

        expect(component.exists()).toBeTruthy()
    })

    describe('watchers', () => {
        describe('triggerHeightCalculation prop', () => {
            it('should call heightCalculation if triggerHeightCalculation & open are true', () => {
                const spy = jest.spyOn(wrapper.vm, 'heightCalculation')
                wrapper.vm.open = true
                wrapper.vm.$options.watch.triggerHeightCalculation.call(
                    wrapper.vm,
                    true
                )

                expect(spy).toHaveBeenCalled()
            })
            it('should not call heightCalculation if triggerHeightCalculation or open are false', () => {
                const spy = jest.spyOn(wrapper.vm, 'heightCalculation')
                wrapper.vm.open = false
                wrapper.vm.$options.watch.triggerHeightCalculation.call(
                    wrapper.vm,
                    true
                )

                expect(spy).not.toHaveBeenCalled()

                wrapper.vm.open = true
                wrapper.vm.$options.watch.triggerHeightCalculation.call(
                    wrapper.vm,
                    false
                )

                expect(spy).not.toHaveBeenCalled()
            })
        })
    })

    describe('methods', () => {
        describe('heightCalculation', () => {
            it('should return without doing anything if header is not defined', () => {
                wrapper.vm.header = undefined
                wrapper.vm.toggleAccordion()
                expect(wrapper.vm.childHeight).toBe(0)
            })
            it('should update ChildHeight with he nextElementSibling height', () => {
                expect(wrapper.vm.childHeight).toBe(0)
                wrapper.vm.header = {
                    nextElementSibling: {
                        scrollHeight: 100
                    }
                }
                wrapper.vm.toggleAccordion()
                expect(wrapper.vm.childHeight).toBe(100)
            })
        })

        describe('toggleAccordion', () => {
            it('should toggle open', () => {
                wrapper.vm.toggleAccordion()
                expect(wrapper.vm.open).toBeTruthy()
            })
        })
    })
})
