import { defineStore } from 'pinia'
import { DeliveryPoint } from '~/interfaces/deliveryPoint'
import { FilterList } from '~/interfaces/filters'

const useDeliveryPointsStore = defineStore('deliveryPoints', () => {
    const convertRate = (deliveryPoint: DeliveryPoint): DeliveryPoint => {
        const TO_EUROS = 100_000
        const TO_KW = 1_000
        const {
            reducedPeriod: reducedPeriodRaw,
            price,
            billEnergy,
            billedEnergyMargin
        } = deliveryPoint.tariff
        let reducedPeriod = null
        if (reducedPeriodRaw) {
            reducedPeriod = {
                ...reducedPeriodRaw,
                price: reducedPeriodRaw.price / TO_EUROS
            }
        }
        return {
            ...deliveryPoint,
            tariff: {
                reducedPeriod,
                price: price / TO_EUROS,
                billEnergy,
                billedEnergyMargin
            },
            maxPower: deliveryPoint.maxPower
                ? deliveryPoint.maxPower / TO_KW
                : null
        }
    }

    const fetchDeliveryPoints = (
        context: any,
        payload: {
            idBU: number
            hasZones: boolean | null
            hasOwner: boolean | null
            filters: FilterList
            archive: boolean | null
        }
    ): Promise<{ count: number; deliveryPoints: DeliveryPoint[] }> => {
        const { idBU, hasZones, hasOwner, filters, archive } = payload
        const offset =
            (filters.pages.index - 1) * filters.pages.perPage || (0 as number)
        const limit = filters.pages.perPage || (20 as number)
        const keyword = filters.searchField || (null as null | string)
        const params = {
            has_zones: hasZones,
            has_owner: hasOwner,
            offset,
            limit,
            keyword
        }
        const url = `/supervisionapi/business-units/${idBU}/delivery-points${
            archive ? '' : '?deleted=false'
        }`
        return context.$axios
            .get(url, { params })
            .then((response: any) => {
                const resDTO = response.data.items
                const count = parseInt(response.headers['x-total-count'], 10)
                const deliveryPoints = context.$toCamel(
                    resDTO
                ) as DeliveryPoint[]

                return {
                    count,
                    deliveryPoints: deliveryPoints.map((deliveryPoint) =>
                        convertRate(deliveryPoint)
                    )
                }
            })
            .catch((e: any) => {
                throw e
            })
    }

    const fetchDeliveryPoint = (
        context: any,
        payload: {
            idBU: number
            idDeliveryPoint: number
        }
    ) => {
        const { idBU, idDeliveryPoint } = payload
        const url = `/supervisionapi/business-units/${idBU}/delivery-points/${idDeliveryPoint}`
        return context.$axios
            .$get(url)
            .then((res: any) => {
                const deliveryPoint = context.$toCamel(res)
                return convertRate(deliveryPoint)
            })
            .catch((e: any) => {
                throw e
            })
    }

    const saveDeliveryPoint = (
        context: any,
        payload: { deliveryPoint: DeliveryPoint; idBU: number }
    ) => {
        const { deliveryPoint, idBU } = payload
        let url = `/supervisionapi/business-units/${idBU}/delivery-points`
        let method = '$post'
        if (deliveryPoint.id) {
            url += `/${deliveryPoint.id}`
            method = '$put'
        }
        const body = {
            ...deliveryPoint,
            id: undefined
        }

        return context.$axios[method](url, body)
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    }

    const disableDeliveryPoint = (
        context: any,
        payload: { idDeliveryPoint: number; idBU: number }
    ) => {
        const { idDeliveryPoint, idBU } = payload
        const url = `/supervisionapi/business-units/${idBU}/delivery-points/${idDeliveryPoint}`
        return context.$axios
            .$delete(url)
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    }

    return {
        fetchDeliveryPoints,
        fetchDeliveryPoint,
        saveDeliveryPoint,
        disableDeliveryPoint
    }
})

type DeliveryPointStore = Omit<
    ReturnType<typeof useDeliveryPointsStore>,
    keyof ReturnType<typeof defineStore>
>

export default useDeliveryPointsStore

export type { DeliveryPointStore }
