
import Vue from 'vue'
import { onClickOutside } from '@vueuse/core'

export default Vue.extend({
    name: 'TimePicker',
    props: {
        hour: {
            type: String,
            required: true
        },
        minute: {
            type: String,
            required: true
        },
        step: {
            type: Number,
            default: 5
        },
        hasError: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentHour: '00',
            currentMinute: '00',
            editHour: false,
            editMinute: false
        }
    },
    computed: {
        listHours(): Array<string> {
            const hours = []
            for (let i = 0; i < 24; i += 1) {
                hours.push(`${(i < 10 ? '0' : '') + i}`)
            }
            return hours
        },
        listMinutes(): Array<string> {
            const minutes = []
            for (let i = 0; i < 60; i += this.step) {
                minutes.push(`${(i < 10 ? '0' : '') + i}`)
            }
            return minutes
        }
    },
    watch: {
        editHour(val: boolean) {
            if (val) this.editMinute = false
        },
        editMinute(val: boolean) {
            if (val) this.editHour = false
        },
        hour(val) {
            this.currentHour = val
        },
        minute(val) {
            this.currentMinute = val
        }
    },
    mounted() {
        this.currentHour = this.hour
        this.currentMinute = this.minute
        onClickOutside(this.$refs.timePickerRef as any, () => this.closeEdit())
    },
    methods: {
        closeEdit() {
            this.editHour = false
            this.editMinute = false
        },
        setHour(hour: string) {
            this.currentHour = hour
            this.editHour = false
            this.$emit('update', `${hour}:${this.minute}`)
        },
        setMinute(minute: string) {
            this.currentMinute = minute
            this.editMinute = false
            this.$emit('update', `${this.hour}:${minute}`)
        }
    }
})
