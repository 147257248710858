import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'

// @ts-ignore
import AppMenu from '@/components/UI/AppMenu.vue'
// Mocks
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()
localVue.use(Vuex)

const user = {
    id: 45,
    firstName: 'Thomas',
    lastName: 'Gauthier',
    fullName: 'Thomas Gauthier',
    email: 'thomas@gauthier.fr',
    activeOrganization: {
        id: 2,
        name: 'Tsup',
        role: 'MEMBER',
        businessUnits: [] as any,
        access: ['SUPERVISION', 'FLEET', 'OPERATOR']
    },
    activeBusinessUnit: {
        id: 4,
        name: 'Business Unit Supervision',
        roles: ['SUPERVISOR']
    },
    rights: {
        organizations: [
            {
                id: 1,
                name: 'Orga',
                role: 'ADMIN',
                businessUnits: [
                    {
                        id: 1,
                        name: 'Business Unit Test',
                        roles: ['SUPERVISOR']
                    },
                    {
                        id: 3,
                        name: 'Business Unit Roxxor',
                        roles: ['FLEET_MANAGER']
                    }
                ],
                access: ['SUPERVISION', 'FLEET', 'OPERATOR']
            },
            {
                id: 2,
                name: 'Tsup',
                role: 'MEMBER',
                businessUnits: [
                    {
                        id: 2,
                        name: 'Business Unit Fleet',
                        roles: ['FLEET_MANAGER']
                    },
                    {
                        id: 4,
                        name: 'Business Unit Supervision',
                        roles: ['SUPERVISOR']
                    }
                ],
                access: ['SUPERVISION', 'FLEET', 'OPERATOR']
            } as {}
        ]
    }
}

const $auth = {
    $storage: {
        getCookie: jest.fn(() => user)
    },
    user
}
const $store = {
    dispatch: jest.fn(() => Promise.resolve(12)),
    getters: {
        'session/getHomepage': '/admin/organizations',
        'organization/getOrganization': user.activeOrganization,
        'businessunit/getBusinessUnit': null as null | {
            id: number
            name: string
            roles: string[]
        },
        'session/countOrganizations': 2,
        'session/isSuperAdmin': false,
        'session/isAdmin': true,
        'session/getUser': user
    }
}

const $menu = {
    $on: jest.fn((param, func) => {
        func(false)
        return param
    })
}

describe('components/UI/AppMenu.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppMenu, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks,
                $auth,
                $store,
                $device: {
                    isIos: jest.fn().mockReturnValue(false)
                },
                $menu
            }
        })
    })

    afterEach(() => {
        jest.clearAllMocks()
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        expect(wrapper.vm.openMenu).toBe('administration')
        expect(wrapper.vm.user).toEqual(user)
        expect(wrapper.vm.selectedMenu).toBe('administration')
        expect(wrapper.vm.displayAdministration).toBeFalsy()
        expect(wrapper.vm.canSwitchBU).toBeTruthy()
        expect($menu.$on).toHaveBeenCalledWith(
            'toggleAdministration',
            expect.any(Function)
        )
        const component = wrapper.find('[data-testid="app-menu"]')
        expect(component.exists()).toBeTruthy()
    })

    it('initialize selectedMenu', async () => {
        wrapper = shallowMount(AppMenu, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks,
                $auth,
                $store: {
                    dispatch: jest.fn(() => Promise.resolve('test')),
                    getters: {
                        'session/getHomepage': '/admin/organizations',
                        'organization/getOrganization': user.activeOrganization,
                        'businessunit/getBusinessUnit': null,
                        'session/countOrganizations': 2,
                        'session/isSuperAdmin': true,
                        'session/isAdmin': false,
                        'session/getUser': user
                    }
                },
                $device: {
                    isIos: jest.fn().mockReturnValue(false)
                },
                $menu: {
                    $on: jest.fn((param, func) => {
                        func(true)
                        return param
                    })
                }
            },
            propsData: {
                openMenu: 'supervision'
            }
        })
        await localVue.nextTick()
        expect(wrapper.vm).toBeTruthy()
        expect(wrapper.vm.openMenu).toBe('supervision')
        expect(wrapper.vm.user).toEqual(user)
        expect(wrapper.vm.selectedMenu).toBe('administration')
        expect(wrapper.vm.displayAdministration).toBeTruthy()
    })

    describe('canSwitchBU', () => {
        beforeEach(() => {
            $store.getters = {
                'session/getHomepage': '/admin/organizations',
                'organization/getOrganization': user.activeOrganization,
                'businessunit/getBusinessUnit': user.activeBusinessUnit,
                'session/countOrganizations': 1,
                'session/isSuperAdmin': false,
                'session/isAdmin': false,
                'session/getUser': user
            }
        })

        it('true if multiple organizations', () => {
            $store.getters['session/countOrganizations'] = 2
            expect(wrapper.vm.canSwitchBU).toBeTruthy()
        })

        it('true if multiple BU', () => {
            user.activeOrganization.businessUnits = ['test1', 'test2']
            expect(wrapper.vm.canSwitchBU).toBeTruthy()
            user.activeOrganization.businessUnits = []
        })

        it('true if admin organizations', () => {
            $store.getters['session/isAdmin'] = true
            expect(wrapper.vm.canSwitchBU).toBeTruthy()
        })

        it('true if superadmin', () => {
            $store.getters['session/isSuperAdmin'] = true
            expect(wrapper.vm.canSwitchBU).toBeTruthy()
        })

        it('false in other cases', () => {
            expect(wrapper.vm.canSwitchBU).toBeFalsy()
        })
    })

    describe('businessUnitsAvailable returns', () => {
        it('all business units from all organizations', () => {
            const listBU = wrapper.vm.businessUnitsAvailable
            expect(listBU).toEqual([
                {
                    id: 1,
                    name: 'Orga',
                    type: 1
                },
                {
                    id: 1,
                    idOrga: 1,
                    name: 'Business Unit Test',
                    type: 2
                },
                {
                    id: 3,
                    idOrga: 1,
                    name: 'Business Unit Roxxor',
                    type: 2
                },
                {
                    id: 2,
                    name: 'Tsup',
                    type: 1
                },
                {
                    id: 2,
                    idOrga: 2,
                    name: 'Business Unit Fleet',
                    type: 2
                },
                {
                    id: 4,
                    idOrga: 2,
                    name: 'Business Unit Supervision',
                    type: 2
                }
            ])
        })

        it('only organizations and filter BUs with search', () => {
            wrapper.vm.searchBU = 'Flee'
            const listBU = wrapper.vm.businessUnitsAvailable
            expect(listBU).toEqual([
                {
                    id: 1,
                    name: 'Orga',
                    type: 1
                },
                {
                    id: 2,
                    name: 'Tsup',
                    type: 1
                },
                {
                    id: 2,
                    idOrga: 2,
                    name: 'Business Unit Fleet',
                    type: 2
                }
            ])
        })

        it('only organizations without business units in it', () => {
            const copyUser = user
            copyUser.rights.organizations = [
                {
                    id: 1,
                    name: 'Orga',
                    role: 'ADMIN'
                },
                {
                    id: 2,
                    name: 'Tsup',
                    role: 'MEMBER'
                }
            ]
            $auth.user = copyUser
            const listBU = wrapper.vm.businessUnitsAvailable
            expect(listBU).toEqual([
                {
                    id: 1,
                    name: 'Orga',
                    type: 1
                },
                {
                    id: 2,
                    name: 'Tsup',
                    type: 1
                }
            ])
        })

        it('empty array, no organizations', () => {
            const copyUser = user
            copyUser.rights.organizations = []
            $auth.user = copyUser
            const listBU = wrapper.vm.businessUnitsAvailable
            expect(listBU).toEqual([])
        })
    })

    it('goToAdmin calls toggle and redirect', () => {
        const toggleSpy = jest.spyOn(wrapper.vm, 'toggleAdministration')
        expect(toggleSpy).not.toHaveBeenCalled()
        wrapper.vm.goToAdmin()
        expect(toggleSpy).toHaveBeenCalled()
        expect(wrapper.vm.$router.push).toHaveBeenCalledWith(
            '/admin/organizations'
        )
    })

    it('toggle administration menu', () => {
        expect(wrapper.vm.displayAdministration).toBeFalsy()
        wrapper.vm.toggleAdministration(true)
        expect(wrapper.vm.displayAdministration).toBeTruthy()
        wrapper.vm.toggleAdministration()
        expect(wrapper.vm.displayAdministration).toBeFalsy()
        wrapper.vm.toggleAdministration(false)
        expect(wrapper.vm.displayAdministration).toBeFalsy()
    })

    it('switch menu emits change', () => {
        expect(wrapper.emitted().change).toBeFalsy()
        const id = 'administration'
        wrapper.vm.switchMenu(id)
        expect(wrapper.vm.selectedMenu).toEqual(id)
        expect(wrapper.emitted().change[0]).toEqual([id])
    })

    it('close mobile menu to emit close', () => {
        expect(wrapper.emitted().close).toBeFalsy()
        wrapper.vm.closeMobileMenu()
        expect(wrapper.emitted().close).toBeTruthy()
    })

    describe('changeBusinessUnit', () => {
        it('change business unit to dispatch store', async () => {
            await wrapper.vm.changeBusinessUnit(2, 4, 2)
            expect(wrapper.vm.$store.dispatch).toHaveBeenCalledWith(
                'businessunit/switchBusinessUnit',
                { idBU: 4, idOrga: 2 }
            )
            expect(wrapper.vm.$router.push).toHaveBeenCalledWith(
                '/organizations/2/business-units/4/dashboard'
            )
        })
        it('doesnt change business unit when error occured', async () => {
            $store.dispatch = jest.fn(() => Promise.reject(new Error('error')))
            await wrapper.vm.changeBusinessUnit(2, 4, 2)
            expect(wrapper.vm.$router.push).toHaveBeenCalledWith(
                '/organizations/2/business-units/4/dashboard'
            )
            expect(wrapper.vm.$store.dispatch).toHaveBeenCalledWith(
                'businessunit/switchBusinessUnit',
                { idBU: 4, idOrga: 2 }
            )
            $store.dispatch = jest.fn(() => Promise.resolve(12))
        })

        describe('change organization to dispatch store', () => {
            beforeEach(() => {
                $store.getters['session/isAdmin'] = false
                $store.getters['session/isSuperAdmin'] = false
            })

            it('should switch organization', async () => {
                await wrapper.vm.switchOrganization(3)
                expect(wrapper.vm.$store.dispatch).toHaveBeenCalledWith(
                    'organization/switchOrganization',
                    {
                        organizationId: 3
                    }
                )
                expect(wrapper.vm.$store.dispatch).toHaveBeenCalledWith(
                    'businessunit/setBusinessUnit',
                    null
                )
                await localVue.nextTick()
                expect(wrapper.vm.$router.push).toHaveBeenCalledWith(
                    '/organizations/3/business-units'
                )
                $store.getters['session/isAdmin'] = true
                await wrapper.vm.switchOrganization(3)
                await localVue.nextTick()
                expect(wrapper.vm.$router.push).toHaveBeenCalledWith(
                    '/organizations/3/dashboard'
                )
            })

            it('as Admin', async () => {
                $store.getters['session/isAdmin'] = true
                wrapper.vm.switchOrganization = jest.fn()
                await wrapper.vm.changeBusinessUnit(1, 3, 2)
                expect(wrapper.vm.switchOrganization).toHaveBeenCalledWith(3)
            })

            it('as SuperAdmin', async () => {
                $store.getters['session/isSuperAdmin'] = true
                wrapper.vm.switchOrganization = jest.fn()
                await wrapper.vm.changeBusinessUnit(1, 3, 2)
                expect(wrapper.vm.switchOrganization).toHaveBeenCalledWith(3)
            })
            it('when an error occured', async () => {
                $store.getters['session/isSuperAdmin'] = true
                $store.dispatch = jest.fn(() =>
                    Promise.reject(new Error('error'))
                )
                await wrapper.vm.changeBusinessUnit(1, 3, 2)
                expect(wrapper.vm.$store.dispatch).toHaveBeenCalledWith(
                    'organization/switchOrganization',
                    {
                        organizationId: 3
                    }
                )
                await localVue.nextTick()
                expect(wrapper.vm.$router.push).not.toHaveBeenCalled()
                $store.dispatch = jest.fn(() => Promise.resolve(12))
            })
            it('when an error occured with bu call', async () => {
                $store.getters['session/isSuperAdmin'] = true
                $store.dispatch = jest
                    .fn()
                    .mockResolvedValueOnce(true)
                    .mockRejectedValue(new Error('test'))
                await wrapper.vm.changeBusinessUnit(1, 3, 2)
                expect(wrapper.vm.$store.dispatch).toHaveBeenCalledWith(
                    'organization/switchOrganization',
                    {
                        organizationId: 3
                    }
                )
                await localVue.nextTick()
                expect(wrapper.vm.$router.push).not.toHaveBeenCalled()
                $store.dispatch = jest.fn(() => Promise.resolve(12))
            })
        })

        it('dont dispatch', async () => {
            $store.getters['session/isSuperAdmin'] = true
            await wrapper.vm.changeBusinessUnit(3, 3, 2)
            expect(wrapper.vm.$store.dispatch).not.toHaveBeenCalled()
        })
    })

    it('can display admin section', () => {
        let component = wrapper.find('[data-testid="app-menu-admin-section"]')
        expect(component.exists()).toBeFalsy()

        wrapper = shallowMount(AppMenu, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks,
                $auth,
                $store: {
                    dispatch: jest.fn(() => Promise.resolve('test')),
                    getters: {
                        'session/getHomepage': '/admin/organizations',
                        'organization/getOrganization': null,
                        'businessunit/getBusinessUnit': null,
                        'session/countOrganizations': 2,
                        'session/isSuperAdmin': true,
                        'session/isAdmin': false,
                        'session/getUser': user
                    }
                },
                $device: {
                    isIos: jest.fn().mockReturnValue(false)
                },
                $menu: {
                    $on: jest.fn((param, func) => {
                        func(true)
                        return param
                    })
                }
            },
            propsData: {
                openMenu: 'supervision'
            }
        })

        component = wrapper.find('[data-testid="app-menu-admin-section"]')
        expect(component.exists()).toBeTruthy()
    })

    it('can display organisation menu', () => {
        let component = wrapper.find('[data-testid="app-menu-organisation"]')
        expect(component.exists()).toBeTruthy()

        wrapper = shallowMount(AppMenu, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks,
                $auth,
                $store: {
                    dispatch: jest.fn(() => Promise.resolve('test')),
                    getters: {
                        'session/getHomepage': '/admin/organizations',
                        'organization/getOrganization': null,
                        'businessunit/getBusinessUnit': null,
                        'session/countOrganizations': 2,
                        'session/isSuperAdmin': true,
                        'session/isAdmin': false,
                        'session/getUser': user
                    }
                },
                $device: {
                    isIos: jest.fn().mockReturnValue(false)
                },
                $menu: {
                    $on: jest.fn((param, func) => {
                        func(true)
                        return param
                    })
                }
            },
            propsData: {
                openMenu: 'supervision'
            }
        })

        component = wrapper.find('[data-testid="app-menu-organisation"]')
        expect(component.exists()).toBeFalsy()
    })

    it('can display super admin button', () => {
        let component = wrapper.find(
            '[data-testid="app-menu-super-admin-button"]'
        )
        expect(component.exists()).toBeTruthy()

        wrapper = shallowMount(AppMenu, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks,
                $auth,
                $store: {
                    dispatch: jest.fn(() => Promise.resolve('test')),
                    getters: {
                        'session/getHomepage': '/admin/organizations',
                        'organization/getOrganization': user.activeOrganization,
                        'businessunit/getBusinessUnit': null,
                        'session/countOrganizations': 2,
                        'session/isSuperAdmin': false,
                        'session/isAdmin': false,
                        'session/getUser': user
                    }
                },
                $device: {
                    isIos: jest.fn().mockReturnValue(false)
                },
                $menu: {
                    $on: jest.fn((param, func) => {
                        func(true)
                        return param
                    })
                }
            },
            propsData: {
                openMenu: 'supervision'
            }
        })

        component = wrapper.find('[data-testid="app-menu-super-admin-button"]')
        expect(component.exists()).toBeFalsy()
    })

    it('can display select BU', async () => {
        let component = wrapper.find('[data-testid="app-menu-BU"]')
        expect(component.exists()).toBeFalsy()

        wrapper = shallowMount(AppMenu, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks,
                $auth,
                $store: {
                    dispatch: jest.fn(() => Promise.resolve('test')),
                    getters: {
                        'session/getHomepage': '/admin/organizations',
                        'organization/getOrganization': user.activeOrganization,
                        'businessunit/getBusinessUnit': null,
                        'session/countOrganizations': 2,
                        'session/isSuperAdmin': false,
                        'session/isAdmin': false,
                        'session/getUser': user
                    }
                },
                $device: {
                    isIos: jest.fn().mockReturnValue(false)
                },
                $menu: {
                    $on: jest.fn((param, func) => {
                        func(true)
                        return param
                    })
                }
            },
            propsData: {
                openMenu: 'supervision'
            }
        })

        await wrapper.setData({ displaySelectBU: true })

        component = wrapper.find('[data-testid="app-menu-BU"]')
        expect(component.exists()).toBeTruthy()
    })

    it('can return isProductionEnvironment', () => {
        expect(wrapper.vm.isProductionEnvironment).toBeFalsy()

        wrapper = shallowMount(AppMenu, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks,
                $auth,
                $store,
                $device: {
                    isIos: jest.fn().mockReturnValue(false)
                },
                $menu,
                $config: {
                    ...basicMountWrapper.mocks.$config,
                    NUXT_ENV: 'production'
                }
            }
        })
        expect(wrapper.vm.isProductionEnvironment).toBeTruthy()
    })

    it('currentLocale', () => {
        expect(wrapper.vm.currentLocale).toBe('en')

        wrapper.vm.$i18n.locale = 'fr'

        expect(wrapper.vm.currentLocale).toBe('fr')
    })
})
