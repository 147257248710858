import { RouterLinkStub, createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'

// @ts-ignore
import MenuLink from '@/components/UI/menu/MenuLink.vue'

// Mocks
import { i18n } from '~/test/mocks/i18n.mock'

const localVue = createLocalVue()
localVue.use(Vuex)

const $emit = jest.fn()

const propsData = {
    link: {
        id: 'menu-vehicles',
        title: 'menu.fleet.vehicles',
        icon: 'IconVehicle',
        url: `/vehicles`
    },
    isSubmenu: false
}

describe('components/UI/menu/MenuLink.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(MenuLink, {
            localVue,
            mocks: {
                $t: i18n.$t,
                $tc: i18n.$tc,
                $emit
            },
            propsData,
            stubs: {
                NuxtLink: RouterLinkStub
            }
        })
    })

    afterEach(() => {
        jest.clearAllMocks()
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
    })

    it('closeMobileMenu', () => {
        expect($emit).not.toHaveBeenCalled()
        wrapper.vm.closeMobileMenu()
        expect($emit).toHaveBeenCalledWith('closeMobileMenu')
    })
})
