
import Vue from 'vue'

export default Vue.extend({
    name: 'AppAccordion',
    props: {
        open: {
            type: Boolean,
            default: false
        },
        scrollIntoView: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            childHeight: 0,
            header: this.$refs.accordionHeader as HTMLElement | null
        }
    },
    watch: {
        open(val) {
            if (val && this.scrollIntoView) {
                setTimeout(() => {
                    this.header?.nextElementSibling?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }, 300)
            }
        }
    },
    mounted() {
        this.header = this.$refs.accordionHeader as HTMLElement

        window.addEventListener('resize', this.heightCalculation)

        this.$nextTick(() => {
            this.heightCalculation()
        })
    },
    methods: {
        heightCalculation(this: any) {
            if (!this.header) {
                return
            }
            const content: HTMLElement = this.header
                .nextElementSibling as HTMLElement

            this.childHeight = content.scrollHeight
        },
        toggleAccordion(this: any) {
            if (this.isDisabled) return
            this.heightCalculation()
            this.$emit('updateOpen', !this.open)
        }
    }
})
