
import Vue from 'vue'
import IconTrash from '~/components/UI/icons/IconTrash.vue'
import IconUpload from '~/components/UI/icons/IconUpload.vue'

export default Vue.extend({
    components: { IconUpload, IconTrash },
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            filename: ''
        }
    },
    computed: {
        label(): string {
            return this.filename ? this.filename : this.$tc('selectCSV')
        }
    },
    methods: {
        onFileChange(this: any, event: any) {
            const fileData = event.target.files[0]
            if (fileData) {
                if (fileData.size > 1000000) {
                    this.$toast?.global.error({
                        title: this.$tc('errors.file_too_big'),
                        message: ''
                    })
                    event.target.value = null
                } else if (fileData.type !== 'text/csv') {
                    this.$toast?.global.error({
                        title: this.$tc('errors.file_must_be_csv'),
                        message: ''
                    })
                    event.target.value = null
                } else {
                    this.filename = fileData.name
                    this.$emit('setAttachment', fileData)
                }
            }
        },
        clearFile() {
            this.filename = ''
            this.$emit('setAttachment', undefined)
        }
    }
})
