
export default {
    name: 'Flyout',
    props: {
        color: {
            type: String,
            default: 'gray',
            validator(value) {
                return [
                    'gray',
                    'red',
                    'yellow',
                    'orange',
                    'green',
                    'blue',
                    'indigo',
                    'purple',
                    'pink',
                    'chargemap'
                ].includes(value)
            }
        }
    }
}
