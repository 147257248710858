
import Vue from 'vue'

export default Vue.extend({
    props: {
        title: {
            type: String,
            default: ''
        },
        showBackButton: {
            type: Boolean,
            default: false
        },
        picture: {
            type: String,
            default: 'wall-box'
        }
    }
})
