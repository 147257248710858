import { render, staticRenderFns } from "./SMALL_PADDLE_INDUCTIVE.vue?vue&type=template&id=6749ce75"
import script from "./SMALL_PADDLE_INDUCTIVE.vue?vue&type=script&lang=ts"
export * from "./SMALL_PADDLE_INDUCTIVE.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports