import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'
// @ts-ignore
import AppDetailSection from '@/components/UI/detailSection/AppDetailSection.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()
localVue.use(Vuex)

const props = {
    showList: true,
    showForm: true,
    paddingClass: 'py-4',
    paddingClassList: '',
    isDivided: false
}

const $emit = jest.fn()
describe('components/UI/AppDetailSection.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppDetailSection, {
            ...basicMountWrapper,
            localVue,
            propsData: props,
            mocks: {
                ...basicMountWrapper.mocks,
                $emit
            }
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="app-detail-section"]')
        expect(component.exists()).toBeTruthy()
    })
})
