
import _ from 'lodash'
import Vue from 'vue'

const countries = require('i18n-iso-countries')

export default Vue.extend({
    name: 'AppSelectCountry',
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        listCountries: {} as any
    }),
    computed: {
        optionsCountries() {
            return Object.keys(this.listCountries).map((key: string) => ({
                id: key,
                value: this.listCountries[key]
            }))
        }
    },
    mounted() {
        countries.registerLocale(
            require(`i18n-iso-countries/langs/${this.$i18n.locale}.json`)
        )
        this.listCountries = countries.getNames(this.$i18n.locale)
    },
    methods: {
        fetchCountries(q: string) {
            if (q && q.length > 0) {
                const filterCountries = _.pickBy(
                    this.listCountries,
                    (val: string) => val.toUpperCase().includes(q.toUpperCase())
                )
                return { results: filterCountries }
            }
            return { results: this.listCountries }
        },
        inputVal(val: string) {
            this.$emit('input', val)
        },
        changeVal(val: string) {
            this.$emit('change', val)
        }
    }
})
