
import Vue from 'vue'

export default Vue.extend({
    props: {
        imageName: {
            type: String,
            required: true
        }
    }
})
