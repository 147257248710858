
export default {
    name: 'IconPen',
    props: {
        isSolid: {
            type: Boolean,
            default: false
        }
    }
}
