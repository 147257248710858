import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'
// @ts-ignore
import AppFilterCheckbox from '@/components/UI/bottomSheetFilters/AppFilterCheckbox.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()
localVue.use(Vuex)

const $emit = jest.fn()
describe('components/UI/bottomSheetFilters/AppFilterCheckbox.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppFilterCheckbox, {
            ...basicMountWrapper,
            localVue,
            propsData: {
                filter: {
                    name: 'online',
                    id: 'test-checkbox',
                    value: false
                }
            },
            mocks: {
                ...basicMountWrapper.mocks,
                $emit
            }
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const bsFilter = wrapper.find('.bs-filter')
        expect(bsFilter.exists()).toBeTruthy()
    })

    it('update', () => {
        const evt = {
            target: {
                checked: true
            }
        }
        expect($emit).not.toHaveBeenCalled()
        wrapper.vm.update(evt)
        expect($emit).toHaveBeenCalledWith('update', {
            id: wrapper.vm.filter.id,
            value: evt.target.checked
        })
    })
})
