const middleware = {}

middleware['flaggyConfigured'] = require('../middleware/flaggyConfigured.js')
middleware['flaggyConfigured'] = middleware['flaggyConfigured'].default || middleware['flaggyConfigured']

middleware['gocardless'] = require('../middleware/gocardless.js')
middleware['gocardless'] = middleware['gocardless'].default || middleware['gocardless']

middleware['hasFilled3OnboardingState'] = require('../middleware/hasFilled3OnboardingState.js')
middleware['hasFilled3OnboardingState'] = middleware['hasFilled3OnboardingState'].default || middleware['hasFilled3OnboardingState']

middleware['hasFleetRights'] = require('../middleware/hasFleetRights.js')
middleware['hasFleetRights'] = middleware['hasFleetRights'].default || middleware['hasFleetRights']

middleware['hasOrdersFeature'] = require('../middleware/hasOrdersFeature.js')
middleware['hasOrdersFeature'] = middleware['hasOrdersFeature'].default || middleware['hasOrdersFeature']

middleware['hasSupervisorRights'] = require('../middleware/hasSupervisorRights.js')
middleware['hasSupervisorRights'] = middleware['hasSupervisorRights'].default || middleware['hasSupervisorRights']

middleware['init'] = require('../middleware/init.js')
middleware['init'] = middleware['init'].default || middleware['init']

middleware['isBuAdmin'] = require('../middleware/isBuAdmin.js')
middleware['isBuAdmin'] = middleware['isBuAdmin'].default || middleware['isBuAdmin']

middleware['isOrgaAdmin'] = require('../middleware/isOrgaAdmin.js')
middleware['isOrgaAdmin'] = middleware['isOrgaAdmin'].default || middleware['isOrgaAdmin']

middleware['isSuperAdmin'] = require('../middleware/isSuperAdmin.js')
middleware['isSuperAdmin'] = middleware['isSuperAdmin'].default || middleware['isSuperAdmin']

middleware['navigation'] = require('../middleware/navigation.js')
middleware['navigation'] = middleware['navigation'].default || middleware['navigation']

middleware['onboarding'] = require('../middleware/onboarding.js')
middleware['onboarding'] = middleware['onboarding'].default || middleware['onboarding']

middleware['token'] = require('../middleware/token.js')
middleware['token'] = middleware['token'].default || middleware['token']

export default middleware
