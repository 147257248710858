
import IconLoader from '@/components/UI/icons/IconLoader.vue'

export default {
    name: 'AppButton',
    components: { IconLoader },
    props: {
        component: {
            type: String,
            default: 'button'
        },
        hasPadding: {
            type: Boolean,
            default: true
        },
        btnStyle: {
            type: String,
            default: ''
        },
        btnType: {
            type: String,
            default: 'button'
        },
        btnSize: {
            type: String,
            default: 'base'
        },
        btnAlt: {
            type: String,
            default: 'default'
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    }
}
