import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {"position":"top-right","duration":3000,"theme":"toast-chargemap","fitToScreen":true,"keepOnHover":true,"className":["max-w-sm","w-full","bg-white","shadow-lg","rounded-lg","p-4","text-sm","text-gray-500"]})

const globals = [{"name":"success","message":(payload) => {
      let content =
      '<svg class="w-8 h-8 mr-2 sm:w-6 sm:h-6 sm:mr-3 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>';
      if (!payload.title && !payload.message) {
        content += 'Great! Everything went fine.';
      } else {
        content += '<div>';
        if (payload.title) {
          content += `<p class="text-sm font-medium text-gray-900">${payload.title}</p>`;
        }
        if (payload.message) {
          content += payload.title ? '<p class="mt-1">' : '<p>';
          content += `${payload.message}</p>`;
        }
        content += '</div>';
      }
      return content;
    },"options":{"type":"info"}},{"name":"info","message":(payload) => {
      let content =
      '<svg class="w-8 h-8 mr-2 sm:w-6 sm:h-6 sm:mr-3 text-chargemap-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>';
      if (!payload.title && !payload.message) {
        content += 'Some info should appear here.';
      } else {
        content += '<div>';
        if (payload.title) {
          content += `<p class="text-sm font-medium text-gray-900">${payload.title}</p>`;
        }
        if (payload.message) {
          content += payload.title ? '<p class="mt-1">' : '<p>';
          content += `${payload.message}</p>`;
        }
        content += '</div>';
      }
      return content;
    },"options":{"type":"info"}},{"name":"warn","message":(payload) => {
      let content =
      '<svg class="w-8 h-8 mr-2 sm:w-6 sm:h-6 sm:mr-3 text-yellow-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>';
      if (!payload.title && !payload.message) {
        content += 'Oops... Something Went Wrong.';
      } else {
        content += '<div>';
        if (payload.title) {
          content += `<p class="text-sm font-medium text-gray-900">${payload.title}</p>`;
        }
        if (payload.message) {
          content += payload.title ? '<p class="mt-1">' : '<p>';
          content += `${payload.message}</p>`;
        }
        content += '</div>';
      }
      return content;
    },"options":{"type":"info"}},{"name":"error","message":(payload) => {
      let content =
      '<svg class="w-8 h-8 mr-2 sm:w-6 sm:h-6 sm:mr-3 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg>';
      if (!payload.title && !payload.message) {
        content += 'Oops... Something Went Wrong.';
      } else {
        content += '<div>';
        if (payload.title) {
          content += `<p class="text-sm font-medium text-gray-900">${payload.title}</p>`;
        }
        if (payload.message) {
          content += payload.title ? '<p class="mt-1">' : '<p>';
          content += `${payload.message}</p>`;
        }
        content += '</div>';
      }
      return content;
    },"options":{"type":"error"}}]
if(globals) {
  globals.forEach(global => {
    Vue.toasted.register(global.name, global.message, global.options)
  })
}

export default function (ctx, inject) {
  inject('toast', Vue.toasted)
}
