
export default {
    name: 'IconCharging',
    props: {
        powerActive: {
            type: Boolean,
            default: true
        }
    }
}
