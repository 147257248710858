
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { mapState } from 'pinia'
import MenuSection from '~/components/UI/menu/MenuSection.vue'
import useFlaggyStore from '~/store/flaggy'

export default Vue.extend({
    name: 'MenuUserSection',
    components: {
        MenuSection
    },
    emits: ['selected', 'closeMobileMenu'],
    computed: {
        ...mapGetters({
            organization: 'organization/getOrganization',
            businessUnit: 'businessunit/getBusinessUnit',
            hasFilled3OnboardingState: 'businessunit/hasFilled3OnboardingState',
            isSuperAdmin: 'session/isSuperAdmin',
            isAdmin: 'session/isAdmin',
            isBUAdmin: 'session/isBUAdmin',
            isFleetManager: 'session/isFleetManager',
            isSupervisionManager: 'session/isSupervisionManager'
        }),
        ...mapState(useFlaggyStore, ['hasFeatureActivated']),
        menuInvoicesTitle() {
            return this.hasFeatureActivated('callForFundsTab')
                ? 'menu.invoices_and_call_for_funds'
                : 'menu.invoices'
        },
        menuSections() {
            return [
                {
                    id: 'administration',
                    header: { title: 'menu.admin.title', icon: 'IconAdmin' },
                    links: [
                        {
                            id: 'menu-dashboard',
                            title: 'dashboard.title',
                            icon: 'IconDashboard',
                            url: `${this.contextLink}/dashboard`,
                            authorized:
                                this.isSuperAdmin ||
                                (this.hasFilled3OnboardingState &&
                                    (this.isAdmin ||
                                        this.isSupervisionManager ||
                                        this.isFleetManager ||
                                        (this.isBUAdmin &&
                                            !!this.businessUnit)))
                        },
                        {
                            id: 'menu-businessunits',
                            title: 'menu.businessunits',
                            icon: 'IconBusiness',
                            url: `${this.organizationLink}/business-units`,
                            authorized:
                                (this.isSuperAdmin || this.isAdmin) &&
                                !this.businessUnit
                        },
                        {
                            id: 'menu-invoices',
                            title: this.menuInvoicesTitle,
                            icon: 'IconBills',
                            url: `${this.contextLink}/orders-bills`,
                            authorized:
                                this.isSuperAdmin ||
                                (this.hasFilled3OnboardingState &&
                                    (this.isAdmin ||
                                        this.isFleetManager ||
                                        (this.isBUAdmin &&
                                            !!this.businessUnit)))
                        }
                    ]
                },
                {
                    id: 'resources',
                    header: { title: 'menu.resources', icon: 'IconFleet' },
                    links: [
                        {
                            id: 'menu-collaborators',
                            title: 'menu.fleet.collaborators',
                            icon: 'IconCollaborators',
                            url: `${this.BULink}/collaborators`
                        },
                        {
                            id: 'menu-vehicles',
                            title: 'menu.fleet.vehicles',
                            icon: 'IconVehicle',
                            url: `${this.BULink}/vehicles`
                        },
                        {
                            id: 'menu-passes',
                            title: 'menu.fleet.passes',
                            icon: 'IconPass',
                            url: '',
                            linksSubMenu: [
                                {
                                    id: 'menu-passes-business',
                                    title: 'menu.fleet.passes-business',
                                    url: `${this.BULink}/passes/business`,
                                    authorized: this.isFleetManager
                                },
                                {
                                    id: 'menu-passes-private',
                                    title: 'menu.fleet.passes-private',
                                    url: `${this.BULink}/passes/private`
                                },
                                {
                                    id: 'menu-passes-classic',
                                    title: 'menu.fleet.passes-classic',
                                    url: `${this.BULink}/passes/community`
                                }
                            ]
                        }
                    ],
                    authorized:
                        !!this.businessUnit &&
                        (this.isSuperAdmin || this.hasFilled3OnboardingState)
                },
                {
                    id: 'fleet',
                    header: { title: 'menu.fleet', icon: 'IconSupervision' },
                    links: [
                        {
                            id: 'menu-charges',
                            title: 'menu.charges',
                            icon: 'IconCharge',
                            url: `${this.contextLink}/charges`
                        }
                    ],
                    authorized:
                        !!this.businessUnit &&
                        (this.isSuperAdmin ||
                            (this.hasFilled3OnboardingState &&
                                (this.isAdmin ||
                                    this.isBUAdmin ||
                                    this.isFleetManager)))
                },
                {
                    id: 'supervision',
                    header: { title: 'menu.fleet', icon: 'IconSupervision' },
                    links: [
                        {
                            id: 'menu-supervision',
                            title: 'menu.supervision.title',
                            icon: 'IconEye',
                            url: '',
                            linksSubMenu: [
                                {
                                    id: 'menu-supervisions-charges',
                                    title: 'menu.fleet.supervisions-charges',
                                    url: `${this.BULink}/supervised-charges`,
                                    authorized:
                                        !!this.businessUnit &&
                                        (this.isSuperAdmin ||
                                            this.isAdmin ||
                                            this.isBUAdmin ||
                                            this.isSupervisionManager)
                                },
                                {
                                    id: 'menu-zones',
                                    title: 'menu.fleet.zones',
                                    url: `${this.BULink}/zones`,
                                    authorized:
                                        this.isSuperAdmin ||
                                        this.isAdmin ||
                                        this.isBUAdmin ||
                                        this.isSupervisionManager
                                }
                            ]
                        }
                    ],
                    authorized:
                        !!this.businessUnit &&
                        (this.isSuperAdmin ||
                            (this.hasFilled3OnboardingState &&
                                (this.isAdmin ||
                                    this.isBUAdmin ||
                                    this.isSupervisionManager)))
                },
                {
                    id: 'other',
                    header: {
                        title: 'menu.analytics.title',
                        icon: 'IconSupervision'
                    },
                    links: [
                        {
                            id: 'menu-other-organization',
                            title: 'menu.organization',
                            icon: 'IconSettings',
                            url: `${this.organizationLink}/parameters`,
                            authorized:
                                (this.isSuperAdmin || this.isAdmin) &&
                                !!this.organization &&
                                !this.businessUnit
                        },
                        {
                            id: 'menu-other-businessunits',
                            title: 'menu.businessunit',
                            icon: 'IconBusiness',
                            url: `${this.BULink}/parameters`,
                            authorized:
                                (this.isSuperAdmin ||
                                    this.isAdmin ||
                                    this.isBUAdmin) &&
                                !!this.businessUnit
                        },
                        {
                            id: 'menu-other-users',
                            title: 'menu.users',
                            icon: 'IconUsers',
                            url: `${this.organizationLink}/users`,
                            authorized:
                                this.isSuperAdmin ||
                                (this.hasFilled3OnboardingState &&
                                    (this.isAdmin || this.isBUAdmin) &&
                                    !!this.organization)
                        }
                    ],
                    authorized:
                        this.isSuperAdmin ||
                        this.isAdmin ||
                        (this.isBUAdmin && !!this.businessUnit)
                }
            ]
        },
        organizationLink() {
            return `/organizations/${this.organization?.id}`
        },
        BULink() {
            return `${this.organizationLink}/business-units/${this.businessUnit?.id}`
        },
        contextLink() {
            return this.businessUnit ? this.BULink : this.organizationLink
        }
    },
    methods: {
        switchMenu(id) {
            this.$emit('selected', id)
        },
        closeMobileMenu() {
            this.$emit('closeMobileMenu')
        }
    }
})
