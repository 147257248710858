import { createLocalVue, mount } from '@vue/test-utils'

import { DateTime } from 'luxon'
// @ts-ignore
import AppFilterDate from '@/components/UI/AppFilterDate.vue'

// Mocks
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()

jest.useFakeTimers().setSystemTime(new Date('2022-06-29'))

describe('components/UI/AppFilterDate.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = mount(AppFilterDate, {
            ...basicMountWrapper,
            localVue,
            propsData: {
                value: ['2022-06-29']
            }
        })
    })

    afterEach(() => {
        jest.clearAllMocks()
    })

    describe('label', () => {
        it('default title', () => {
            expect(wrapper.vm.label).toBe('time.today')
        })

        it('with start date, yesterday', async () => {
            const nbDays = 1
            const startDate = DateTime.now().minus({ days: nbDays }).toISODate()
            await wrapper.setProps({
                value: [startDate]
            })
            expect(wrapper.vm.label).toBe('time.yesterday')
        })

        it('with start date, 30 days ago', async () => {
            const nbDays = 30
            const startDate = DateTime.now().minus({ days: nbDays }).toISODate()
            await wrapper.setProps({
                value: [startDate]
            })
            expect(wrapper.vm.label).toEqual(
                `${nbDays} time.last.other time.day.other`
            )
        })

        it('with start and end date', async () => {
            const startDate = '2020-12-03'
            const endDate = '2020-12-24'
            await wrapper.setProps({
                value: [startDate, endDate]
            })
            expect(wrapper.vm.label).toBe(
                'time.from December 3, 2020 time.to December 24, 2020'
            )
        })
        it('last 3 month', async () => {
            jest.useFakeTimers().setSystemTime(new Date('2021-05-01').getTime())
            let startDate = '2021-02-03'
            await wrapper.setProps({
                value: [startDate]
            })
            expect(wrapper.vm.label).toBe('3 time.last.other time.month.other')
            jest.useFakeTimers().setSystemTime(new Date('2021-05-01').getTime())
            startDate = '2021-01-03'
            await wrapper.setProps({
                value: [startDate]
            })
            expect(wrapper.vm.label).toBe('4 time.last.other time.month.other')
            jest.useFakeTimers().setSystemTime(new Date('2021-05-01').getTime())
            startDate = '2021-02-28'
            await wrapper.setProps({
                value: [startDate]
            })
            expect(wrapper.vm.label).toBe('2 time.last.other time.month.other')
        })
    })

    describe('update days', () => {
        it('-1', () => {
            const days = 1
            const dateTarget = DateTime.now().set({ hour: 1 }).minus({ days })
            wrapper.vm.updateDays(days)
            setTimeout(() => {
                expect(wrapper.emitted().input).toBe([dateTarget.toISODate()])
            }, 500)
        })

        it('-28', () => {
            const days = 28
            const dateTarget = DateTime.now().set({ hour: 13 }).minus({ days })
            wrapper.vm.updateDays(days)
            setTimeout(() => {
                expect(wrapper.emitted().input).toEqual([
                    dateTarget.toISODate()
                ])
            }, 500)
        })

        it('empty', () => {
            wrapper.vm.updateDays()
            setTimeout(() => {
                expect(wrapper.emitted().input).toEqual([])
            }, 500)
        })
    })

    it('calls updateDate emit input', () => {
        const dateStart = '2020-12-04'
        const dateEnd = '2020-12-20'
        wrapper.vm.updateDate(dateStart, dateEnd)
        setTimeout(() => {
            expect(wrapper.emitted().input).toEqual([dateStart, dateEnd])
        }, 500)
    })

    it('updateFilter', () => {
        const uid = wrapper.vm._uid
        const badFilter = { id: 999, value: 10 }
        const filterValue = { id: uid, value: 30 }
        const spyUpdate = jest.spyOn(wrapper.vm, 'updateDays')
        wrapper.vm.updateLocalFilter(badFilter)
        expect(spyUpdate).not.toHaveBeenCalled()
        wrapper.vm.updateLocalFilter(filterValue)
        expect(spyUpdate).toHaveBeenCalledWith(30)
    })
})
