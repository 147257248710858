
import Vue from 'vue'

export default Vue.extend({
    props: {
        value: {
            type: String,
            default() {
                return ''
            }
        }
    },
    data() {
        return {
            maxChar: 500
        }
    },
    computed: {
        maxCharAvailable(): number {
            return this.maxChar - (this.value?.length || 0)
        },
        tooMuchChar(): boolean {
            return this.maxCharAvailable < 0
        }
    }
})
