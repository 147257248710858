import { render, staticRenderFns } from "./SwitchCheck.vue?vue&type=template&id=524ae563"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports