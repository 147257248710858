
import validHexa from '~/validations/hexa'

export default {
    name: 'AppControlInput',
    inheritAttrs: false,
    props: {
        id: {
            type: String,
            default: null
        },
        inputClass: {
            type: String,
            default: ''
        },
        controlType: {
            type: String,
            default: 'input'
        },
        value: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    emits: ['input', 'error'],
    data() {
        return {
            formId: null,
            error: ''
        }
    },
    mounted() {
        this.formId = this.id || this._uid
    },
    methods: {
        controlInput() {
            this.error = ''
            if (
                this.controlType === 'email' &&
                this.value &&
                !this.validEmail(this.value)
            ) {
                this.error = this.$tc('email.invalid')
            }
            if (
                this.controlType === 'tel' &&
                this.value &&
                !this.validTel(this.value)
            ) {
                this.error = this.$tc('tel.invalid')
            }
            if (
                this.controlType === 'hexa' &&
                this.value &&
                !validHexa(this.value)
            ) {
                this.error = this.$tc('hexa.invalid')
            }
            this.$emit('error', { error: this.error, id: this.formId })
        },
        validEmail(email) {
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(email)
        },
        validTel(tel) {
            const re =
                /^((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{7}))$/
            return re.test(tel)
        }
    }
}
