
import Vue from 'vue'
import TeslaCB from '~/components/UI/images/tesla/TeslaCB.vue'
import DriverLink from '~/components/UI/images/tesla/DriverLink.vue'
import LinkTeslaChargeMap from '~/components/UI/images/tesla/LinkTeslaChargeMap.vue'
import Wallet from '~/components/UI/images/tesla/Wallet.vue'
import KeepHabits from '~/components/UI/images/tesla/KeepHabits.vue'
import AppChevronLeft from '~/components/UI/images/AppChevronLeft.vue'
import AppChevronRight from '~/components/UI/images/AppChevronRight.vue'
import { TypeSlideCarousel } from '~/interfaces/other'

export default Vue.extend({
    name: 'AppCarousel',
    components: {
        TeslaCB,
        DriverLink,
        LinkTeslaChargeMap,
        Wallet,
        AppChevronLeft,
        AppChevronRight,
        KeepHabits
    },
    props: {
        listSlides: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            currentIndex: 0,
            timeTotal: 300,
            timePhaseOne: 10,
            timer: null as null | ReturnType<typeof setInterval>
        }
    },
    computed: {
        slides() {
            return this.listSlides
        }
    },
    mounted() {
        this.setInterval()
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer)
        }
    },
    methods: {
        sleep(milliseconds: number) {
            return new Promise((resolve) => setTimeout(resolve, milliseconds))
        },
        setInterval() {
            clearInterval(this.timer as ReturnType<typeof setInterval>)
            this.timer = setInterval(() => {
                this.changeSlide(TypeSlideCarousel.NEXT, true)
            }, 5000)
        },
        getIndexSecondSlide(this: any, typeChangeSlide: TypeSlideCarousel) {
            let secondIndex =
                this.currentIndex + 1 <= this.slides.length - 1
                    ? this.currentIndex + 1
                    : 0
            if (typeChangeSlide === TypeSlideCarousel.PREV) {
                secondIndex =
                    this.currentIndex - 1 >= 0
                        ? this.currentIndex - 1
                        : this.slides.length - 1
            }
            return secondIndex
        },
        updateShowClass(
            index: number,
            value: boolean,
            type: string,
            emitName: string
        ) {
            this.$emit(emitName, { index, value, type })
        },
        async animationPhaseOne(this: any, typeChangeSlide: TypeSlideCarousel) {
            const indexSecondSlide = this.getIndexSecondSlide(typeChangeSlide)
            const indexList = [this.currentIndex, indexSecondSlide]
            this.updateShowClass(
                indexSecondSlide,
                true,
                typeChangeSlide,
                'updateShowClassPrevNext'
            )
            await this.sleep(this.timePhaseOne)
            indexList.forEach((index) =>
                this.updateShowClass(
                    index,
                    true,
                    typeChangeSlide,
                    'updateShowClass'
                )
            )
        },
        animationPhaseTwo(this: any, typeChangeSlide: TypeSlideCarousel) {
            this.listSlides.forEach((_: any, indexSlide: number) => {
                this.updateShowClass(
                    indexSlide,
                    false,
                    typeChangeSlide,
                    'updateShowClass'
                )
                this.updateShowClass(
                    indexSlide,
                    false,
                    typeChangeSlide,
                    'updateShowClassPrevNext'
                )
            })
        },
        async changeSlide(
            this: any,
            typeChangeSlide: TypeSlideCarousel,
            isInterval: boolean = false
        ) {
            if (!isInterval) {
                this.setInterval()
            }

            await this.animationPhaseOne(typeChangeSlide)
            await this.sleep(this.timeTotal - this.timePhaseOne)
            this.animationPhaseTwo(typeChangeSlide)

            if (typeChangeSlide === TypeSlideCarousel.PREV) {
                this.currentIndex =
                    this.currentIndex === 0
                        ? this.slides.length - 1
                        : this.currentIndex - 1
            } else {
                this.currentIndex =
                    this.currentIndex === this.slides.length - 1
                        ? 0
                        : this.currentIndex + 1
            }
        }
    }
})
