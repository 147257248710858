import { ref } from 'vue'
import { defineStore } from 'pinia'
import {
    ChargeboxSpecificPayloadType,
    CommandType,
    SpecificPayload
} from '~/interfaces/chargebox'

const useChargeboxCommandStore = defineStore('chargeboxCommand', () => {
    const isReloadingList = ref([] as Array<string>)

    const downloadFile = (
        context: any,
        payload: {
            idBU: number
            idChargeBoxes: string
        }
    ) => {
        const { idBU, idChargeBoxes } = payload
        const url = `/supervisionapi/business-units/${idBU}/chargeboxes/${idChargeBoxes}/configuration`
        return context.$axios
            .get(url)
            .then(
                (res: {
                    data: Blob
                    headers: { 'content-disposition': string }
                }) => res
            )
            .catch((e: any) => {
                throw e
            })
    }

    const resetConfig = (
        context: any,
        payload: {
            idBU: number
            idChargeBoxes: string
        }
    ) => {
        const { idBU, idChargeBoxes } = payload
        let url = `/supervisionapi`
        url += `/business-units/${idBU}`
        url += `/chargeboxes/${idChargeBoxes}/configuration/reset`
        return context.$axios
            .post(url)
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    }

    const resetHard = (
        context: any,
        payload: { idBU: number; idChargeBoxes: string }
    ) => {
        const params = {
            ...payload,
            action: CommandType.RESET,
            specificPayload: {
                type: ChargeboxSpecificPayloadType.HARD
            }
        }
        const { idChargeBoxes } = payload
        return useChargeboxCommandStore()
            .command(context, params)
            .then((response: { status: 'FAILED' | 'SUCCESS' }) => {
                if (response?.status === 'SUCCESS') {
                    isReloadingList.value.push(idChargeBoxes)
                    const remove = () => {
                        isReloadingList.value = isReloadingList.value.filter(
                            (id: string) => id !== idChargeBoxes
                        )
                    }
                    setTimeout(remove, 1_000 * 60 * 3)
                }
                return response
            })
    }

    const changeConfiguration = (
        context: any,
        payload: {
            idBU: number
            idChargeBoxes: string
            key: string
            value: string
        }
    ) => {
        const { idBU, idChargeBoxes, key, value } = payload
        const params = {
            idBU,
            idChargeBoxes,
            action: CommandType.CHANGE_CONFIGURATION,
            specificPayload: {
                key,
                value
            }
        }
        return useChargeboxCommandStore().command(context, params)
    }

    const unlock = (
        context: any,
        payload: {
            idBU: number
            idChargeBoxes: string
            idConnector: number
        }
    ) => {
        const { idBU, idChargeBoxes, idConnector } = payload
        const params = {
            idBU,
            idChargeBoxes,
            action: CommandType.UNLOCK,
            specificPayload: {
                connector_id: idConnector
            }
        }
        return useChargeboxCommandStore().command(context, params)
    }

    const refreshStatus = (
        context: any,
        payload: {
            idBU: number
            idChargeBoxes: string
        }
    ) => {
        const { idBU, idChargeBoxes } = payload
        const params = {
            idBU,
            idChargeBoxes,
            action: CommandType.TRIGGER,
            specificPayload: {
                requested_message: 'STATUS_NOTIFICATION'
            }
        }
        return useChargeboxCommandStore().command(context, params)
    }

    const play = (
        context: any,
        payload: {
            idBU: number
            idChargeBoxes: string
            idPass: number
            idConnector: number
        }
    ) => {
        const { idBU, idChargeBoxes, idPass, idConnector } = payload
        const params = {
            idBU,
            idChargeBoxes,
            action: CommandType.REMOTE_START_TRANSACTION,
            specificPayload: {
                pass_id: idPass,
                connector_id: idConnector
            }
        }
        return useChargeboxCommandStore().command(context, params)
    }

    const stop = (
        context: any,
        payload: {
            idBU: number
            idChargeBoxes: string
            idConnector: number
        }
    ) => {
        const { idBU, idChargeBoxes, idConnector } = payload
        const params = {
            idBU,
            idChargeBoxes,
            action: CommandType.REMOTE_STOP_TRANSACTION,
            specificPayload: {
                connector_id: idConnector
            }
        }
        return useChargeboxCommandStore().command(context, params)
    }

    const updateFirmware = (
        context: any,
        payload: {
            idBU: number
            idChargeBoxes: string
            firmwareUri: string
        }
    ) => {
        const { idBU, idChargeBoxes, firmwareUri } = payload
        const params = {
            idBU,
            idChargeBoxes,
            action: CommandType.UPDATE_FIRMWARE,
            specificPayload: {
                firmware_uri: firmwareUri
            }
        }
        return useChargeboxCommandStore().command(context, params)
    }

    const command = (
        context: any,
        payload: {
            idBU: number
            idChargeBoxes: string
            action: CommandType
            specificPayload: SpecificPayload
        }
    ) => {
        const { idBU, idChargeBoxes, action, specificPayload } = payload
        return context.$axios
            .$post(
                `/supervisionapi/business-units/${idBU}/chargeboxes/${idChargeBoxes}/actions`,
                {
                    action,
                    specific_payload: specificPayload
                }
            )
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    }

    return {
        isReloadingList,
        downloadFile,
        resetConfig,
        resetHard,
        changeConfiguration,
        unlock,
        refreshStatus,
        play,
        stop,
        updateFirmware,
        command
    }
})

type ChargeboxCommandStore = Omit<
    ReturnType<typeof useChargeboxCommandStore>,
    keyof ReturnType<typeof defineStore>
>

export default useChargeboxCommandStore

export type { ChargeboxCommandStore }
