import { render, staticRenderFns } from "./NEMA_5_20.vue?vue&type=template&id=745a7f6b"
import script from "./NEMA_5_20.vue?vue&type=script&lang=ts"
export * from "./NEMA_5_20.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports