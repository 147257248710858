
import IconChevronDown from '~/components/UI/icons/input/IconChevronDown.vue'
import MenuLink from '~/components/UI/menu/MenuLink.vue'

export default {
    name: 'SubMenuSection',
    components: {
        IconChevronDown,
        MenuLink
    },
    props: {
        link: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showSubMenu: false
        }
    },
    computed: {
        linksAuthorized(this: any) {
            return this.link.linksSubMenu.filter(
                (link: any) =>
                    link.authorized === undefined || link.authorized === true
            )
        }
    },
    watch: {
        $route(this: any) {
            this.showSubMenu = !!this.hasCurrentLink()
        }
    },
    mounted(this: any) {
        if (this.hasCurrentLink()) {
            this.showSubMenu = true
        }
    },
    methods: {
        closeMobileMenu(this: any) {
            this.$emit('closeMobileMenu')
        },
        hasCurrentLink(this: any): boolean {
            const { path } = this.$route
            let hasCurrentLink = false
            this.link.linksSubMenu.forEach((link: any) => {
                if (path.includes(link.url)) hasCurrentLink = true
            })

            return hasCurrentLink
        }
    }
}
