import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'

// @ts-ignore
import AppBanner from '@/components/UI/AppBanner.vue'

// Mocks
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()
localVue.use(Vuex)

const propsData = {
    title: 'title',
    text: 'text'
}

describe('components/UI/AppBanner.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppBanner, {
            ...basicMountWrapper,
            localVue,
            propsData
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
    })
})
