export enum PaymentType {
    NONE = 'NONE',
    STRIPE_CB = 'STRIPE_CB',
    BANK_TRANSFER = 'BANK_TRANSFER',
    DEBIT = 'DEBIT',
    MAIL_NOTIFICATION = 'MAIL_NOTIFICATION',
    CREDIT_CARD = 'CREDIT_CARD',
    CHECK = 'CHECK',
    CREDIT = 'CREDIT',
    PAYPAL = 'PAYPAL',
    DISPUTE = 'DISPUTE'
}

export enum CardStatus {
    CLEAR = 0,
    ERROR,
    COMPLETE,
    CHECKING,
    VALIDATE
}
export interface RouteInfo {
    idOrga: string
    idBU: string
}

export interface Payment {
    paymentSelected: PaymentType
    paymentId: string | null
}
export interface PaymentInfo {
    routeInfo: RouteInfo
    payment: Payment
}
export interface PrefilledCustomer {
    email: string
    company_name: string
    postal_code: string
    country_code: string
    city: string
    address_line1: string
    address_line2: string
}
