
import { mapGetters } from 'vuex'
import Inaccessible from '~/components/UI/icons/errors/Inaccessible.vue'
import NotFound from '~/components/UI/icons/errors/NotFound.vue'
import LicenseNotFound from '@/components/cloud/organization/LicenseNotFound.vue'

export default {
    components: { Inaccessible, NotFound, LicenseNotFound },
    layout: 'error',
    props: {
        error: {
            type: Object,
            default() {
                return {
                    statusCode: 500,
                    message: 'Have You Tried Turning It Off and On Again? :D'
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            organization: 'organization/getOrganization'
        }),
        email() {
            const email = this.organization?.mainContact?.email
            return email ? ` : ${email}` : ' '
        }
    }
}
