
import Vue from 'vue'
import { Prop } from 'vue/types/options'
import IconConnectorStatus from '@/components/UI/icons/connectors/status/IconConnectorStatus.vue'
import AppBadge from '~/components/UI/AppBadge.vue'
import AppFlyout from '~/components/UI/AppFlyout.vue'
import IconConnector from '~/components/UI/icons/IconConnector.vue'
import IconCharging from '~/components/UI/icons/IconCharging.vue'
import { evseStatuses as statuses } from '~/interfaces/chargebox'
import { Connector, ConnectorColorStatus } from '~/interfaces/connector'

export default Vue.extend({
    // https://tailwindui.com/components/application-ui/lists/stacked-lists
    name: 'ConnectorChargebox',
    components: {
        AppBadge,
        AppFlyout,
        IconConnector,
        IconCharging,
        IconConnectorStatus
    },
    props: {
        connector: {
            type: Object as Prop<Connector>,
            required: true
        },
        chargeboxConnected: {
            type: Boolean,
            default: false
        },
        disableFlyout: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        statuses,
        enableFlyout: false
    }),
    computed: {
        statusDisplayed() {
            if (!this.chargeboxConnected) {
                return 'chargeboxes.status.offline'
            }
            const { connector } = this
            return `statuses.${connector.status}`
        },
        isPluggedIn() {
            const { connector } = this
            return (
                connector.status === statuses.CHARGING ||
                connector.status === statuses.PREPARING ||
                connector.status === statuses.SUSPENDED_EVSE ||
                connector.status === statuses.SUSPENDED_EV ||
                connector.status === statuses.FINISHING
            )
        },
        colorBadge(this: any): string {
            if (this.color === 'black' || this.color === 'gray') {
                return 'gray'
            }
            return this.color
        },
        color(): string {
            if (!this.chargeboxConnected) {
                return 'gray'
            }
            return this.connector.status
                ? ConnectorColorStatus.get(this.connector.status) ?? 'gray'
                : 'gray'
        },
        typeStyle() {
            switch (this.connector.status) {
                case statuses.RESERVED:
                case statuses.SUSPENDED_EVSE:
                case statuses.SUSPENDED_EV:
                case statuses.PREPARING:
                case statuses.FINISHING:
                    return 'font-bold'
                case statuses.CHARGING:
                    return 'font-bold'
                case statuses.FAULTED:
                    return 'text-red-600'
                case statuses.AVAILABLE:
                    return ''
                case statuses.UNAVAILABLE:
                case statuses.UNKNOWN:
                    return 'text-gray-600'
                default:
                    return 'gray'
            }
        },
        powerStyle() {
            switch (this.connector.status) {
                case statuses.RESERVED:
                    return 'text-purple-800 font-bold'
                case statuses.SUSPENDED_EVSE:
                case statuses.SUSPENDED_EV:
                case statuses.PREPARING:
                case statuses.FINISHING:
                    return 'text-orange-800 font-bold'
                case statuses.CHARGING:
                    return 'text-green-600 font-medium'
                case statuses.UNAVAILABLE:
                case statuses.AVAILABLE:
                case statuses.UNKNOWN:
                case statuses.FAULTED:
                default:
                    return 'text-gray-400'
            }
        }
    },
    mounted() {
        this.enableFlyout = !this.disableFlyout
    }
})
