export enum organizationRoles {
    ADMIN = 'ADMIN',
    MEMBER = 'MEMBER'
}

export enum businessUnitRoles {
    BU_ADMIN = 'BU_ADMIN',
    FLEET_MANAGER = 'FLEET_MANAGER',
    SUPERVISOR = 'SUPERVISOR'
}

export enum roleIcons {
    ADMIN = 'IconBusiness',
    MEMBER = 'IconUserCircle',
    BU_ADMIN = 'IconBusiness',
    FLEET_MANAGER = 'IconFleet',
    SUPERVISOR = 'IconStation'
}
