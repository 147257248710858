var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ChargemapDropdown',{attrs:{"data-testid":"app-filter"},scopedSlots:_vm._u([{key:"button",fn:function(slotProps){return [_c('button',{ref:"buttonRef",staticClass:"filters-trigger",attrs:{"aria-label":"Filter","aria-haspopup":"true"},on:{"click":function($event){return _vm.toggleDropdown(slotProps, true)}}},[_c('div',{staticClass:"flex items-center gap-2"},[_c('IconDate',{staticClass:"text-gray-500"}),_vm._v(" "),(_vm.label)?_c('span',[_vm._v("\n                    "+_vm._s(_vm.label)+"\n                ")]):_c('span',[_vm._v("\n                    "+_vm._s(_vm.$d(_vm.DateTime.fromISO(_vm.value[0]).toJSDate(), 'date'))+"\n                    "+_vm._s(_vm.$tc('time.to'))+"\n                    "+_vm._s(_vm.$d(_vm.DateTime.fromISO(_vm.value[1]).toJSDate(), 'date'))+"\n                ")])],1),_vm._v(" "),_c('IconArrowDown')],1)]}},{key:"actionsList",fn:function(slotProps){return [_c('div',{staticClass:"py-1"},[_c('button',{staticClass:"filters-button",class:{
                    'filters-button--selected':
                        _vm.diffDaysToday === null && !_vm.value[1]
                },attrs:{"role":"menuitem"},on:{"click":function($event){return _vm.updateDays(null, slotProps)}}},[_c('span',{staticClass:"filters-button__check"},[_c('IconCheck',{staticClass:"icon"})],1),_vm._v("\n\n                "+_vm._s(_vm.$tc('all-fem'))+"\n            ")]),_vm._v(" "),(_vm.low !== null)?_c('button',{staticClass:"filters-button",class:{
                    'filters-button--selected': _vm.diffDaysToday === _vm.low
                },attrs:{"role":"menuitem"},on:{"click":function($event){return _vm.updateDays(_vm.low, slotProps)}}},[_c('span',{staticClass:"filters-button__check"},[_c('IconCheck',{staticClass:"icon"})],1),_vm._v(" "),(_vm.low === 0)?_c('span',[_vm._v(_vm._s(_vm.$tc('time.today')))]):_c('span',[_vm._v("\n                    "+_vm._s(_vm.low)+" "+_vm._s(_vm.$tc('time.last.other'))+"\n                    "+_vm._s(_vm.$tc('time.day.other'))+"\n                ")])]):_vm._e(),_vm._v(" "),(_vm.middle !== null)?_c('button',{staticClass:"filters-button",class:{
                    'filters-button--selected': _vm.diffDaysToday === _vm.middle
                },attrs:{"role":"menuitem"},on:{"click":function($event){return _vm.updateDays(_vm.middle, slotProps)}}},[_c('span',{staticClass:"filters-button__check"},[_c('IconCheck',{staticClass:"icon"})],1),_vm._v("\n\n                "+_vm._s(_vm.middle)+" "+_vm._s(_vm.$tc('time.last.other'))+"\n                "+_vm._s(_vm.$tc('time.day.other'))+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.high !== null)?_c('button',{staticClass:"filters-button",class:{
                    'filters-button--selected': _vm.diffDaysToday === _vm.high
                },attrs:{"role":"menuitem"},on:{"click":function($event){return _vm.updateDays(_vm.high, slotProps)}}},[_c('span',{staticClass:"filters-button__check"},[_c('IconCheck',{staticClass:"icon"})],1),_vm._v("\n\n                "+_vm._s(_vm.high / 30)+" "+_vm._s(_vm.$tc('time.last.other'))+"\n                "+_vm._s(_vm.$tc('time.month.other'))+"\n            ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"py-1"},[_c('label',{staticClass:"px-4 py-2 text-left text-sm text-gray-700"},[_vm._v("\n                "+_vm._s(_vm.$tc('sessions.custom'))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"px-4 py-2"},[_c('ChargemapDatepicker',{staticClass:"!w-full",attrs:{"value":_vm.value,"is-range":"","placeholder":_vm.$tc('time.selectTwoDates'),"week-start":1,"lang":_vm.currentLocale,"clearable":""},on:{"change":function($event){return _vm.updateDate($event)}}})],1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }