
import Vue, { PropType } from 'vue'

import ChargemapModal from '~/components/UI/CMUI/ChargemapModal.vue'
import { DialogType, DialogVariant } from '~/interfaces/ui'

export default Vue.extend({
    name: 'ChargemapDialog',
    components: { ChargemapModal },
    props: {
        type: {
            type: String as PropType<DialogType>,
            default: DialogType.Alert
        },
        variant: {
            type: String as PropType<DialogVariant>,
            default: DialogVariant.INFO
        },
        title: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: 'name'
        },
        okButtonText: {
            type: String,
            default: 'global.delete'
        },
        cancelButtonText: {
            type: String,
            default: 'global.cancel'
        }
    },
    data() {
        return {
            DialogVariant,
            showDialog: false,
            resolve: null as null | ((value?: unknown) => void),
            reject: null as null | ((value?: unknown) => void)
        }
    },
    computed: {
        colorBtn(): string | undefined {
            return this.variant === DialogVariant.DELETE
                ? 'alert-full'
                : undefined
        }
    },
    methods: {
        updateModalVisibility(value: boolean) {
            this.showDialog = value
        },
        close() {
            this.updateModalVisibility(false)
            if (this.resolve) this.resolve(false)
        },
        confirm() {
            this.updateModalVisibility(false)
            if (this.resolve) this.resolve(true)
        }
    }
})
