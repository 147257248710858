import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'

// @ts-ignore
import AppSwitch from '@/components/UI/AppSwitch.vue'

const localVue = createLocalVue()
localVue.use(Vuex)

describe('components/UI/AppSwitch.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppSwitch, {
            localVue
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
    })
    it('should emit an event on click', async () => {
        const component = wrapper.find('[data-testid="app_switch"]')
        expect(component.exists()).toBeTruthy()
        await component.trigger('click')
        expect(wrapper.emitted().input).toHaveLength(1)
    })
})
