import {
    evseStatuses,
    ConnectorCurrentTypes,
    connectorTypes
} from './chargebox'

export interface Connector {
    id: number
    status?: evseStatuses
    power?: number | null
    statusDate?: string
    currentType?: ConnectorCurrentTypes | null
    type: connectorTypes
}
export interface ConnectorJSON {
    id: number
    status?: evseStatuses
    power: number
    status_date?: string
    current_type: ConnectorCurrentTypes
    type: connectorTypes
}

export enum ConnectorColor {
    BLUE = 'chargemap',
    GREEN = 'green',
    ORANGE = 'orange',
    PURPLE = 'purple',
    BLACK = 'black',
    GRAY = 'gray',
    RED = 'red'
}

export const ConnectorColorStatus = new Map([
    [evseStatuses.AVAILABLE, ConnectorColor.GREEN],
    [evseStatuses.RESERVED, ConnectorColor.PURPLE],
    [evseStatuses.SUSPENDED_EVSE, ConnectorColor.ORANGE],
    [evseStatuses.SUSPENDED_EV, ConnectorColor.ORANGE],
    [evseStatuses.PREPARING, ConnectorColor.ORANGE],
    [evseStatuses.FINISHING, ConnectorColor.ORANGE],
    [evseStatuses.CHARGING, ConnectorColor.BLUE],
    [evseStatuses.FAULTED, ConnectorColor.RED],
    [evseStatuses.UNAVAILABLE, ConnectorColor.BLACK],
    [evseStatuses.UNKNOWN, ConnectorColor.GRAY]
])

export const ConnectorIconStatus = new Map([
    [evseStatuses.AVAILABLE, 'IconCheckCircle'],
    [evseStatuses.RESERVED, 'IconFlag'],
    [evseStatuses.SUSPENDED_EVSE, 'IconChargingInactivity'],
    [evseStatuses.SUSPENDED_EV, 'IconChargingInactivity'],
    [evseStatuses.PREPARING, 'IconCharging'],
    [evseStatuses.FINISHING, 'IconCharging'],
    [evseStatuses.CHARGING, 'IconCharging'],
    [evseStatuses.FAULTED, 'IconXCircle'],
    [evseStatuses.UNAVAILABLE, 'IconBan'],
    [evseStatuses.UNKNOWN, 'IconQuestionMark']
])
