import { defineStore } from 'pinia'
import { Filters } from '~/interfaces/filters'

const useMobileFilterStore = defineStore('mobileFilter', {
    state: () => ({
        filters: [] as Filters[],
        showBottomSheet: false as boolean,
        showSearchBar: false as boolean,
        hasSearch: false as boolean,
        toUpdate: false as boolean
    }),
    getters: {
        getFilters(state: any) {
            return state.filters
        },
        getFilter(state: any) {
            return (filter: any) =>
                state.filters.find((f: any) => f.id === filter.id)
        },
        getShowBottomSheet(state: any) {
            return state.showBottomSheet
        },
        getShowSearchBar(state: any) {
            return state.showSearchBar
        },
        getHasSearch(state: any) {
            return state.hasSearch
        },
        getToUpdate(state: any) {
            return state.toUpdate
        }
    },
    actions: {
        setFilters(filters: any) {
            this.filters = filters
        },
        setShowBottomSheet(showBottomSheet: boolean) {
            this.showBottomSheet = showBottomSheet
        },
        setShowSearchBar(showSearchBar: boolean) {
            this.showSearchBar = showSearchBar
        },
        setToUpdate(toUpdate: boolean) {
            this.toUpdate = toUpdate
        },
        addFilter(filter: any) {
            this.filters.push(filter)
        },
        removeFilter(idFilter: number) {
            this.filters = this.filters.filter((f: any) => f.id !== idFilter)
        },
        resetFilter() {
            this.filters.forEach((filter: any) => {
                filter.value = null
            })
        },
        updateFilter(filter: any) {
            const filterMatch = this.getFilter(filter)
            if (filterMatch) {
                filterMatch.value = filter.value
            }
        },
        setHasSearch(hasSearch: boolean) {
            this.hasSearch = hasSearch
        }
    }
})

export default useMobileFilterStore
