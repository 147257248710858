import Vue from 'vue'
import { DateTime } from 'luxon'
import { Context } from '@nuxt/types'

export function convertSecondsToDurationWithUnit(seconds: number): string {
    seconds = Number(seconds)
    const days = Math.floor(seconds / (3600 * 24))
    let hours: number | string = Math.floor((seconds % (3600 * 24)) / 3600)
    let minutes: number | string = Math.floor((seconds % 3600) / 60)

    if (days) hours = days * 24 + hours
    if (hours < 10) hours = `0${hours}`
    if (minutes < 10) minutes = `0${minutes}`
    return `${hours}h${minutes}min`
}
Vue.filter('convertSecondsToDurationWithUnit', convertSecondsToDurationWithUnit)

export function getDateSubstract(
    amountToRemove: number,
    type: 'day' | 'month',
    dateAsParam = ''
): string | null {
    const date = dateAsParam ? DateTime.fromISO(dateAsParam) : DateTime.now()
    if (type === 'day') {
        return date.minus({ days: amountToRemove }).toISODate()
    }
    return date.minus({ months: amountToRemove }).toISODate()
}
Vue.filter('getDateSubstract', getDateSubstract)

export default ({ app }: Context) => {
    const { i18n } = app

    function convertMinutesToDuration(value: number): string {
        const secondsInDay = 60 * 24
        const secondsAfterDays = value % secondsInDay
        const days = (value - secondsAfterDays) / secondsInDay
        if (days === 0) {
            return convertSecondsToDurationWithUnit(secondsAfterDays)
        }

        return `${days} ${i18n.tc(
            days > 1 ? 'time.day.other' : 'time.day.one'
        )}`
    }
    Vue.filter('convertMinutesToDuration', convertMinutesToDuration)

    function convertDateToDuration(value: string): string {
        const pastDate = DateTime.fromISO(value)
        const now = DateTime.now()
        const diffFromNow = now.diff(pastDate, ['days', 'hours', 'minutes'])
        const { days, minutes, hours } = diffFromNow
        if (days === 0) {
            return `${hours}h${Math.floor(minutes)}min`
        }
        return `${days} ${i18n.tc(
            days > 1 ? 'time.day.other' : 'time.day.one'
        )}`
    }
    Vue.filter('convertDateToDuration', convertDateToDuration)

    function durationSinceDate(value: string): string {
        const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
        const pastDate = DateTime.fromISO(value)
        const nowDate = DateTime.now()
        const secondsPast = nowDate.toMillis() - pastDate.toMillis()
        const days = Math.floor(Math.abs(secondsPast / oneDay))
        let duration = ''
        if (days === 0) {
            const oneHour = 60 * 60 * 1000 // minutes*seconds*milliseconds
            const hours = Math.floor(Math.abs(secondsPast / oneHour))
            if (hours === 0) {
                const oneMinute = 60 * 1000
                const minutes = Math.floor(Math.abs(secondsPast / oneMinute))
                duration =
                    minutes === 0
                        ? `${i18n.tc('time.lessThan')} 1 ${i18n.tc(
                              'time.minute.one'
                          )}`
                        : `${minutes} ${i18n.tc(
                              minutes > 1
                                  ? 'time.minute.other'
                                  : 'time.minute.one'
                          )}`
            } else {
                duration = `${hours} ${i18n.tc(
                    hours > 1 ? 'time.hour.other' : 'time.hour.one'
                )}`
            }
        } else {
            duration = `${days} ${i18n.tc(
                days > 1 ? 'time.day.other' : 'time.day.one'
            )}`
        }
        return i18n.tc('time.lastUpdate') + duration + i18n.tc('time.ago')
    }
    Vue.filter('durationSinceDate', durationSinceDate)

    // Convert Wh to kWh
    function convertPower(value: number): string {
        return i18n.n(Math.round(value) / 1000) // keep decimals
    }
    Vue.filter('convertPower', convertPower)

    function convertPrice(value: number): string {
        return `${i18n.n(Number((value / 100).toFixed(2)))} € HT`
    }
    Vue.filter('convertPrice', convertPrice)

    function convertPriceWithoutTax(value: number): string {
        return `${i18n.n(Number((value / 100).toFixed(2)))} €`
    }
    Vue.filter('convertPriceWithoutTax', convertPriceWithoutTax)

    function addPriceUnit(value: number): string {
        return i18n.n(value, 'currency')
    }
    Vue.filter('addPriceUnit', addPriceUnit)

    function convertPriceWithUnit(value: number): string {
        return i18n.n(Number((value / 100).toFixed(2)), 'currency')
    }
    Vue.filter('convertPriceWithUnit', convertPriceWithUnit)

    function convertPowerWithUnit(value: number): string {
        return `${i18n.n(Number((value / 1000).toFixed(2)))} kW`
    }
    Vue.filter('convertPowerWithUnit', convertPowerWithUnit)

    function convertEnergyWithUnit(value: number): string {
        return `${i18n.n(Number((value / 1000).toFixed(2)))} kWh`
    }
    Vue.filter('convertEnergyWithUnit', convertEnergyWithUnit)

    function powerWithUnit(value: number): string {
        return `${i18n.n(value)} kWh`
    }
    Vue.filter('powerWithUnit', powerWithUnit)

    function rounded(value: number): string {
        return i18n.n(Math.round(value))
    }
    Vue.filter('rounded', rounded)
}
