import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'

// @ts-ignore
import IconCharging from '@/components/UI/icons/IconCharging.vue'

// Mocks
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()
localVue.use(Vuex)

describe('components/UI/IconCharging.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(IconCharging, {
            ...basicMountWrapper,
            localVue
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        expect(wrapper.vm.powerActive).toBeTruthy()
    })
})
