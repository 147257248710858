
import Vue from 'vue'
import Cookies from 'js-cookie'
import IconCross from '~/components/UI/icons/layout/IconCross.vue'
import AppAlert from '~/components/UI/images/AppAlert.vue'

export default Vue.extend({
    name: 'AppBanner',
    components: {
        IconCross,
        AppAlert
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: 'banner-visible'
        }
    },
    data: () => ({ visible: false }),
    mounted() {
        const cookieValue = Cookies.get(this.name)
        if (!cookieValue) {
            Cookies.set(this.name, 'true', {
                expires: 365
            })
        }

        this.visible = Cookies.get(this.name) === 'true'
    },
    methods: {
        visit() {
            Cookies.set(this.name, 'false', {
                expires: 365
            })
            this.visible = false
        },
        openMore() {
            this.$emit('openMore')
        }
    }
})
