import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'
// @ts-ignore
import AppListPage from '@/components/UI/AppListPage.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()
localVue.use(Vuex)
describe('components/AppListPage.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppListPage, {
            ...basicMountWrapper,
            localVue
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="app_list_page"]')
        expect(component.exists()).toBeTruthy()
    })
})
