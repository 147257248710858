
import SubMenuSection from '~/components/UI/menu/SubMenuSection.vue'
import MenuLink from '~/components/UI/menu/MenuLink.vue'

export default {
    name: 'MenuSection',
    components: {
        SubMenuSection,
        MenuLink
    },
    props: {
        id: {
            type: String,
            required: true
        },
        header: {
            type: Object,
            required: true
        },
        listLinks: {
            type: Array,
            required: true
        },
        authorized: {
            type: Boolean,
            default: true
        }
    },
    emits: ['closeMobileMenu'],
    computed: {
        linksAuthorized() {
            return this.listLinks.filter(
                (link) =>
                    link.authorized === undefined || link.authorized === true
            )
        }
    },
    methods: {
        closeMobileMenu() {
            this.$emit('closeMobileMenu')
        }
    }
}
