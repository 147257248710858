function toCamel(s) {
    return s.replace(/([_]([a-z]|[1-9]))/gi, ($1) =>
        $1.toUpperCase().replace('_', '')
    )
}

function keysToCamel(o) {
    if (o === Object(o) && !Array.isArray(o) && typeof o !== 'function') {
        const n = {}
        Object.keys(o).forEach((k) => {
            n[toCamel(k)] = keysToCamel(o[k])
        })
        return n
    }
    if (Array.isArray(o)) {
        return o.map((i) => keysToCamel(i))
    }
    return o
}

export const $toCamel = keysToCamel

function toSnake(s) {
    return s
        .split(/(?=[A-Z]|[1-9])/)
        .join('_')
        .toLowerCase()
}

function keysToSnake(o) {
    if (o === Object(o) && !Array.isArray(o) && typeof o !== 'function') {
        const n = {}
        Object.keys(o).forEach((k) => {
            n[toSnake(k)] = keysToSnake(o[k])
        })
        return n
    }
    if (Array.isArray(o)) {
        return o.map((i) => keysToSnake(i))
    }
    return o
}

export const $toSnake = keysToSnake

export const camelToKebab = (s) =>
    s.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()

export const $toKebab = camelToKebab

export const toCapitalize = (s) =>
    s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()

export const $toCapitalize = toCapitalize
