// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Vue from 'vue'
import { keysToCamel } from './utils'

declare module 'vue/types/vue' {
    interface Vue {
        $toCamel: (e: any) => any
    }
}

export default (_: any, inject: any) => {
    inject('toCamel', (json: any) => keysToCamel(json))
}
