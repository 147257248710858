
import Vue from 'vue'
import _ from 'lodash'
import { onClickOutside } from '@vueuse/core'
import { type OptionChargemapSelect } from '~/interfaces/ui'
import IconCheck from '~/components/UI/icons/dropdown/IconCheck.vue'
import IconChevronDown from '~/components/UI/icons/input/IconChevronDown.vue'

export default Vue.extend({
    name: 'ChargemapSelect',
    components: { IconChevronDown, IconCheck },
    props: {
        value: {
            type: [String, Number, Array],
            default: ''
        },
        options: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            default: ''
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        large: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        hasSearch: {
            type: Boolean,
            default: false
        },
        noResultsText: {
            type: String,
            default: ''
        },
        hasAsyncFetch: {
            type: Boolean,
            default: false
        },
        loadingText: {
            type: String,
            default() {
                return this.$t('select.moreResults')
            }
        },
        searchPlaceholder: {
            type: String,
            default: ''
        },
        clearable: {
            type: Boolean,
            default: false
        },
        position: {
            type: String,
            default: 'bottom',
            validator: (value: string) => ['top', 'bottom'].includes(value)
        },
        isValid: {
            type: Boolean,
            default: true
        },
        canCreateOption: {
            type: Boolean,
            default: false
        },
        customInfoSearchText: {
            type: String,
            default: ''
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        isOpen: false,
        keyword: '',
        showLabel: false,
        tamponCurrentValue: undefined as OptionChargemapSelect | undefined
    }),
    computed: {
        keywordIsSameAsValue(): boolean {
            return (
                this.displayOptions.filter(
                    (option) =>
                        option.value.toLowerCase() ===
                        this.keyword.toLowerCase()
                ).length > 0
            )
        },
        displayValue(): string {
            if (this.options.length > 0) {
                if (this.multiple) {
                    if (Array.isArray(this.value)) {
                        const _options = this.displayOptions
                        const currentOpt = _options.filter((i) =>
                            (this.value as any[]).includes(i.id)
                        )
                        if (currentOpt && currentOpt.length > 0) {
                            return currentOpt.map((i) => i.value).join(', ')
                        }
                    }
                } else {
                    const _options = this.displayOptions
                    const currentOpt = _options.find((i) => i.id === this.value)
                    if (currentOpt) {
                        return currentOpt.value
                    }
                }
            } else if (this.tamponCurrentValue) {
                return this.tamponCurrentValue.value
            }
            return this.placeholder
        },
        currentValueObject(): OptionChargemapSelect | null {
            if (this.options.length > 0) {
                const _options = this.displayOptions
                const currentOpt = _options.find((i) => i.id === this.value)
                if (currentOpt) {
                    return currentOpt
                }
            } else if (this.tamponCurrentValue) {
                return this.tamponCurrentValue
            }
            return null
        },
        displayOptions(): OptionChargemapSelect[] {
            const listOptions = [] as OptionChargemapSelect[]
            if (this.options.length > 0) {
                const checkType = this.options[0]
                if (
                    checkType !== null &&
                    typeof checkType === 'object' &&
                    !Array.isArray(checkType)
                ) {
                    this.options.forEach((option: any) => {
                        const optId = option.id
                        const optValue = option.value
                        const optBlocked = option.blocked || false
                        const optRaw = option.raw || null
                        listOptions.push({
                            id: optId,
                            value: optValue,
                            blocked: optBlocked,
                            raw: optRaw
                        })
                    })
                }
            }
            return listOptions
        },
        filteredValues(): OptionChargemapSelect[] {
            // If the data is filtered by the query, we don't filter it again
            if (this.hasAsyncFetch) {
                return this.displayOptions
            }

            return this.displayOptions.filter((option) =>
                option.value
                    ?.toLowerCase()
                    ?.includes(this.keyword.toLowerCase())
            )
        }
    },
    watch: {
        value() {
            this.getTamponCurrentValue()
        },
        options() {
            this.getTamponCurrentValue()
        }
    },
    mounted() {
        document.addEventListener(
            'keyup',
            (event) => {
                if (this.isOpen) {
                    event.stopImmediatePropagation()
                    this.closeSelectWhenEscape(event)
                }
            },
            true
        )

        onClickOutside(this.$refs.ChargemapSelectRef as any, () =>
            this.closeSelect()
        )
    },
    beforeDestroy() {
        document.removeEventListener(
            'keyup',
            (event) => {
                if (this.isOpen) {
                    event.stopImmediatePropagation()
                    this.closeSelectWhenEscape(event)
                }
            },
            true
        )
    },
    methods: {
        closeSelectWhenEscape(event: KeyboardEvent) {
            if (event.key === 'Escape') {
                this.closeSelect()
            }
        },
        updateKeyword(val: string) {
            if (this.hasAsyncFetch) {
                this.delayCallFetch(val)
            } else {
                this.keyword = val
                this.$emit('updateKeyword', val)
            }
        },
        delayCallFetch: _.debounce(function fetchDebounce(
            this: any,
            val: string
        ) {
            this.keyword = val
            this.$emit('updateKeyword', val)
        },
        500),
        getTamponCurrentValue() {
            const _options = this.displayOptions
            const currentOpt = _options.find((i) => i.id === this.value)
            if (currentOpt) {
                this.tamponCurrentValue = currentOpt
            } else {
                this.tamponCurrentValue = undefined
            }
        },
        onSelect(option: OptionChargemapSelect) {
            if (!option.blocked) {
                if (this.multiple) {
                    if (Array.isArray(this.value)) {
                        const value = this.value as string[]
                        const index = value.indexOf(option.id as string)
                        if (index === -1) {
                            value.push(option.id as string)
                        } else {
                            value.splice(index, 1)
                        }
                        this.$emit('input', value)
                    }
                } else {
                    this.closeSelect()
                    const value = option.id || option.value
                    this.$emit('input', value)
                }
            }
        },
        toggleOpen() {
            if (!this.isDisabled) {
                this.isOpen = !this.isOpen
            }
        },
        closeSelect() {
            this.isOpen = false
        },
        clearValue() {
            this.$emit('input', '')
        }
    }
})
