import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'

// @ts-ignore
import IconStatus from '@/components/UI/icons/IconStatus.vue'

const localVue = createLocalVue()
localVue.use(Vuex)

describe('components/UI/IconCharging.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(IconStatus, {
            localVue
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
    })
})
