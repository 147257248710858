var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"transition-transform ease-in-out duration-700 flex",style:({
            height: `${_vm.fakeDivHeight}px !important`,
            width: `${_vm.fakeDivWidth}px !important`
        })}),_vm._v(" "),_c('div',{ref:"drawer",staticClass:"mobile-drawer absolute flex flex-col gap-2 ease-in-out bottom-0 duration-700 z-50 bg-white border-t border-slate-300 overflow-hidden",class:[
            _vm.drawerVisible ? 'pointer-events-auto' : 'pointer-events-none',
            _vm.isResizing ? 'resizing transition-none' : 'transition-all',
            _vm.isFullSize ? 'h-full' : 'h-auto',
            _vm.animateSize ? 'transition-all' : 'transition-none',
            !_vm.isFullSize && !_vm.isDesktopDrawer
                ? 'rounded-t-xl'
                : '!rounded-t-none',
            _vm.isDesktopDrawer ? '!absolute !border-r' : ''
        ],style:({
            width: _vm.isDesktopDrawer ? `${_vm.drawerWidth}px` : '100%',
            height:
                _vm.isDesktopDrawer || (_vm.isFullSize && _vm.visible)
                    ? '100% !important'
                    : _vm.drawerHeight + 'px !important',
            ..._vm.drawerPositionStyle
        }),attrs:{"data-testid":"chargemap-drawer"},on:{"transitionstart":_vm.animationStart}},[_c('div',{staticClass:"resize-handle justify-center",class:[
                {
                    hidden: _vm.isDesktopDrawer
                },
                _vm.isFullSize ? 'hidden' : 'flex'
            ],on:{"mousedown":_vm.startResize,"dblclick":_vm.closeDrawer,"touchstart":_vm.startResize}}),_vm._v(" "),_c('div',{staticClass:"sticky top-0 w-12 ml-auto pointer-events-none flex-shrink-0 transition-all ease-in-out duration-500 mr-auto bg-black bg-opacity-10 rounded-2xl mt-2",class:[
                _vm.isFullSize ? 'h-0 -pb-2' : 'h-1.5',
                { hidden: _vm.isDesktopDrawer }
            ]}),_vm._v(" "),_c('div',{staticClass:"w-full flex-col inline-flex relative overflow-hidden h-full",class:[_vm.isResizing ? 'resizing' : '']},[(_vm.title)?_c('TemporaryDrawerTitleBar',{attrs:{"show-close-button":_vm.drawCloseButton,"title":_vm.title},on:{"closeDrawer":_vm.closeDrawer}}):_vm._e(),_vm._v(" "),_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }