
import Vue from 'vue'

export default Vue.extend({
    props: {
        fromTemplate: {
            type: Boolean,
            default: false
        }
    }
})
