
import Vue from 'vue'

export default Vue.extend({
    name: 'AppDropdown',
    props: {
        isHeader: {
            type: Boolean,
            default: false
        },
        position: {
            type: String,
            default: 'left',
            validator(value: string) {
                return ['left', 'right'].includes(value)
            }
        }
    },
    methods: {
        toggleDropdown(slotProps: any, shouldUpdateRef: boolean = false) {
            if (slotProps) {
                slotProps.toggleShowList()
                if (shouldUpdateRef) {
                    if (this.isHeader) {
                        // @ts-ignore
                        slotProps.setButtonRef(this.$refs.buttonRef?.$el)
                    } else {
                        slotProps.setButtonRef(this.$refs.buttonRef)
                    }
                }
            }
        }
    }
})
