import axios, { AxiosError, AxiosRequestConfig } from 'axios'

export default function ({
    $axios,
    $sentry,
    $auth
}: {
    $axios: any
    $sentry: any
    $auth: any
}) {
    const statusCodeToIgnore = ['404', '403', '401', '400', '409', '502']

    $axios.onRequest((_config: AxiosRequestConfig) => {
        $axios.setHeader('X-Frame-Options', 'SAMEORIGIN')
        $axios.setHeader('X-Content-Type-Options', 'nosniff')
        $axios.setHeader('X-XSS-Protection', '1; mode=block')
    })

    $axios.onError((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
            const code = error.response.status
            const comeFromRefreshToken =
                error?.config?.url === '/cloudapi/tokens/refresh'
            const hasTokenExpired = $auth?.strategy?.token?.status()?.expired()
            const hasRefreshToken = $auth?.strategy?.refreshToken
                ?.status()
                ?.valid()
            const canRefreshRights =
                !comeFromRefreshToken &&
                code === 401 &&
                hasRefreshToken &&
                hasTokenExpired
            if (canRefreshRights) {
                return $auth.refreshTokens().then((_: any) => {
                    const originalRequest = error.config
                    if (originalRequest.headers) {
                        originalRequest.headers.Authorization = `${$auth.strategy.token.get()}`
                    }
                    return $axios(originalRequest)
                })
            }
        }
    })
    $axios.onResponseError((error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
            const { response } = error
            const urlApi = error?.config?.url || 'Unknown'
            const statusCode = response?.status || 'No Status'

            const tokenStatus = $auth?.strategy?.token?.status()._status
            const refreshTokenStatus =
                $auth?.strategy?.refreshToken?.status()._status
            const userId = $auth.user?.id || 'Unknown'
            const dataResponse = response?.data
                ? JSON.stringify(response.data, null, 4)
                : 'No data provided'
            const data = {
                name: error.name,
                message: error.message,
                urlApi,
                statusCode,
                tokenStatus,
                refreshTokenStatus,
                userId,
                dataResponse
            }

            error.name = 'Error API'
            error.message = `[Status: ${statusCode}]: ${urlApi}`

            // We do not send when error code is 400, 401, 403, 404,...
            if (!statusCodeToIgnore.includes(statusCode.toString())) {
                $sentry.captureException(error, {
                    tags: {
                        section: 'API_ERROR'
                    },
                    extra: data
                })
            }

            error.name = 'Error API AFTER SENTRY'
            return
        }

        // a non axios error could also have a status
        const status = (error as any)?.response.status || null

        // We do not send when error code is 400, 401, 403, 404,...
        if (
            error.message !==
                'Both token and refresh token have expired. Your request was aborted.' &&
            (!status || !statusCodeToIgnore.includes(status.toString()))
        ) {
            $sentry.captureException(error)
        }
    })
}
