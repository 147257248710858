import { ref } from 'vue'
import { defineStore } from 'pinia'
import { DateTime } from 'luxon'
import { Bill } from '@/interfaces/bill'
import { ExportType } from '@/interfaces/other'
import { BillAndExpenseReportsFilter } from '@/interfaces/filters'

const useBillStore = defineStore('bill', () => {
    const bills = ref([] as Bill[])
    const billsCount = ref(null as number | null)
    const billsSelected = ref([] as string[])

    const convertDate = (
        dateStartRaw: string | undefined,
        dateEndRaw: string | undefined
    ) => {
        let minStartDate = null
        let maxStartDate = null
        if (!dateStartRaw) {
            return { minStartDate, maxStartDate }
        }
        minStartDate = DateTime.fromISO(dateStartRaw).toISO()
        if (dateEndRaw) {
            maxStartDate = DateTime.fromISO(dateEndRaw)
                .plus({ days: 1 })
                .toISO()
        }
        return { minStartDate, maxStartDate }
    }

    const setBills = (newBills: Bill[]) => {
        bills.value = newBills
    }

    const setBillsCount = (count: number) => {
        billsCount.value = count
    }

    const fetchBills = (
        context: any,
        payload: {
            idOrga: string | undefined
            idBU: string | undefined
            filters: BillAndExpenseReportsFilter
        }
    ) => {
        const { keyword, dateRange, state, pages, orderColumn, orderType } =
            payload.filters
        const { index, perPage } = pages

        const { idOrga, idBU } = payload
        const offset = (index - 1) * perPage || (0 as number)
        const limit = perPage || (20 as number)
        let params = {
            offset,
            limit,
            keyword,
            min_start_date: null as string | null,
            max_start_date: null as string | null,
            state,
            order_by: 'date,desc'
        }

        if (dateRange) {
            const { minStartDate, maxStartDate } = convertDate(
                dateRange[0],
                dateRange[1]
            )
            if (minStartDate) {
                params = { ...params, min_start_date: minStartDate }
            }
            if (maxStartDate) {
                params = { ...params, max_start_date: maxStartDate }
            }
        }

        if (orderType && orderColumn) {
            params = {
                ...params,
                order_by: `${orderColumn},${orderType}`
            }
        }

        let url = '/cloudapi'
        if (idOrga) {
            url += `/organizations/${idOrga}`
        }
        if (idBU) {
            url += `/business-units/${idBU}`
        }
        url += '/invoices'
        return context.$axios
            .$get(url, { params })
            .then((res: any) => {
                const result = context.$toCamel(res)
                setBills(result.invoices)
                setBillsCount(result.count)

                return result
            })
            .catch((e: any) => {
                throw e
            })
    }

    const fetchBillExport = (
        context: any,
        payload: {
            idOrga: number | null
            idBU: number | null
            invoiceNo: string | undefined
            type: ExportType | undefined
        }
    ) => {
        const { idOrga, idBU, invoiceNo, type } = payload

        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/invoices/${invoiceNo}/export?file_type=${type}`
        const call = context.$axios
            .get(url)
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
        return call
    }

    const fetchBatchBillExport = (
        context: any,
        payload: {
            idOrga: string | undefined
            idBU: string | undefined
            invoicesIds: number[] | undefined
            type: ExportType | undefined
        }
    ) => {
        const { idOrga, idBU, invoicesIds, type } = payload
        let url = '/cloudapi'
        if (idOrga) {
            url += `/organizations/${idOrga}`
        }
        if (idBU) {
            url += `/business-units/${idBU}`
        }

        return context.$axios
            .get(`${url}/invoices/batch-export`, {
                params: {
                    invoices_nos: billsSelected.value,
                    invoices_ids: invoicesIds,
                    file_type: type
                }
            })
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    }

    return {
        convertDate,
        setBills,
        setBillsCount,
        fetchBills,
        fetchBillExport,
        fetchBatchBillExport,
        bills,
        billsCount,
        billsSelected
    }
})

type BillStore = Omit<
    ReturnType<typeof useBillStore>,
    keyof ReturnType<typeof defineStore>
>

export default useBillStore

export type { BillStore }
