
import Vue from 'vue'
import IconChevronDown from '~/components/UI/icons/input/IconChevronDown.vue'

export default Vue.extend({
    name: 'AppAccordion',
    components: {
        IconChevronDown
    },
    props: {
        title: {
            type: String,
            required: true
        },
        triggerHeightCalculation: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            open: false,
            childHeight: 0,
            header: this.$refs.accordionHeader as HTMLElement | null
        }
    },
    watch: {
        triggerHeightCalculation(val) {
            if (val && this.open) {
                this.heightCalculation()
            }
        },
        open(val) {
            if (val) {
                setTimeout(() => {
                    this.header?.nextElementSibling?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }, 300)
            }
        }
    },
    mounted() {
        this.header = this.$refs.accordionHeader as HTMLElement
    },
    methods: {
        heightCalculation(this: any) {
            if (!this.header) {
                return
            }
            const content: HTMLElement = this.header
                .nextElementSibling as HTMLElement

            this.childHeight = content.scrollHeight
        },
        toggleAccordion(this: any) {
            this.heightCalculation()
            this.open = !this.open
        }
    }
})
