import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'

// @ts-ignore
import fr from 'vue2-datepicker/locale/fr.js'
// @ts-ignore
import de from 'vue2-datepicker/locale/de.js'
// @ts-ignore
import en from 'vue2-datepicker/locale/en.js'
import basicMountWrapper from '~/utilitary/mountWrapper'

// @ts-ignore
import ChargemapDatepicker from '@/components/UI/CMUI/ChargemapDatepicker.vue'

const localVue = createLocalVue()
localVue.use(Vuex)

const props = {
    value: '2021',
    type: 'date',
    format: 'DD/MM/YYYY',
    isRange: false,
    placeholder: 'placeholder',
    clearable: false,
    valueType: 'YYYY-MM-DD',
    lang: 'fr'
}

describe('components/UI/CMUI/ChargemapDatepicker.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(ChargemapDatepicker, {
            ...basicMountWrapper,
            localVue,
            propsData: props
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="chargemap-datepicker"]')

        expect(component.exists()).toBeTruthy()
    })

    describe('computed', () => {
        it('can update lang', async () => {
            expect(wrapper.vm.getLang).toEqual(fr)

            await wrapper.setProps({
                lang: 'de'
            })

            expect(wrapper.vm.getLang).toEqual(de)

            await wrapper.setProps({
                lang: 'en'
            })

            expect(wrapper.vm.getLang).toEqual(en)
        })

        it('can return local format', async () => {
            expect(wrapper.vm.getLocalFormat).toBe('DD/MM/YYYY')

            await wrapper.setProps({
                lang: 'de'
            })

            expect(wrapper.vm.getLocalFormat).toBe('DD.MM.YYYY')

            await wrapper.setProps({
                lang: 'en'
            })

            expect(wrapper.vm.getLocalFormat).toBe('YYYY-MM-DD')
        })
    })
})
