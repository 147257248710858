import { createLocalVue, shallowMount } from '@vue/test-utils'

// @ts-ignore
import AppTitle from '@/components/UI/AppTitle.vue'
import router from '~/test/mocks/router.mock'

// Mocks
import basicMountWrapper from '~/utilitary/mountWrapper'
import useMobileFilter from '~/store/mobileFilter'

const localVue = createLocalVue()

const menu = {
    $emit: jest.fn()
}

describe('components/UI/AppTitle.vue', () => {
    let wrapper: any
    let mobileFilterStore: any

    beforeEach(() => {
        wrapper = shallowMount(AppTitle, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks,
                $menu: menu
            }
        })

        mobileFilterStore = useMobileFilter()
        mobileFilterStore.filters = []
        mobileFilterStore.showBottomSheet = false
        mobileFilterStore.showSearchBar = false
        mobileFilterStore.hasSearch = false
        mobileFilterStore.toUpdate = false
    })

    afterEach(() => {
        jest.clearAllMocks()
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
    })

    it('emit toggle menu', async () => {
        expect(menu.$emit).not.toHaveBeenCalled()
        await wrapper.vm.toggleMobileMenu()
        expect(menu.$emit).toHaveBeenCalledWith('toggle')
    })

    it('call toggle bottom sheet', async () => {
        const spyFunctionCall = jest.spyOn(wrapper.vm, 'toggleBottomSheet')
        await wrapper.vm.toggleBottomSheet()
        expect(spyFunctionCall).toHaveBeenCalled()
    })

    it('call toggle search', async () => {
        const spyFunctionCall = jest.spyOn(wrapper.vm, 'toggleSearch')
        await wrapper.vm.toggleSearch()
        expect(spyFunctionCall).toHaveBeenCalled()
    })

    describe('goBack', () => {
        it('use browser back', () => {
            wrapper.vm.goBack()
            expect(router.go).toHaveBeenCalledWith(-1)
        })
    })
})
