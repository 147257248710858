export default function ({ route, store, redirect, $auth }) {
    const hasRefreshToken = $auth?.strategy?.refreshToken?.status()?.valid()
    if (store.getters['session/getUser'] && hasRefreshToken) {
        if (!store.getters['session/isAdmin']) {
            const homePage = store.getters['session/getHomepage']
            if (route.path !== homePage) {
                return redirect(homePage)
            }
        }
    } else {
        return store
            .dispatch('session/logout')
            .finally(() => redirect('/login#session-redirect'))
    }
}
