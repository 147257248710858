
import DOMESTIC_TYPE_A from '@/components/UI/icons/connectors/types/DOMESTIC_TYPE_A.vue'
import DOMESTIC_TYPE_B from '@/components/UI/icons/connectors/types/DOMESTIC_TYPE_B.vue'
import DOMESTIC_TYPE_C from '@/components/UI/icons/connectors/types/DOMESTIC_TYPE_C.vue'
import DOMESTIC_TYPE_D from '@/components/UI/icons/connectors/types/DOMESTIC_TYPE_D.vue'
import DOMESTIC_TYPE_E from '@/components/UI/icons/connectors/types/DOMESTIC_TYPE_E.vue'
import DOMESTIC_TYPE_F from '@/components/UI/icons/connectors/types/DOMESTIC_TYPE_F.vue'
import DOMESTIC_TYPE_G from '@/components/UI/icons/connectors/types/DOMESTIC_TYPE_G.vue'
import DOMESTIC_TYPE_H from '@/components/UI/icons/connectors/types/DOMESTIC_TYPE_H.vue'
import DOMESTIC_TYPE_I from '@/components/UI/icons/connectors/types/DOMESTIC_TYPE_I.vue'
import DOMESTIC_TYPE_J from '@/components/UI/icons/connectors/types/DOMESTIC_TYPE_J.vue'
import DOMESTIC_TYPE_K from '@/components/UI/icons/connectors/types/DOMESTIC_TYPE_K.vue'
import DOMESTIC_TYPE_L from '@/components/UI/icons/connectors/types/DOMESTIC_TYPE_L.vue'
import DOMESTIC_TYPE_M from '@/components/UI/icons/connectors/types/DOMESTIC_TYPE_M.vue'
import MENNEKES_TYPE_2 from '@/components/UI/icons/connectors/types/MENNEKES_TYPE_2.vue'
import MENNEKES_TYPE_2_CABLE_ATTACHED from '@/components/UI/icons/connectors/types/MENNEKES_TYPE_2_CABLE_ATTACHED.vue'
import TYPE_3A from '@/components/UI/icons/connectors/types/TYPE_3A.vue'
import TYPE_3C from '@/components/UI/icons/connectors/types/TYPE_3C.vue'
import TYPE_1 from '@/components/UI/icons/connectors/types/TYPE_1.vue'
import CEE_BLUE from '@/components/UI/icons/connectors/types/CEE_BLUE.vue'
import CEE_RED from '@/components/UI/icons/connectors/types/CEE_RED.vue'
import CHADEMO from '@/components/UI/icons/connectors/types/CHADEMO.vue'
import NEMA_5_20 from '@/components/UI/icons/connectors/types/NEMA_5_20.vue'
import AVCON from '@/components/UI/icons/connectors/types/AVCON.vue'
import LARGE_PADDLE_INDUCTIVE from '@/components/UI/icons/connectors/types/LARGE_PADDLE_INDUCTIVE.vue'
import SMALL_PADDLE_INDUCTIVE from '@/components/UI/icons/connectors/types/SMALL_PADDLE_INDUCTIVE.vue'
import COMBO_TYPE_2 from '@/components/UI/icons/connectors/types/COMBO_TYPE_2.vue'
import COMBO_TYPE_1 from '@/components/UI/icons/connectors/types/COMBO_TYPE_1.vue'
import CHINA_GB_PART_2 from '@/components/UI/icons/connectors/types/CHINA_GB_PART_2.vue'
import CHINA_GB_PART_3 from '@/components/UI/icons/connectors/types/CHINA_GB_PART_3.vue'
import MARECHAL from '@/components/UI/icons/connectors/types/MARECHAL.vue'
import IEC_309_DC from '@/components/UI/icons/connectors/types/IEC_309_DC.vue'
import T23 from '@/components/UI/icons/connectors/types/T23.vue'
import T15 from '@/components/UI/icons/connectors/types/T15.vue'
import T25 from '@/components/UI/icons/connectors/types/T25.vue'
import TESLA_TYPE_2 from '@/components/UI/icons/connectors/types/TESLA_TYPE_2.vue'
import TESLA_ROADSTER from '@/components/UI/icons/connectors/types/TESLA_ROADSTER.vue'
import TESLA_SUPERCHARGER_EU from '@/components/UI/icons/connectors/types/TESLA_SUPERCHARGER_EU.vue'
import TESLA_COMBO_CCS from '@/components/UI/icons/connectors/types/TESLA_COMBO_CCS.vue'
import UNKOWN from '@/components/UI/icons/connectors/types/UNKOWN.vue'

const connectorList = [
    'DOMESTIC_TYPE_A',
    'DOMESTIC_TYPE_B',
    'DOMESTIC_TYPE_C',
    'DOMESTIC_TYPE_D',
    'DOMESTIC_TYPE_E',
    'DOMESTIC_TYPE_F',
    'DOMESTIC_TYPE_G',
    'DOMESTIC_TYPE_H',
    'DOMESTIC_TYPE_I',
    'DOMESTIC_TYPE_J',
    'DOMESTIC_TYPE_K',
    'DOMESTIC_TYPE_L',
    'DOMESTIC_TYPE_M',
    'MENNEKES_TYPE_2',
    'MENNEKES_TYPE_2_CABLE_ATTACHED',
    'TYPE_3A',
    'TYPE_3C',
    'TYPE_1',
    'CEE_BLUE',
    'CEE_RED',
    'CHADEMO',
    'NEMA_5_20',
    'AVCON',
    'LARGE_PADDLE_INDUCTIVE',
    'SMALL_PADDLE_INDUCTIVE',
    'COMBO_TYPE_2',
    'COMBO_TYPE_1',
    'CHINA_GB_PART_2',
    'CHINA_GB_PART_3',
    'MARECHAL',
    'IEC_309_DC',
    'T23',
    'T15',
    'T25',
    'TESLA_TYPE_2',
    'TESLA_ROADSTER',
    'TESLA_SUPERCHARGER_EU',
    'TESLA_COMBO_CCS',
    'UNKOWN'
]
export default {
    name: 'IconConnector',
    components: {
        DOMESTIC_TYPE_A,
        DOMESTIC_TYPE_B,
        DOMESTIC_TYPE_C,
        DOMESTIC_TYPE_D,
        DOMESTIC_TYPE_E,
        DOMESTIC_TYPE_F,
        DOMESTIC_TYPE_G,
        DOMESTIC_TYPE_H,
        DOMESTIC_TYPE_I,
        DOMESTIC_TYPE_J,
        DOMESTIC_TYPE_K,
        DOMESTIC_TYPE_L,
        DOMESTIC_TYPE_M,
        MENNEKES_TYPE_2,
        MENNEKES_TYPE_2_CABLE_ATTACHED,
        TYPE_3A,
        TYPE_3C,
        TYPE_1,
        CEE_BLUE,
        CEE_RED,
        CHADEMO,
        NEMA_5_20,
        AVCON,
        LARGE_PADDLE_INDUCTIVE,
        SMALL_PADDLE_INDUCTIVE,
        COMBO_TYPE_2,
        COMBO_TYPE_1,
        CHINA_GB_PART_2,
        CHINA_GB_PART_3,
        MARECHAL,
        IEC_309_DC,
        T23,
        T15,
        T25,
        TESLA_TYPE_2,
        TESLA_ROADSTER,
        TESLA_SUPERCHARGER_EU,
        TESLA_COMBO_CCS,
        UNKOWN
    },
    props: {
        color: {
            type: String,
            default() {
                return 'gray'
            },
            validator(value) {
                return [
                    'gray',
                    'black',
                    'red',
                    'yellow',
                    'orange',
                    'green',
                    'blue',
                    'indigo',
                    'purple',
                    'pink',
                    'chargemap'
                ].includes(value)
            }
        },
        connectorType: {
            type: String,
            default: 'UNKOWN',
            validator(value) {
                return connectorList.includes(value)
            }
        }
    }
}
