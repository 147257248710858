import { render, staticRenderFns } from "./IconConnectorStatusSUSPENDED_EV.vue?vue&type=template&id=12b663d5"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports