var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-span-full"},[_c('div',{staticClass:"overflow-hidden relative"},[_c('div',{staticClass:"carousel-inner relative w-full overflow-hidden"},_vm._l((_vm.slides),function(item,index){return _c('div',{key:index,staticClass:"carousel-item relative float-left w-full",class:{
                    active: index === _vm.currentIndex,
                    'carousel-item-start': item.showClassStart,
                    'carousel-item-end': item.showClassEnd,
                    'carousel-item-next': item.showClassNext,
                    'carousel-item-prev': item.showClassPrev
                },attrs:{"id":`slide-${index}`}},[_c(item.img,{tag:"component",staticClass:"block w-full",attrs:{"alt":"First slide"}})],1)}),0),_vm._v(" "),_c('div',{staticClass:"flex justify-center items-center mt-6 mb-6 min-h-2"},_vm._l((_vm.slides),function(slide,indexSlide){return _c('span',{key:slide.id,staticClass:"carousel-dots w-12 rounded-full mx-1",class:{
                    'bg-blue-500': indexSlide === _vm.currentIndex,
                    'bg-gray-200': indexSlide !== _vm.currentIndex,
                    'h-2': indexSlide === _vm.currentIndex,
                    active: indexSlide === _vm.currentIndex,
                    'carousel-dot-start': slide.showClassStart,
                    'carousel-dot-end': slide.showClassEnd,
                    'h-1.5': indexSlide !== _vm.currentIndex
                }})}),0),_vm._v(" "),_c('div',{staticClass:"carousel-inner relative w-full overflow-hidden mb-11 min-h-34"},_vm._l((_vm.slides),function(item,index){return _c('div',{key:index,staticClass:"carousel-item relative float-left w-full",class:{
                    active: index === _vm.currentIndex,
                    'carousel-item-start': item.showClassStart,
                    'carousel-item-end': item.showClassEnd,
                    'carousel-item-next': item.showClassNext,
                    'carousel-item-prev': item.showClassPrev
                },attrs:{"id":`slide-${index}`}},[_c('div',{staticClass:"carousel-text"},[_c('h2',{staticClass:"font-bold text-lg text-dark-600 mb-2"},[_vm._v("\n                        "+_vm._s(_vm.$tc(item.title))+"\n                    ")]),_vm._v(" "),_c('p',{staticClass:"text-base text-gray-600 leading-tight h-16"},[_c('i18n',{attrs:{"path":item.body,"tag":"div"},scopedSlots:_vm._u([{key:"br",fn:function(){return [_c('br')]},proxy:true},{key:"strong",fn:function(){return [_c('strong',[_vm._v("\n                                    "+_vm._s(_vm.$tc('tesla.modal.slide2.strong'))+"\n                                ")])]},proxy:true}],null,true)})],1)])])}),0),_vm._v(" "),_c('button',{staticClass:"button-chevrons ml-5 left-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline",attrs:{"type":"button"},on:{"click":function($event){return _vm.changeSlide('prev')}}},[_c('AppChevronLeft')],1),_vm._v(" "),_c('button',{staticClass:"button-chevrons mr-5 right-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline",attrs:{"type":"button"},on:{"click":function($event){return _vm.changeSlide('next')}}},[_c('AppChevronRight')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }