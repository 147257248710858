
import Vue from 'vue'

export default Vue.extend({
    name: 'AppCheckboxInput',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: ''
        },
        labelKey: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            inputID: ''
        }
    },
    mounted() {
        this.inputID = this.id || this.generateRandomId()
    },
    methods: {
        generateRandomId() {
            return Math.random().toString(8)
        },
        onChange(event: Event) {
            const target = event.target as HTMLInputElement
            this.$emit('change', target.checked)
        },
        onInput(event: Event) {
            const target = event.target as HTMLInputElement
            this.$emit('input', target.checked)
        }
    }
})
