import { createLocalVue, shallowMount } from '@vue/test-utils'

// @ts-ignore
import AppInput from '@/components/UI/AppInput.vue'

const localVue = createLocalVue()

const props = {
    value: '',
    isValid: true,
    unit: '',
    errorMessage: '',
    disabled: false,
    placeholder: ''
}
describe('components/UI/AppInput.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppInput, {
            localVue,
            propsData: props
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="app-input"]')
        expect(component.exists()).toBeTruthy()
    })

    describe('computed', () => {
        it('can return hasValue', async () => {
            expect(wrapper.vm.hasValue).toBeFalsy()

            await wrapper.setProps({
                value: 'test'
            })
            expect(wrapper.vm.hasValue).toBeTruthy()
        })

        it('can return getSizeInputText', () => {
            expect(wrapper.vm.getSizeInputText).toBe(0)

            wrapper.vm.canvas = undefined
            expect(wrapper.vm.getSizeInputText).toBe(0.1)
        })

        it('can return calculatePositionLeft', () => {
            expect(wrapper.vm.calculatePositionLeft).toBe(1)

            wrapper.vm.canvas = undefined
            expect(wrapper.vm.calculatePositionLeft).toBe(1.1)
        })

        it('can show Eye Icon', async () => {
            expect(wrapper.vm.canShowEyeIcon).toBeFalsy()

            await wrapper.setProps({
                isPassword: true,
                isValid: true,
                value: 'test'
            })

            expect(wrapper.vm.canShowEyeIcon).toBeTruthy()
        })

        it('can show search icon', async () => {
            expect(wrapper.vm.canShowSearchIcon).toBeFalsy()

            await wrapper.setProps({
                isSearch: true,
                value: ''
            })

            expect(wrapper.vm.canShowSearchIcon).toBeTruthy()

            await wrapper.setProps({
                isSearch: true,
                noX: true,
                value: 'test'
            })

            expect(wrapper.vm.canShowSearchIcon).toBeTruthy()
        })

        it('can show X circle Icon', async () => {
            expect(wrapper.vm.canShowXCircleIcon).toBeTruthy()

            await wrapper.setProps({
                isPassword: false,
                isValid: false,
                value: 'test'
            })

            expect(wrapper.vm.canShowXCircleIcon).toBeTruthy()

            await wrapper.setProps({
                isPassword: false,
                isValid: false,
                noX: true,
                value: 'test'
            })

            expect(wrapper.vm.canShowXCircleIcon).toBeFalsy()

            await wrapper.setProps({
                isPassword: true,
                isValid: false,
                value: 'test'
            })

            expect(wrapper.vm.canShowXCircleIcon).toBeFalsy()
        })

        it('can return getTypeInput', async () => {
            expect(wrapper.vm.getTypeInput).toBe('text')

            await wrapper.setProps({
                isPassword: true,
                isRevealed: false
            })

            expect(wrapper.vm.getTypeInput).toBe('password')

            await wrapper.setProps({
                isPassword: false,
                isRevealed: false,
                typeInput: 'EMAIL'
            })

            expect(wrapper.vm.getTypeInput).toBe('EMAIL')
        })
    })

    it('can display unit', async () => {
        let component = wrapper.find('[data-testid="app-input-unit"]')
        expect(component.exists()).toBeFalsy()

        await wrapper.setProps({
            value: 'test',
            unit: 'kg'
        })

        component = wrapper.find('[data-testid="app-input-unit"]')
        expect(component.exists()).toBeTruthy()
    })
})
