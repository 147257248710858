
export default {
    name: 'IconLoader',
    props: {
        color: {
            type: String,
            default: '',
            validator(value) {
                return ['green', 'blue', ''].includes(value)
            }
        }
    },
    computed: {
        isBlue() {
            return this.color === 'blue'
        },
        isGreen() {
            return this.color === 'green'
        }
    }
}
