var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"py-2",class:[
        _vm.fromTemplate && 'col-span-6',
        _vm.classForm,
        _vm.noBorderLine && 'border-none'
    ],attrs:{"data-testid":"app-detail-form-line"}},[(_vm.$slots.label || _vm.hasEmptyLabel)?_c('label',{staticClass:"text-sm leading-5 font-medium tracking-tight",class:[
            _vm.mode === 'inline-left' && 'w-1/4',
            _vm.mode === 'inline-switch' && 'w-2/3',
            (_vm.mode === 'inline' || _vm.mode === 'line') && 'flex-1',
            _vm.isDisabled ? 'text-gray-400' : 'text-gray-600'
        ],attrs:{"for":_vm.inputId,"data-testid":"app-detail-form-line-label"}},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-inline justify-between items-center"},[_vm._t("label"),_vm._v(" "),(_vm.isOptional)?_c('span',{staticClass:"text-gray-400 font-light text-xs",attrs:{"data-testid":"app-detail-form-line-optional"}},[_vm._v("\n                    "+_vm._s(_vm.$t('optional'))+"\n                ")]):_vm._e()],2),_vm._v(" "),(_vm.$slots.sublabel)?_c('div',{staticClass:"text-xs text-gray-400 font-normal leading-4"},[_vm._t("sublabel")],2):_vm._e()])]):_vm._e(),_vm._v(" "),_c('div',{class:[
            _vm.mode === 'inline-switch'
                ? 'w-1/3'
                : 'flex-1 flex flex-col gap-2',
            _vm.mode !== 'column' && 'flex items-center',
            _vm.mode === 'inline-switch' && 'justify-end'
        ]},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }