
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { mapState } from 'pinia'
import AppMenu from '~/components/UI/AppMenu.vue'
import TeslaBanner from '~/components/others/TeslaBanner.vue'
import TeslaModal from '~/components/others/TeslaModal.vue'
import IconCloseMenu from '~/components/UI/icons/layout/IconCloseMenu.vue'
import { User } from '~/interfaces/user'
import useFlaggyStore from '~/store/flaggy'

declare global {
    interface Window {
        FrontChat: any
    }
}

export default Vue.extend({
    components: { AppMenu, TeslaBanner, IconCloseMenu, TeslaModal },
    middleware: ['auth', 'init', 'navigation', 'flaggyConfigured'],
    data: () => ({
        heartbeatListenerId: 0,
        mobileMenuOpen: false,
        selectedMenu: 'administration',
        showTeslaModal: false
    }),

    computed: {
        ...mapGetters({
            getOrganization: 'organization/getOrganization',
            getBusinessUnit: 'businessunit/getBusinessUnit',
            isSuperAdmin: 'session/isSuperAdmin',
            isAdmin: 'session/isAdmin',
            isBUAdmin: 'session/isBUAdmin',
            isFleetManager: 'session/isFleetManager',
            isSupervisionManager: 'session/isSupervisionManager',
            getUser: 'session/getUser'
        }),
        ...mapState(useFlaggyStore, ['package']),
        user(): User {
            return this.getUser
        },
        teslaEnabled(): boolean {
            return this.$config.TESLA_ENABLED || false
        },
        isDev(): boolean {
            return this.$config.NUXT_ENV !== 'production'
        },
        currentRole(): string {
            if (this.isSuperAdmin) {
                return 'Super Admin'
            }
            if (this.isAdmin) {
                return 'Orga Admin'
            }
            if (this.isBUAdmin) {
                return 'BU Admin'
            }
            if (this.isFleetManager) {
                return 'Fleet Manager'
            }
            if (this.isSupervisionManager) {
                return 'Supervision Manager'
            }

            return 'Inconnu'
        }
    },
    watch: {
        $route(to, from) {
            // when we completely change page, we scroll to top
            if (to.path !== from.path) {
                this.$nextTick(() => {
                    ;(this.$refs.content as HTMLElement).scrollTop = 0
                })
            }

            // when we change hash, we scroll under the title
            if (to.path === from.path && to.hash !== from.hash) {
                this.$nextTick(() => {
                    if ((this.$refs.content as HTMLElement).scrollTop > 156) {
                        ;(this.$refs.content as HTMLElement).scrollTop = 156
                    }
                })
            }
        },
        user: {
            handler() {
                this.launchFrontAppChat()
            },
            deep: true,
            immediate: true
        }
    },
    async mounted(this: any) {
        this.$menu.$on('toggle', (force: boolean) => {
            if (force === false || (this.mobileMenuOpen && force !== true)) {
                this.closeMobileMenu()
            } else {
                this.mobileMenuOpen = true
            }
        })
    },
    beforeDestroy(this: any) {
        this.$menu.$off('toggle')
        clearInterval(this.heartbeatListenerId)
    },
    methods: {
        updateShowTeslaModal(value: boolean) {
            this.showTeslaModal = value
        },
        heartbeat(this: any) {
            return this.$autobahn
                .publish('staying.alive', [], undefined, undefined)
                .catch((error: any) => {
                    this.$sentry.captureException('staying.alive', error)
                })
        },
        closeMobileMenu() {
            this.mobileMenuOpen = false
        },
        switchMenu(id: string) {
            this.selectedMenu = id
        },
        goTo(location: string) {
            this.$router.push(location)
            this.mobileMenuOpen = false
        },
        launchFrontAppChat() {
            const isBrowser = typeof window !== 'undefined'
            if (!this.user?.email || !isBrowser) {
                return
            }
            try {
                this.$axios
                    .$get('/server/getInfoForFrontAppChat', {
                        params: {
                            userEmail: this.user.email
                        }
                    })
                    .then(
                        (response: {
                            userHash: string
                            frontChatID: string
                        }) => {
                            window?.FrontChat('init', {
                                chatId: response.frontChatID,
                                useDefaultLauncher: true,
                                name: this.user.fullName,
                                userHash: response.userHash,
                                email: this.user.email,
                                contact: {
                                    customFields: {
                                        bu_name: this.getBusinessUnit?.name,
                                        orga_name: this.getOrganization?.name,
                                        phone: this.user?.phone || '',
                                        package: this.package,
                                        locale: this.$i18n.locale,
                                        role: this.currentRole
                                    }
                                }
                            })
                        }
                    )
            } catch (e) {
                this.$sentry.captureMessage('Front chat error')
            }
        }
    }
})
