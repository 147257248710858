
import Vue from 'vue'
import { onClickOutside } from '@vueuse/core'

export default Vue.extend({
    name: 'ChargemapDropdown',
    props: {
        responsiveSwitch: {
            type: Boolean,
            default: false
        },
        position: {
            type: String,
            default: 'right',
            validator(value: string) {
                return ['left', 'right'].includes(value)
            }
        }
    },
    data() {
        return {
            shown: false,
            arrayPositions: [-1000, -1000],
            windowWidth: 0,
            dropdownButtonRef: null as HTMLElement | null
        }
    },
    computed: {
        isDesktop(this: any): boolean {
            return this.$device && this.$device.isDesktop
        },
        positionStyle(): number {
            let leftPosition =
                this.arrayPositions[1] -
                // @ts-ignore
                (this.$refs.dropdownRef?.clientWidth || 0)

            const buttonElement = this.dropdownButtonRef

            if (buttonElement) {
                leftPosition += buttonElement.clientWidth
            }

            switch (this.position) {
                case 'right':
                    if (this.responsiveSwitch) {
                        return this.isDesktop
                            ? this.arrayPositions[1]
                            : leftPosition
                    }
                    return this.arrayPositions[1]
                case 'left':
                    if (this.responsiveSwitch) {
                        return this.isDesktop
                            ? leftPosition
                            : this.arrayPositions[1]
                    }
                    return leftPosition
                default:
                    if (this.responsiveSwitch) {
                        return this.isDesktop
                            ? this.arrayPositions[1]
                            : leftPosition
                    }
                    return leftPosition
            }
        }
    },
    watch: {
        shown: {
            async handler(val) {
                if (this.windowWidth === 0) return

                await this.$nextTick()
                if (!window) return

                if (val) {
                    this.calculatePosition()

                    window.addEventListener('wheel', () =>
                        this.calculatePosition()
                    )

                    window.addEventListener('resize', () => {
                        this.handleResize()
                        this.calculatePosition()
                    })
                } else {
                    this.arrayPositions = [-1000, -1000]

                    window.removeEventListener('wheel', () =>
                        this.calculatePosition()
                    )

                    window.removeEventListener('resize', () => {
                        this.handleResize()
                        this.calculatePosition()
                    })
                }
            },
            immediate: true
        }
    },
    mounted() {
        if (window) this.windowWidth = window.innerWidth || 0
        onClickOutside(this.$refs.chargemapDropdownRef as any, () =>
            this.close()
        )
    },
    beforeDestroy() {
        window?.removeEventListener('wheel', () => this.calculatePosition())
        window?.removeEventListener('resize', () => {
            this.handleResize()
            this.calculatePosition()
        })
    },
    methods: {
        setSlotButtonRef(el: any) {
            this.dropdownButtonRef = el
        },
        toggleShowList() {
            this.shown = !this.shown
        },
        close() {
            this.shown = false
        },
        handleResize() {
            this.windowWidth = window?.innerWidth || 0
        },
        calculatePosition() {
            if (!this.shown) return

            const buttonElement = this.dropdownButtonRef
            if (buttonElement) {
                this.arrayPositions = buttonElement.getBoundingClientRect()
                    .bottom
                    ? [
                          buttonElement.getBoundingClientRect().bottom + 8,
                          buttonElement.getBoundingClientRect().left
                      ]
                    : [-1000, -1000]
            }
        }
    }
})
