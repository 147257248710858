
import { mapGetters } from 'vuex'
import MenuSection from '~/components/UI/menu/MenuSection.vue'

export default {
    name: 'MenuAdminSection',
    components: {
        MenuSection
    },
    emits: ['closeMobileMenu', 'selected'],
    computed: {
        ...mapGetters({
            isSuperAdmin: 'session/isSuperAdmin'
        }),
        adminSections() {
            return {
                id: 'administration',
                header: {
                    title: 'menu.admin.title',
                    icon: 'IconChargemap'
                },
                links: [
                    {
                        id: 'menu-admin-dashboard',
                        title: 'dashboard.title',
                        icon: 'IconDashboard',
                        url: '/',
                        exact: true
                    },
                    {
                        id: 'menu-admin-organizations',
                        title: 'menu.admin.organizations',
                        icon: 'IconLibrary',
                        url: '/admin/organizations'
                    },
                    {
                        id: 'menu-admin-orders',
                        title: 'menu.admin.orders',
                        icon: 'IconOrders',
                        url: '/admin/orders'
                    },
                    {
                        id: 'menu-admin-bills',
                        title: 'menu.admin.bills',
                        icon: 'IconBills',
                        url: '/admin/bills'
                    },
                    {
                        id: 'menu-chargeboxes',
                        title: 'menu.admin.chargeboxes',
                        icon: 'IconStation',
                        url: '/admin/chargeboxes'
                    },
                    {
                        id: 'menu-admin-charges',
                        title: 'menu.admin.charges',
                        icon: 'IconCharge',
                        url: '/admin/charges'
                    }
                ],
                authorized: this.isSuperAdmin
            }
        }
    },
    methods: {
        switchMenu(id) {
            this.$emit('selected', id)
        },
        closeMobileMenu() {
            this.$emit('closeMobileMenu')
        }
    }
}
