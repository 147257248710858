import { createLocalVue, shallowMount } from '@vue/test-utils'

// @ts-ignore
import AppTabs from '@/components/UI/AppTabs.vue'

const localVue = createLocalVue()
const tabs = [
    {
        id: 'first'
    },
    {
        id: 'second'
    },
    {
        id: 'third'
    }
]
describe('components/UI/AppTabs.vue', () => {
    let wrapper: any
    Element.prototype.scrollTo = jest.fn()

    beforeEach(() => {
        const mockResponse = jest.fn()
        Object.defineProperty(window, 'location', {
            value: {
                hash: {
                    endsWith: mockResponse,
                    includes: mockResponse
                },
                replace: mockResponse
            },
            writable: true
        })
        wrapper = shallowMount(AppTabs, {
            localVue,
            propsData: {
                tabs
            }
        })
    })

    it('is a Vue instance', async () => {
        expect(wrapper.vm).toBeTruthy()
        expect(wrapper.vm.selectedTab).toEqual(null)
        expect(wrapper.vm.indicators).toEqual([])
        expect(wrapper.vm.tabs).toEqual(tabs)
        await wrapper.setProps({ selectedTab: 'second' })
        expect(wrapper.vm.selectedTab).toBe('second')
    })
})
