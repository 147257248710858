import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'

// @ts-ignore
import AppLoader from '@/components/UI/AppLoader.vue'

const localVue = createLocalVue()
localVue.use(Vuex)

describe('components/UI/AppLoader.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppLoader, {
            localVue
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
    })
})
