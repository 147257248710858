
import Vue from 'vue'
import AppButton from './AppButton.vue'
import IconX from './icons/IconX.vue'

export default Vue.extend({
    name: 'TemporaryDrawerCloseButton',
    components: {
        IconX,
        AppButton
    },
    props: {
        showCloseButton: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        closeDrawer() {
            this.$emit('closeDrawer', false)
        }
    }
})
