import { User } from '~/interfaces/user'
import { organizationRoles, businessUnitRoles } from '~/interfaces/roles'
import { Organization } from '~/interfaces/organization'
import { BusinessUnit } from '~/interfaces/businessunit'
import { axiosWrapper } from '~/utilitary/storeWrapper'

const state = () => ({
    user: null,
    organizationRole: 'MEMBER',
    buRoles: [],
    homepage: '/profile'
})

const getters = {
    getUser(state: any) {
        return state.user
    },
    getEmail(state: any) {
        return state.user?.email
    },
    getHomepage(state: any) {
        return state.homepage
    },
    countOrganizations(state: any, getters: any) {
        return getters.hasUser && getters.hasOrganization
            ? state.user.rights.organizations.length
            : 0
    },
    getOrganizationRole(state: any) {
        return state.organizationRole
    },
    getBusinessUnitRoles(state: any) {
        return state.buRoles
    },
    isSuperAdmin(state: any, getters: any) {
        return getters.hasUser ? state.user.rights.superAdmin : false
    },
    isAdmin(state: any, getters: any) {
        return (
            !!getters.isSuperAdmin ||
            state.organizationRole === organizationRoles.ADMIN
        )
    },
    isBUAdmin(state: any, getters: any) {
        return (
            !!getters.isAdmin ||
            !!state.buRoles.includes(businessUnitRoles.BU_ADMIN)
        )
    },
    isFleetManager(state: any, getters: any) {
        return (
            !!getters.isBUAdmin ||
            !!state.buRoles.includes(businessUnitRoles.FLEET_MANAGER)
        )
    },
    isSupervisionManager(state: any, getters: any) {
        return (
            !!getters.isBUAdmin ||
            !!state.buRoles.includes(businessUnitRoles.SUPERVISOR)
        )
    },
    hasAccessRights(state: any, getters: any) {
        return !!getters.isAdmin || state.buRoles.length > 0
    },
    hasUser(state: any) {
        return !!state.user?.rights
    },
    hasOrganization(state: any, getters: any) {
        return getters.hasUser ? state.user.rights.organizations : false
    },
    getOrganizationsSession(state: any, getters: any) {
        return getters.hasUser && getters.hasOrganization
            ? state.user.rights.organizations
            : []
    }
}

const mutations = {
    setUser(state: any, user: User) {
        state.user = user
    },
    setHomepage(state: any, url: string | null | undefined) {
        if (url) {
            state.homepage = url
        } else {
            state.homepage = '/'
        }
    },
    setOrganizationRole(state: any, newRole: string) {
        state.organizationRole = newRole
    },
    setBusinessUnitRoles(state: any, newRoles: string[] | null) {
        state.buRoles = newRoles || []
    },
    setOrganizations(state: any, newOrganizations: Organization[]) {
        state.user.rights.organizations = newOrganizations
    },
    pushBusinessUnit(
        state: any,
        payload: { idOrga: string; newBusinessUnit: BusinessUnit }
    ) {
        const listOrga = state.user.rights.organizations
        const orga = listOrga.find(
            (o: any) => o.id === parseInt(payload.idOrga, 10)
        )
        if (orga) {
            orga.businessUnits.push(payload.newBusinessUnit)
        }
    },
    updateBusinessUnit(
        state: any,
        payload: { idOrga: string; businessUnit: BusinessUnit }
    ) {
        const listOrga = state.user.rights.organizations
        const orga = listOrga.find(
            (o: any) => o.id === parseInt(payload.idOrga, 10)
        )
        if (orga) {
            const bu = orga.businessUnits.find(
                (b: any) => b.id === payload.businessUnit.id
            )
            if (bu) {
                bu.name = payload.businessUnit.name
            }
        }
    }
}

const actions = {
    getOrganizationByBusinessUnit(vuexContext: any, businessUnitId: number) {
        const listOrga = vuexContext.state.user.rights.organizations
        const firstOrga = listOrga[0]
        let organization = null
        const BreakException = {}
        try {
            if (vuexContext.getters.isSuperAdmin) {
                organization = firstOrga
            } else {
                listOrga.forEach((o: any) => {
                    const listBu = o.businessUnits
                    const bu = listBu.find((x: any) => x.id === businessUnitId)
                    if (bu) {
                        organization = o
                        throw BreakException
                    }
                })
            }
        } catch (e) {
            if (e !== BreakException) {
                throw e
            }
        }
        return organization
    },
    getBusinessUnit(vuexContext: any, businessUnitId: number) {
        const listOrga = vuexContext.state.user.rights.organizations
        let businessUnit = null
        const BreakException = {}
        try {
            listOrga.forEach((o: any) => {
                const listBu = o.businessUnits
                const buTemp = listBu.find(
                    (bu: any) => bu.id === businessUnitId
                )
                if (buTemp) {
                    businessUnit = buTemp
                    throw BreakException
                }
            })
        } catch (e) {
            if (e !== BreakException) {
                throw e
            }
        }
        return businessUnit
    },
    updateUserRoles(vuexContext: any) {
        vuexContext.dispatch('updateOrganizationRole')
        vuexContext.dispatch('updateBusinessUnitRoles')
    },
    updateOrganizationRole(vuexContext: any) {
        const orgaRole = vuexContext.rootGetters['organization/getRole']
        vuexContext.commit('setOrganizationRole', orgaRole)
    },
    updateBusinessUnitRoles(vuexContext: any) {
        const buRoles = vuexContext.rootGetters['businessunit/getRoles']
        vuexContext.commit('setBusinessUnitRoles', buRoles)
    },
    getOrDeleteSubscriptionNewsletterUser(
        vuexContext: any,
        payload: { method: string; user: User }
    ) {
        let userObject = payload.user
        if (!userObject) userObject = (this as any).$auth.user

        const url = '/cloudapi/newsletter/subscription'
        return axiosWrapper
            .bind(this)(payload.method, url)
            .then(() => {
                if (payload.method === 'delete') {
                    const newUser = {
                        ...userObject,
                        isSubscribedToNewsletter: false
                    }
                    vuexContext.commit('setUser', newUser)
                }
            })
    },
    subscribedUserToNewsletter(vuexContext: any) {
        let userObject = vuexContext.state.user
        if (!userObject) userObject = (this as any).$auth.user

        const url = '/cloudapi/newsletter/subscription'
        return axiosWrapper
            .bind(this)('post', url, {
                token: (this as any).$auth.strategy.token.get()
            })
            .then(() => {
                const newUser = {
                    ...userObject,
                    isSubscribedToNewsletter: true
                }
                vuexContext.commit('setUser', newUser)
            })
    },
    async setUpUser(
        vuexContext: any,
        data: { user: any; rights: any; token: any; refresh_token: any }
    ) {
        vuexContext.commit('organization/setOrganization', null, {
            root: true
        })
        vuexContext.commit('businessunit/setBusinessUnit', null, {
            root: true
        })
        const user = (this as any).$toCamel(data.user)
        await vuexContext
            .dispatch('user/getUserColor', user.id, {
                root: true
            })
            .then((res: any) => {
                user.color = res
            })

        // GET INFO ABOUT NEWSLETTER SUBSCRIPTION
        user.isSubscribedToNewsletter = false
        await vuexContext
            .dispatch(
                'session/getOrDeleteSubscriptionNewsletterUser',
                { method: 'get', user },
                {
                    root: true
                }
            )
            .then(() => {
                user.isSubscribedToNewsletter = true
            })
            .catch(() => {})

        user.rights = (this as any).$toCamel(data.rights)
        user.fullName = `${user.firstName} ${user.lastName}`
        await (this as any).$auth.setUser(user)
        await (this as any).$auth.$storage.setUniversal('user', user)

        if (user.locale) {
            ;(this as any).$i18n.setLocale(user.locale.toLowerCase())
        }

        if (data.token && data.refresh_token) {
            await (this as any).$auth.setUserToken(
                data.token.value,
                data.refresh_token.value
            )
        }

        vuexContext.commit('setUser', user)
        return user
    },
    loadUser(vuexContext: any) {
        let { user } = (this as any).$auth
        if (!user?.id) {
            if (
                process.server &&
                (this as any).$auth.$storage.getCookie('user')
            ) {
                user = (this as any).$auth.$storage.getCookie('user')
            } else {
                return (this as any).$auth
                    .refreshTokens()
                    .then((res: any) =>
                        vuexContext.dispatch('setUpUser', res.data)
                    )
                    .catch(() => {
                        vuexContext.dispatch('logout')
                    })
            }

            ;(this as any).$auth.setUser(user)
            ;(this as any).$i18n.setLocale(user.locale.toLowerCase())
            vuexContext.commit('setUser', user)
        }
    },
    initSession(vuexContext: any, route: any) {
        const organizationId = route?.params.idOrga
        const idBU: string | null = route?.params.idBU || null
        return new Promise((resolve) => {
            let homepageURL: string | null = null
            vuexContext
                .dispatch(
                    'organization/switchOrganization',
                    { organizationId, idBU },
                    { root: true }
                )
                .then(() => {
                    const organization =
                        vuexContext.rootGetters['organization/getOrganization']
                    if (!vuexContext.getters.isSuperAdmin && organization) {
                        const BU =
                            vuexContext.rootGetters[
                                'businessunit/getBusinessUnit'
                            ]
                        if (vuexContext.getters.isAdmin) {
                            homepageURL = `/organizations/${organization.id}`
                            if (
                                organization?.businessUnits?.length === 1 &&
                                BU
                            ) {
                                homepageURL += `/business-units/${BU.id}`
                            }
                            homepageURL += '/dashboard'
                        } else if (vuexContext.getters.isBUAdmin) {
                            homepageURL = `/organizations/${organization.id}`
                            if (organization.businessUnits.length === 1 && BU) {
                                homepageURL += `/business-units/${BU.id}/dashboard`
                            } else if (
                                organization.businessUnits.length > 0 &&
                                organization.businessUnits[0]?.id
                            ) {
                                homepageURL += `/business-units/${organization.businessUnits[0]?.id}/dashboard`
                            } else {
                                homepageURL += `/business-units`
                            }
                        } else if (vuexContext.getters.isFleetManager && BU) {
                            homepageURL = `/organizations/${organization.id}/business-units/${BU.id}/dashboard`
                        } else if (
                            vuexContext.getters.isSupervisionManager &&
                            BU
                        ) {
                            homepageURL = `/organizations/${organization.id}/business-units/${BU.id}/dashboard`
                        } else {
                            homepageURL = '/profile'
                        }
                    } else if (vuexContext.getters.isSuperAdmin) {
                        homepageURL = '/'
                    } else {
                        homepageURL = '/profile'
                    }
                })
                .catch(() => {
                    homepageURL = null
                })
                .finally(() => {
                    vuexContext.commit('setHomepage', homepageURL)
                    resolve(homepageURL)
                })
        })
    },
    logout(this: any, _: any) {
        this.$auth.$storage.removeUniversal('user')
        this.$auth.strategy.token.reset()
        this.$auth.strategy.refreshToken.reset()
    }
}

export default {
    namespaced: true as true,
    state,
    getters,
    mutations,
    actions
}
