import { DateTime } from 'luxon'
import { defineStore } from 'pinia'
import { keysToCamel } from '~/plugins/json/utils'
import { AdminStatistics } from '~/interfaces/admin'

const useAdminStore = defineStore('admin', {
    actions: {
        fetchStatistics(context: any, untilMonth = null as number | null) {
            const stats: AdminStatistics = {
                passes: {},
                revenues: {},
                chartSeriesCumulative: [],
                chartSeriesMonthly: []
            }
            const currentDate = DateTime.now()
            return this.fetchPassesActivations(context)
                .then(async (passes: any) => {
                    stats.passes = passes
                    return this.fetchRevenues(context)
                })
                .then((revenues: any) => {
                    const decimalMoney = 100
                    stats.revenues = revenues

                    if (stats.revenues && stats.revenues.currentPeriodRevenue) {
                        // Remove future empty months
                        stats.revenues.currentPeriodRevenue.revenues =
                            stats.revenues.currentPeriodRevenue.revenues.slice(
                                0,
                                untilMonth || currentDate.month
                            )
                        const serieCumulativeCurrentRevenues = {
                            data: [] as any[],
                            name: stats.revenues.currentPeriodRevenue.year.toString()
                        }

                        const serieCumulativePrevisionsRevenues = {
                            data: [] as any[],
                            name: 'Prevision'
                        }
                        const serieMonthlyCurrentRevenues = {
                            data: [] as any[],
                            name: stats.revenues.currentPeriodRevenue.year.toString()
                        }
                        const serieCumulativePreviousRevenues = {
                            data: [] as any[],
                            name: ''
                        }
                        const serieMonthlyPreviousRevenues = {
                            data: [] as any[],
                            name: ''
                        }
                        if (stats.revenues.previousPeriodRevenue) {
                            serieCumulativePreviousRevenues.name =
                                stats.revenues.previousPeriodRevenue.year.toString()
                            serieMonthlyPreviousRevenues.name =
                                stats.revenues.previousPeriodRevenue.year.toString()
                        }

                        // Create data for charts
                        stats.revenues.currentPeriodRevenue.revenues.forEach(
                            (current, index) => {
                                let currentCumulativeRevenue =
                                    current.cumulativeRevenue / decimalMoney
                                serieCumulativeCurrentRevenues.data.push(
                                    currentCumulativeRevenue
                                )
                                const currentMonthRevenue = current.revenue
                                    ? current.revenue / decimalMoney
                                    : 0
                                serieMonthlyCurrentRevenues.data.push(
                                    currentMonthRevenue
                                )
                                if (stats.revenues.currentPeriodRevenue) {
                                    stats.revenues.currentPeriodRevenue.maxRevenue =
                                        currentCumulativeRevenue
                                    stats.revenues.currentPeriodRevenue.progressMonthRevenue = 150
                                }

                                if (
                                    Number.isFinite(currentMonthRevenue) &&
                                    stats.revenues.currentPeriodRevenue &&
                                    index ===
                                        stats.revenues.currentPeriodRevenue
                                            .revenues.length -
                                            1
                                ) {
                                    stats.revenues.currentPeriodRevenue.maxRevenue =
                                        currentCumulativeRevenue
                                    if (stats.revenues.previousPeriodRevenue) {
                                        const lastYearMonthRevenue = stats
                                            .revenues?.previousPeriodRevenue
                                            ?.revenues[index]?.revenue
                                            ? stats.revenues
                                                  ?.previousPeriodRevenue
                                                  ?.revenues[index]?.revenue /
                                              decimalMoney
                                            : 0

                                        if (
                                            Number.isFinite(
                                                currentMonthRevenue
                                            ) &&
                                            Number.isFinite(
                                                lastYearMonthRevenue
                                            )
                                        ) {
                                            let mathRes = 0
                                            if (lastYearMonthRevenue) {
                                                mathRes =
                                                    ((currentMonthRevenue -
                                                        lastYearMonthRevenue) /
                                                        lastYearMonthRevenue) *
                                                    100
                                            } else {
                                                mathRes = 100
                                            }

                                            stats.revenues.currentPeriodRevenue.lastRevenue =
                                                currentMonthRevenue
                                            if (Number.isFinite(mathRes)) {
                                                stats.revenues.currentPeriodRevenue.progressMonthRevenue =
                                                    Math.round(mathRes)
                                            }
                                        }
                                    }
                                    let lastYearCumulativeRevenue = 0

                                    if (stats.revenues.previousPeriodRevenue) {
                                        const cumulativeRevenues =
                                            stats.revenues.previousPeriodRevenue
                                                .revenues[index]
                                                ?.cumulativeRevenue
                                        lastYearCumulativeRevenue =
                                            cumulativeRevenues
                                                ? cumulativeRevenues /
                                                  decimalMoney
                                                : 0
                                    }
                                    // Calculate previsions for the next months

                                    if (
                                        Number.isFinite(
                                            currentCumulativeRevenue
                                        ) &&
                                        Number.isFinite(currentMonthRevenue)
                                    ) {
                                        if (lastYearCumulativeRevenue > 0) {
                                            let mathRes = 0
                                            if (lastYearCumulativeRevenue) {
                                                mathRes =
                                                    ((currentCumulativeRevenue -
                                                        lastYearCumulativeRevenue) /
                                                        lastYearCumulativeRevenue) *
                                                    100
                                            } else {
                                                mathRes = 100
                                            }
                                            if (Number.isFinite(mathRes)) {
                                                stats.revenues.currentPeriodRevenue.progressCumulativeRevenue =
                                                    Math.round(mathRes)
                                            }
                                        }

                                        serieCumulativePrevisionsRevenues.data.push(
                                            currentCumulativeRevenue
                                        )

                                        if (
                                            currentCumulativeRevenue &&
                                            current.month
                                        ) {
                                            const averageMonth = Math.round(
                                                currentCumulativeRevenue /
                                                    current.month
                                            )
                                            while (
                                                serieCumulativePrevisionsRevenues
                                                    .data.length < 12
                                            ) {
                                                currentCumulativeRevenue +=
                                                    averageMonth
                                                serieCumulativePrevisionsRevenues.data.push(
                                                    currentCumulativeRevenue
                                                )
                                            }
                                        }
                                    }
                                } else {
                                    serieCumulativePrevisionsRevenues.data.push(
                                        null
                                    )
                                }
                            }
                        )

                        while (
                            serieCumulativeCurrentRevenues.data.length < 12
                        ) {
                            serieCumulativeCurrentRevenues.data.push(null)
                            serieMonthlyCurrentRevenues.data.push(null)
                        }
                        if (stats.revenues.previousPeriodRevenue) {
                            stats.revenues.previousPeriodRevenue.revenues.forEach(
                                (prev) => {
                                    serieCumulativePreviousRevenues.data.push(
                                        prev.cumulativeRevenue / decimalMoney
                                    )
                                    serieMonthlyPreviousRevenues.data.push(
                                        prev.revenue / decimalMoney
                                    )
                                }
                            )
                        }

                        stats.chartSeriesCumulative = []
                        stats.chartSeriesMonthly = []
                        if (serieCumulativePreviousRevenues.data.length > 0) {
                            while (
                                serieCumulativePreviousRevenues.data.length < 12
                            ) {
                                serieCumulativePreviousRevenues.data.push(null)
                                serieMonthlyPreviousRevenues.data.push(null)
                            }
                            stats.chartSeriesCumulative.push(
                                serieCumulativePreviousRevenues
                            )
                            stats.chartSeriesMonthly.push(
                                serieMonthlyPreviousRevenues
                            )
                        }
                        stats.chartSeriesCumulative.push(
                            serieCumulativeCurrentRevenues
                        )
                        stats.chartSeriesCumulative.push(
                            serieCumulativePrevisionsRevenues
                        )
                        stats.chartSeriesMonthly.push(
                            serieMonthlyCurrentRevenues
                        )
                    }
                    return stats
                })
                .catch((e: any) => {
                    throw e
                })
        },
        fetchPassesActivations(context: any) {
            return context.$axios
                .$get('/fleetapi/administration/dashboard')
                .then((res: any) => {
                    const passes = keysToCamel(res)
                    return passes
                })
                .catch((_e: any) => {
                    throw new Error('No passes data')
                })
        },
        fetchRevenues(context: any) {
            return context.$axios
                .$get('/cloudapi/administration/dashboard')
                .then((res: any) => {
                    const revenues = keysToCamel(res)
                    return revenues
                })
                .catch((_e: any) => {
                    throw new Error('No revenues data')
                })
        }
    }
})

export default useAdminStore
