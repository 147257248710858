import { createLocalVue, shallowMount, RouterLinkStub } from '@vue/test-utils'
import * as Vuex from 'vuex'

// @ts-ignore
import SubMenuSection from '@/components/UI/menu/SubMenuSection.vue'

// Mocks
import { i18n } from '~/test/mocks/i18n.mock'

const localVue = createLocalVue()
localVue.use(Vuex)

const $emit = jest.fn()

const propsData = {
    link: {
        id: 'menu-vehicles',
        title: 'menu.fleet.vehicles',
        icon: 'IconVehicle',
        url: `/vehicles`,
        linksSubMenu: [
            {
                id: 'menu-vehicles-child',
                title: 'menu.fleet.vehicles.child',
                icon: 'IconVehicle',
                url: `/vehicles/child`
            }
        ]
    }
}

describe('components/UI/menu/SubMenuSection.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(SubMenuSection, {
            localVue,
            mocks: {
                $t: i18n.$t,
                $tc: i18n.$tc,
                $emit,
                $route: {
                    path: '/vehicles'
                }
            },
            propsData,
            stubs: {
                NuxtLink: RouterLinkStub
            }
        })
    })

    afterEach(() => {
        jest.clearAllMocks()
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        expect(wrapper.vm.showSubMenu).toBeFalsy()
    })

    it('closeMobileMenu', () => {
        expect($emit).not.toHaveBeenCalled()
        wrapper.vm.closeMobileMenu()
        expect($emit).toHaveBeenCalledWith('closeMobileMenu')
    })

    it('hasCurrentLink', () => {
        expect(wrapper.vm.hasCurrentLink()).toBeFalsy()
        expect(wrapper.vm.showSubMenu).toBeFalsy()
    })

    it('hasCurrentLink falsy', () => {
        wrapper = shallowMount(SubMenuSection, {
            localVue,
            mocks: {
                $t: i18n.$t,
                $tc: i18n.$tc,
                $emit,
                $route: {
                    path: '/vehicles/child'
                }
            },
            propsData,
            stubs: {
                NuxtLink: RouterLinkStub
            }
        })
        expect(wrapper.vm.hasCurrentLink()).toBeTruthy()
        expect(wrapper.vm.showSubMenu).toBeTruthy()
    })
})
