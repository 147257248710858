
import Vue from 'vue'
import AppBanner from '~/components/UI/AppBanner.vue'

export default Vue.extend({
    components: {
        AppBanner
    },
    computed: {
        title() {
            return this.$tc('tesla.banner.title')
        },
        text() {
            return this.$tc('tesla.banner.text')
        }
    },
    methods: {
        showTeslaModal() {
            this.$emit('showTeslaModal')
        }
    }
})
