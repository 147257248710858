import traduction from '../../locales/en.json'

export const i18n = {
    $t: (msg: any) => msg,
    $tc: (msg: any) => msg,
    $d: (msg: any) => Date.parse(msg).toString(),
    $n: (msg: any) => msg,
    setLocale: jest.fn((locale) => Promise.resolve(locale)),
    config: {
        locale: 'en',
        locales: [
            {
                code: 'en',
                name: 'English',
                file: 'en.json'
            },
            {
                code: 'fr',
                name: 'Français',
                file: 'fr.json'
            }
        ],
        setLocale: jest.fn((locale) => Promise.resolve(locale))
    }
}
export const setUpi18n = {
    $t: i18n.$t,
    $tc: i18n.$tc,
    $n: i18n.$n,
    $d: i18n.$d,
    setLocale: i18n.config.setLocale,
    $i18n: {
        locale: i18n.config.locale,
        locales: i18n.config.locales,
        setLocale: i18n.config.setLocale
    }
}

const messages = {
    en: {
        ...traduction
    }
}
export const i18nConfig = {
    locale: 'en',
    fallbackLocale: 'en',
    messages
}
