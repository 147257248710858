
import Vue from 'vue'
import type { PropType } from 'vue'

type FetchState = {
    error: Error | null
    pending: boolean
    timestamp: number
}
export default Vue.extend({
    props: {
        fetchState: {
            type: Object as PropType<FetchState>,
            default() {
                return {
                    error: null,
                    pending: false,
                    timestamp: 0
                }
            }
        },
        hasData: {
            type: Boolean,
            default() {
                return false
            }
        }
    }
})
