
import Vue from 'vue'
import TemporaryDrawerCloseButton from './TemporaryDrawerCloseButton.vue'

export default Vue.extend({
    name: 'TemporaryDrawerTitleBar',
    components: { TemporaryDrawerCloseButton },
    props: {
        title: {
            type: String,
            required: true
        },
        showCloseButton: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        closeDrawer() {
            this.$emit('closeDrawer', false)
        }
    }
})
