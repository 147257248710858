
import SwitchCheck from '~/components/UI/icons/switch/SwitchCheck.vue'
import SwitchCross from '~/components/UI/icons/switch/SwitchCross.vue'

export default {
    name: 'AppSwitch',
    components: { SwitchCheck, SwitchCross },
    props: {
        title: {
            type: String,
            default: 'Use setting'
        },
        id: {
            type: String,
            default: 'app-switch'
        },
        value: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    }
}
