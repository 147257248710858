
export default {
    name: 'AppFilterOptions',
    props: {
        filter: {
            type: Object,
            required: true
        }
    },
    methods: {
        update(value) {
            this.$emit('update', { id: this.filter.id, value })
        }
    }
}
