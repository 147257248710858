import { defineStore } from 'pinia'
import {
    Collaborator,
    CollaboratorDTO,
    CollaboratorJSON,
    CollaboratorJsonDTO,
    CollaboratorPayloadJSON,
    RefundBankAccountStatus
} from '../interfaces/collaborator'
import { FilterList } from '@/interfaces/filters'
import { convertDate } from './charge'

const useCollaboratorStore = defineStore('collaborator', () => {
    const hasUniqueEmail = (
        context: any,
        payload: {
            idOrga: number
            idBU: number
            email: string
            idCollaborator: number | null
        }
    ) => {
        const collaboratorStore = useCollaboratorStore()
        const { idOrga, idBU, email, idCollaborator } = payload
        const filters = {
            searchField: '',
            email,
            pages: {
                index: 1,
                perPage: 1,
                totalItems: 0
            }
        }
        const payloadCollaborators = {
            idOrga,
            idBU,
            filters
        }
        return collaboratorStore
            .getCollaborators(context, payloadCollaborators)
            .then((res: CollaboratorDTO) => {
                const collaborators = context.$toCamel(res)
                if (collaborators.count > 0) {
                    return collaborators.collaborators[0].id === idCollaborator
                }
                return true
            })
            .catch(() => false)
    }

    const getCollaborators = (
        context: any,
        payload: { idOrga: number; idBU: number; filters: FilterList }
    ): Promise<CollaboratorDTO> => {
        const { idOrga, idBU, filters } = payload

        const offset =
            (filters.pages.index - 1) * filters.pages.perPage || (0 as number)

        const limit = filters.pages.perPage || (20 as number)
        const keyword = filters.searchField || (null as null | string)
        const email = filters.email || (null as null | string)
        const order_by = filters.orderBy || (null as null | string)
        const hasRefundBankAccountStatus =
            filters.hasRefundBankAccountStatus || (null as null | boolean)

        let params = {
            offset,
            limit,
            keyword,
            email,
            order_by,
            has_business_pass: filters.hasBusinessPass,
            has_domestic_zone: filters.hasDomesticZone,
            min_date_created: undefined as string | undefined | null,
            max_date_created: undefined as string | undefined | null,
            has_refund_bank_account_status: hasRefundBankAccountStatus
        }

        if (filters.dateRange) {
            const { minStartDate, maxStartDate } = convertDate(
                filters.dateRange[0],
                filters.dateRange[1]
            )
            if (minStartDate) {
                params = { ...params, min_date_created: minStartDate }
            }
            if (maxStartDate) {
                params = { ...params, max_date_created: maxStartDate }
            }
        }

        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/collaborators`

        return context.$axios
            .$get(url, { params })
            .then((res: CollaboratorJsonDTO) => context.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    }

    const getCollaborator = (
        context: any,
        payload: { idOrga: number; idBU: number; idCollaborator: number }
    ): Promise<Collaborator> => {
        const { idOrga, idBU, idCollaborator } = payload
        return new Promise((resolve, reject) => {
            const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/collaborators/${idCollaborator}`
            context.$axios
                .$get(url)
                .then((res: CollaboratorJSON) => resolve(context.$toCamel(res)))
                .catch((e: any) => reject(e))
        })
    }

    const createCollaborator = (
        context: any,
        payload: {
            collaborator: CollaboratorPayloadJSON
            idOrga: number
            idBU: number
        }
    ): Promise<Collaborator> => {
        const { collaborator, idOrga, idBU } = payload

        return new Promise((resolve, reject) => {
            context.$axios
                .$post(
                    `/cloudapi/organizations/${idOrga}/business-units/${idBU}/collaborators`,
                    collaborator
                )
                .then((res: CollaboratorJSON) => resolve(context.$toCamel(res)))
                .catch((e: any) => reject(e))
        })
    }

    const updateCollaborator = (
        context: any,
        payload: { collaborator: any; idOrga: number; idBU: number }
    ): Promise<Collaborator> => {
        const { collaborator, idOrga, idBU } = payload

        return new Promise((resolve, reject) => {
            context.$axios
                .$put(
                    `/cloudapi/organizations/${idOrga}/business-units/${idBU}/collaborators/${collaborator.id}`,
                    { ...collaborator, id: undefined }
                )
                .then((res: CollaboratorJSON) => resolve(context.$toCamel(res)))
                .catch((e: any) => reject(e))
        })
    }

    const deleteCollaborator = (
        context: any,
        payload: { idCollaborator: number; idOrga: number; idBU: number }
    ) => {
        const { idCollaborator, idOrga, idBU } = payload
        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/collaborators/${idCollaborator}`
        return new Promise((resolve, reject) => {
            context.$axios
                .$delete(url)
                .then((res: any) => resolve(res))
                .catch((e: any) => reject(new Error(e)))
        })
    }

    const sendInviteCommunityAssociation = (
        context: any,
        payload: {
            idCollaborator: number
            idOrga: number
            idBU: number
        }
    ): Promise<Collaborator> => {
        const { idCollaborator, idOrga, idBU } = payload
        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/collaborators/${idCollaborator}/community-association`
        return new Promise((resolve, reject) =>
            context.$axios
                .$post(url)
                .then((res: CollaboratorJSON) => resolve(context.$toCamel(res)))
                .catch((e: any) => reject(e))
        )
    }

    const updateRefundBankAccountStatusAndIban = (
        context: any,
        payload: {
            idCollaborator: number
            idOrga: number
            idBU: number
            refundBankAccountStatus?: RefundBankAccountStatus
            iban?: string
            bic?: string
        }
    ): Promise<Collaborator> => {
        const {
            idCollaborator,
            idOrga,
            idBU,
            refundBankAccountStatus,
            bic,
            iban
        } = payload
        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/collaborators/${idCollaborator}`
        const refundBankAccount = iban
            ? {
                  iban,
                  bic: bic === '' ? null : bic
              }
            : null

        const params = {
            refund_bank_account_status: refundBankAccountStatus
        } as {
            refund_bank_account_status: RefundBankAccountStatus
            refund_bank_account?: {
                iban: string
                bic: string | null | undefined
            }
        }

        if (refundBankAccount) params.refund_bank_account = refundBankAccount

        return new Promise((resolve, reject) => {
            context.$axios
                .$patch(url, params)
                .then((res: CollaboratorJSON) => {
                    const collab = context.$toCamel(res)
                    resolve(collab)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }

    return {
        getCollaborators,
        getCollaborator,
        createCollaborator,
        updateCollaborator,
        deleteCollaborator,
        sendInviteCommunityAssociation,
        updateRefundBankAccountStatusAndIban,
        hasUniqueEmail
    }
})

type CollaboratorStore = Omit<
    ReturnType<typeof useCollaboratorStore>,
    keyof ReturnType<typeof defineStore>
>

export default useCollaboratorStore

export type { CollaboratorStore }
