
import Vue from 'vue'
import LoginLeft from '@/components/UI/images/LoginLeft.vue'
import LoginRight from '@/components/UI/images/LoginRight.vue'
import LogoLogin from '@/components/UI/images/LogoLogin.vue'

export default Vue.extend({
    components: {
        LoginLeft,
        LoginRight,
        LogoLogin
    },
    middleware: ['init']
})
