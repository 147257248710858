
import Vue from 'vue'
import AppCarousel from '~/components/UI/AppCarousel.vue'
import TeslaNetworkModal from '@/components/others/TeslaNetworkModal.vue'
import IconShare from '~/components/UI/icons/IconShare.vue'
import { TypeSlideCarousel } from '~/interfaces/other'
import ChargemapModal from '~/components/UI/CMUI/ChargemapModal.vue'

export default Vue.extend({
    name: 'TeslaModal',
    components: {
        AppCarousel,
        TeslaNetworkModal,
        IconShare,
        ChargemapModal
    },
    data: () => ({
        showModalLink: false,
        slides: [
            {
                showClassStart: false,
                showClassEnd: false,
                showClassPrev: false,
                showClassNext: false,
                img: 'TeslaCB',
                title: 'tesla.modal.slide1.title',
                body: 'tesla.modal.slide1.text'
            },
            {
                showClassStart: false,
                showClassEnd: false,
                showClassPrev: false,
                showClassNext: false,
                img: 'DriverLink',
                title: 'tesla.modal.slide2.title',
                body: 'tesla.modal.slide2.text'
            },
            {
                showClassStart: false,
                showClassEnd: false,
                showClassPrev: false,
                showClassNext: false,
                img: 'Wallet',
                title: 'tesla.modal.slide3.title',
                body: 'tesla.modal.slide3.text'
            },
            {
                showClassStart: false,
                showClassEnd: false,
                showClassPrev: false,
                showClassNext: false,
                img: 'LinkTeslaChargeMap',
                title: 'tesla.modal.slide4.title',
                body: 'tesla.modal.slide4.text'
            },
            {
                showClassStart: false,
                showClassEnd: false,
                showClassPrev: false,
                showClassNext: false,
                img: 'KeepHabits',
                title: 'tesla.modal.slide5.title',
                body: 'tesla.modal.slide5.text'
            }
        ]
    }),
    methods: {
        updateModalIsVisible(value: boolean) {
            this.$emit('updateModalIsVisible', value)
        },
        updateShowModalLink(value: boolean) {
            this.showModalLink = value
        },
        updateShowClass({
            index,
            value,
            type
        }: {
            index: number
            value: boolean
            type: TypeSlideCarousel
        }) {
            const typeClass =
                type === TypeSlideCarousel.NEXT
                    ? 'showClassStart'
                    : 'showClassEnd'
            this.slides[index][typeClass] = value
        },
        updateShowClassPrevNext({
            index,
            value,
            type
        }: {
            index: number
            value: boolean
            type: TypeSlideCarousel
        }) {
            const typeClass =
                type === TypeSlideCarousel.PREV
                    ? 'showClassPrev'
                    : 'showClassNext'
            this.slides[index][typeClass] = value
        }
    }
})
