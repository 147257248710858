import { createLocalVue, shallowMount } from '@vue/test-utils'
import * as Vuex from 'vuex'

// @ts-ignore
import IconConnectorStatus from '@/components/UI/icons/connectors/status/IconConnectorStatus.vue'

// Mocks
import { evseStatuses } from '~/interfaces/chargebox'

const localVue = createLocalVue()
localVue.use(Vuex)

describe('components/UI/icons/connectors/status/IconConnectorStatus.vue', () => {
    let wrapper: any

    beforeAll(() => {
        wrapper = shallowMount(IconConnectorStatus, {
            localVue,
            propsData: {
                status: evseStatuses.AVAILABLE,
                color: 'green'
            }
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
    })

    describe('computed', () => {
        it('status', async () => {
            expect(wrapper.vm.statusIcon).toBe('AVAILABLE')
            await wrapper.setProps({
                status: evseStatuses.RESERVED
            })
            expect(wrapper.vm.statusIcon).toBe('RESERVED')
            await wrapper.setProps({
                status: 'fake status'
            })
            expect(wrapper.vm.statusIcon).toBe('UNKNOWN')
        })

        it('color', async () => {
            expect(wrapper.vm.colorIcon).toBe('green')
            await wrapper.setProps({
                color: 'red'
            })
            expect(wrapper.vm.colorIcon).toBe('red')
            await wrapper.setProps({
                color: 'black'
            })
            expect(wrapper.vm.colorIcon).toBe('gray')
        })
    })
})
