const $toast = {
    global: {
        success: jest.fn(),
        info: jest.fn(),
        warn: jest.fn(),
        error: jest.fn()
    }
}

export default $toast
