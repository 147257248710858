var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppBadge',{staticClass:"connector-badge",attrs:{"color":_vm.colorBadge,"is-disabled":_vm.color === 'gray' || !_vm.chargeboxConnected,"squared":"","bordered":""}},[_c('div',{staticClass:"flex flex-row flex-inline sm:flex-col items-center gap-2 sm:gap-0.5"},[_c('IconConnector',{attrs:{"connector-type":_vm.connector.type,"color":_vm.color}}),_vm._v(" "),(_vm.isPluggedIn)?_c('IconCharging',{class:_vm.color === 'gray'
                    ? `text-${_vm.colorBadge}-400`
                    : `text-${_vm.colorBadge}-600`,attrs:{"power-active":_vm.connector.status === _vm.statuses.CHARGING}}):_c('IconConnectorStatus',{staticClass:"h-4 w-4",attrs:{"status":_vm.connector.status,"color":_vm.color}})],1),_vm._v(" "),_c('div',{staticClass:"mb-1 sm:hidden text-xs",class:_vm.color === 'gray'
                ? `text-${_vm.colorBadge}-500`
                : `text-${_vm.colorBadge}-700`},[_vm._v("\n        "+_vm._s(_vm.$tc(_vm.statusDisplayed))+"\n    ")]),_vm._v(" "),(_vm.enableFlyout)?_c('AppFlyout',{attrs:{"color":_vm.colorBadge},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$tc(_vm.statusDisplayed))+"\n        ")]},proxy:true}],null,false,1929177613)},[_vm._v(" "),_c('span',{class:_vm.typeStyle},[_vm._v("\n            "+_vm._s(_vm.$tc('connectors.' + _vm.connector.type))+"\n        ")]),_vm._v(" "),(_vm.connector.power)?_c('span',{class:_vm.powerStyle},[_vm._v("\n            "+_vm._s(_vm._f("convertPowerWithUnit")(_vm.connector.power))+"\n            "),(_vm.connector.currentType)?_c('span',[_vm._v("\n                ("+_vm._s(_vm.$t(
                        `chargeboxes.connectors.line.type.${_vm.connector.currentType}`
                    ))+")\n            ")]):_vm._e()]):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }