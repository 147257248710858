import Vue from 'vue'

const eventMenu = {
    install(app: typeof Vue) {
        app.prototype.$menu = new Vue()
    }
}

Vue.use(eventMenu)

declare module 'vue/types/vue' {
    interface Vue {
        $menu: Vue
    }
}
