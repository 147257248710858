import { ref } from 'vue'
import { defineStore } from 'pinia'
import {
    LicenseBU,
    LicenseBUDTO,
    LicenseOrga,
    LicenseOrgaDTO,
    LicenseType
} from '~/interfaces/license'

const useLicenseStore = defineStore('license', () => {
    const licenses = ref({} as LicenseOrga)
    const licensesBU = ref({} as LicenseBU | null)

    const fetchLicensesBU = (
        context: any,
        payload: { idOrga: number; idBU: number }
    ) => {
        const { idOrga, idBU } = payload
        return context.$axios
            .$get(
                `/flaggyapi/organizations/${idOrga}/business-units/${idBU}/licenses`
            )
            .then((licenseBUDTO: LicenseBUDTO) => {
                const licenseBU = context.$toCamel(licenseBUDTO)
                licensesBU.value = licenseBU
                return licensesBU.value
            })
            .catch((e: any) => new Error(e.message))
    }

    const fetchLicenses = (context: any, idOrga: number) =>
        context.$axios
            .$get(`/flaggyapi/organizations/${idOrga}/licenses`)
            .then((licenseOrgaDTO: LicenseOrgaDTO) => {
                const licenseOrga = context.$toCamel(licenseOrgaDTO)
                licenses.value = licenseOrga
                return licenses.value
            })
            .catch((e: any) => new Error(e.message))

    const orderChargebox = (
        context: any,
        payload: {
            chargeboxLicenseNumber: number
            chargeboxRebillingLicenseNumber: number
            chargeboxDomesticLicenseNumber: number
            simCardLicenseNumber: number
            overstayParkingFeesLicenseNumber?: number
            idBU: string
            collaborators: number[]
        }
    ) => {
        const {
            chargeboxRebillingLicenseNumber,
            chargeboxDomesticLicenseNumber,
            chargeboxLicenseNumber,
            simCardLicenseNumber,
            overstayParkingFeesLicenseNumber,
            idBU,
            collaborators
        } = payload

        const orderLicense = {
            licenses: {
                CHARGEBOX: chargeboxLicenseNumber || undefined,
                CHARGEBOX_REBILLING:
                    chargeboxRebillingLicenseNumber || undefined,
                SIM_CARD: simCardLicenseNumber || undefined,
                CHARGEBOX_DOMESTIC: chargeboxDomesticLicenseNumber || undefined,
                OVERSTAY_PARKING_FEES:
                    overstayParkingFeesLicenseNumber || undefined
            },
            collaborators_ids: collaborators || []
        }

        return context.$axios
            .$post(
                `/supervisionapi/business-units/${idBU}/orders`,
                orderLicense
            )
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    }

    const cancelLicense = (
        context: any,
        payload: {
            totalLicenseToCancel: number | string
            licenseType: LicenseType
            idBU: string
        }
    ) => {
        const { totalLicenseToCancel, licenseType, idBU } = payload
        let url = `fleetapi/business-units/${idBU}/licenses`
        if (licenseType !== LicenseType.PASS)
            url = `supervisionapi/business-units/${idBU}/licenses`

        const params = {
            license_type:
                licenseType !== LicenseType.PASS ? licenseType : undefined,
            licenses_count: parseInt(totalLicenseToCancel as string, 10)
        }
        return context.$axios.$delete(url, { data: params }).catch((e: any) => {
            throw e
        })
    }

    return {
        licenses,
        licensesBU,
        fetchLicensesBU,
        fetchLicenses,
        orderChargebox,
        cancelLicense
    }
})

type LicenseStore = Omit<
    ReturnType<typeof useLicenseStore>,
    keyof ReturnType<typeof defineStore>
>

export default useLicenseStore

export type { LicenseStore }
