
import Vue from 'vue'

export default Vue.extend({
    props: {
        inputId: {
            type: String,
            default: ''
        },
        isOptional: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'line',
            validator(value: string) {
                return [
                    'line',
                    'inline',
                    'inline-switch',
                    'inline-left',
                    'column'
                ].includes(value)
            }
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        fromTemplate: {
            type: Boolean,
            default: false
        },
        hasEmptyLabel: {
            type: Boolean,
            default: false
        },
        noBorderLine: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classForm(): string {
            return `detail-section__form-${this.mode}`
        }
    }
})
